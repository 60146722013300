if (document.querySelector('#canvas-wrap') !== null) {
  /*
  const controlOptions = {
    x: -0.5,
    y: -0.5,
    offsetX: -10,
    offsetY: -10,
    cursorStyle: 'pointer',
    mouseUpHandler: editObject,
    render: renderIcon,
    cornerSize: 24
  };
  fabric.Object.prototype.controls.editObject = new fabric.Control(controlOptions);
  fabric.Textbox.prototype.controls.editObject = new fabric.Control(controlOptions);
  */
  const controlRotationOptions = {
    x: 0.5,
    y: -0.5,
    offsetX: 9,
    offsetY: -9,
    cursorStyle: 'crosshair',
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    actionName: 'rotate',
    render: renderIconRotation,
    cornerSize: 24,
    withConnection: true
  };
  // Changing rotation control properties
  fabric.Object.prototype.controls.mtr = new fabric.Control(controlRotationOptions);
  fabric.Textbox.prototype.controls.mtr = new fabric.Control(controlRotationOptions);
  
  const originalControl = fabric.Object.prototype.controls.br;
  const controlResizingOptions = {
    x: 0.5,
    y: 0.5,
    offsetX: 10,
    offsetY: 10,
    cursorStyle: 'nwse-resize',
    actionHandler: originalControl.actionHandler,
    actionName: 'rotate',
    render: renderIconResizing,
    cornerSize: 24,
    withConnection: true
  };
  // Changing resizing control properties
  fabric.Object.prototype.controls.br = new fabric.Control(controlResizingOptions);
  fabric.Textbox.prototype.controls.br = new fabric.Control(controlResizingOptions);
  
  //Deletion
  const controlDeletionOptions = {
    x: -0.5,
    y: -0.5,
    offsetX: -10,
    offsetY: -10,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIconDeletion,
    cornerSize: 24
  }
  fabric.Object.prototype.controls.deleteControl = new fabric.Control(controlDeletionOptions);
  fabric.Textbox.prototype.controls.deleteControl = new fabric.Control(controlDeletionOptions);
  
  function deleteObject(eventData, transform) {
    const target = transform.target;
    document.querySelector('.personnalisation-bit[data-index="' + target.index + '"]').remove();
    const canvas = target.canvas;
    canvas.remove(target);
    handleDeselectAllObjects(canvas);
    canvas.renderAll();
  }
  
  //Move
  const controlMoveOptions = {
    x: -0.5,
    y: 0.5,
    offsetX: -10,
    offsetY: 10,
    actionHandler: fabric.controlsUtils.dragHandler,//change to this
    actionName: 'drag',
    cursorStyle:'pointer',
    render: renderIconMove,
    cornerSize: 24
  }
  fabric.Object.prototype.controls.moveObject = new fabric.Control(controlMoveOptions);
  fabric.Textbox.prototype.controls.moveObject = new fabric.Control(controlMoveOptions);
  
  if (document.body.clientWidth >= 767) {
    window.addEventListener("resize", (event) => {
      canvasInfoList.forEach((canvasInfo) => {
        handleCanvasDimension(canvasInfo.canvas);
      });
    });
  }
}

// check inventory
let input = document.getElementById('sylius_add_to_cart_cartItem_quantity')
let selector = '';
if (input) {
  input.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
    const select = element;
    const option = select[select.selectedIndex].value;
    selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
    select.addEventListener('change', function () {
      document.querySelector('[name="sylius_add_to_cart[cartItem][quantity]"]').dispatchEvent(new Event('change'));
    })
  });
}

let variantDetails = null;
if (selector !== "") {
  variantDetails = document.querySelector('#sylius-variants-details').querySelector(selector);
} else {
  variantDetails = document.querySelector('#sylius-variants-details > div')
}
let inStock = true;
let btnPersonnalisation = document.getElementById('btn-start-customization');
if (variantDetails) {
  let onHand = variantDetails.dataset.onhand === "0" ? 0 : parseInt(variantDetails.dataset.onhand);
  let onHold = variantDetails.dataset.onhold === "0" ? 0 : parseInt(variantDetails.dataset.onhold);
  let quantity = onHand - onHold;
  if ((parseInt(variantDetails.dataset.tracked) === 1 && quantity <= 0 && variantDetails.dataset.stockFamily === "null") ||
    (parseInt(variantDetails.dataset.tracked) === 1 && quantity < parseInt(variantDetails.dataset.minimalQuantity) && variantDetails.dataset.stockFamily === "null") ||
    (variantDetails.dataset.stockFamily === "0")) {
    inStock = false;
  }
  if (variantDetails.dataset.bundleInstock) {
    if (variantDetails.dataset.bundleInstock == "1") {
      inStock = true;
    }
  }
  if (document.querySelector('#sylius-variants-details').dataset.bundle == 0) {
    if (variantDetails.dataset.advanced == 0) {
      checkConfiguratorValid();
    }
  }
}

let enableRemoveFocus = true;
const characters =   ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z', 'ẞ',
  'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z', 'ß',
  'á','à','â','ä','ã','å','ó','ò','ô','ö','õ','í','ì','î','ï','é','è','ê','ë','ú','ù','û','ü','ý','ÿ','æ','ç','ñ',
  'Á','À','Â','Ä','Ã','Å','Ó','Ò','Ô','Ö','Õ','Í','Ì','Î','Ï','É','È','Ê','Ë','Ú','Ù','Û','Ü','Ý','Ÿ','Æ','Ç','Ñ',
  '0','1','2','3','4','5','6','7','8','9',
  ' ','*','-','_',',',';','.','?','/','+','=','(',')','[',']','€','$','@','&','"','\'','’','#','~','|','{','}','!','\n'];

const keys = ['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'End', 'F5', 'Home', 'Delete', 'Shift', 'Enter'];

const defaultOption = {
  transparentCorners: false,
  cornerColor: '#789789',
  cornerSize: 24,
  cornerStyle: 'circle',
  strokeUniform: true,
  lockScalingX: true,
  lockScalingY: true,
  centeredRotation: true,
  hasControls: true,
  strokeLineJoin: 'round',
  strokeLineCap: 'round'
};

const defaultTextOption = { ...defaultOption,
  stroke: 'rgba(0,0,0,0)',
  strokeColor: 'rgba(0,0,0,0)',
  strokeWidth: 1,
  kerning: 0,
  flipped: false,
  paintFirst: "stroke",
  shadow: null,
  affectStroke: true,
  fontSize: 15,
  defaultTextSize: 15,
  fontWeight: 'normal',
  fontStyle: 'normal',
  editable: false,
  lockScalingFlip: true,
  textAlign: "center",
};

let canvaSizeMax = 750;
let canvasInfoList = [];
let pCanvas = null;
let editMode = false;
let resetData = {};
let fontFamilies = [];
let globalIndex = 100;
let timeOutClear = [];
let timeOutTextModified = null;
let cursorPosition = 0;

let edittextIcon = document.createElement("img")
edittextIcon.src = "/_themes/sylius/bootstrap-theme/images/edittext.png";
let editimageIcon = document.createElement("img")
editimageIcon.src = "/_themes/sylius/bootstrap-theme/images/editimage.png";
let imgRotationIcon = document.createElement("img")
imgRotationIcon.src = "/_themes/sylius/bootstrap-theme/images/rotationicon.png";
let imgDeletionIcon = document.createElement("img")
imgDeletionIcon.src = "/_themes/sylius/bootstrap-theme/images/deleteicon.png";
let imgResizingIcon = document.createElement("img")
imgResizingIcon.src = "/_themes/sylius/bootstrap-theme/images/resizeicon.png";
const watermarkSrc = "/_themes/sylius/bootstrap-theme/images/watermark.png";

let moveIcon = document.createElement("img")
moveIcon.src = "/_themes/sylius/bootstrap-theme/images/moveicon.png";

var widgetTexts = [];

handleDropDownGlobal();

function initCanvas(dataModel) {
  const modelId = dataModel.modelId;
  const itemId = dataModel.itemId;
  
  const canvas = new fabric.Canvas('canvas-'+itemId, {
    backgroundColor: "#dddddd",
    preserveObjectStacking: true,
    allowTouchScrolling: true,
    selection: false,
    uniScaleKey: null,
    modelId: modelId,
    itemId: itemId,
  });
  
  canvas.on('selection:created', function(event) {
    if (typeof event.selected !== 'undefined') {
      handleSelectObject(event.selected[0]);
    }
  });
  
  canvas.on('selection:updated', function(event) {
    if (typeof event.selected !== 'undefined') {
      handleSelectObject(event.selected[0]);
    }
    if (typeof event.deselected !== 'undefined') {
      handleDeselectObject(event.deselected[0]);
    }
  });
  
  canvas.on('selection:cleared', function(event) {
    if (typeof event.deselected !== 'undefined') {
      handleDeselectObject(event.deselected[0]);
    }
  });
  
  canvas.on('object:modified', function(event) {
    if (typeof event.target !== 'undefined') {
      handleModifyObject(event.target);
    }
  });
  
  canvas.on('mouse:down', function(event) {
    if (typeof event.target !== null) {
      if (event.target) {
        event.target.stateMovement = event.target.stateMovement + 1;
        
        if(event.target.enableMovement){
          if(event.target.stateMovement >= 2){
            event.target.lockMovementX = false;
            event.target.lockMovementY = false;
            event.target.dirty = true;
          }
        }
      }
    }
  });
  
  const bodyWidth = document.body.clientWidth;
  if(bodyWidth < 750){
    canvaSizeMax = bodyWidth;
  }
  
  canvasInfoList.push({
    modelId: modelId,
    itemId: itemId,
    model: dataModel,
    canvas: canvas
  });
  
  displayFreeWidget(dataModel);
  updateCanvasDimensions(canvas, dataModel);
  
  document.querySelectorAll("#free-element-" + itemId + " .btn-add-text").forEach((item) => {
    item.addEventListener('click', (e) => {
      addFreeWidgetText(canvas, dataModel);
    });
  });
  
  document.querySelectorAll("#free-element-" + itemId + " .btn-add-image-upload").forEach((item) => {
    item.addEventListener('click', (e) => {
      addFreeWidgetImageUpload(canvas, dataModel);
    });
  });
  
  document.querySelectorAll("#free-element-" + itemId + " .btn-add-image-library").forEach((item) => {
    item.addEventListener('click', (e) => {
      addFreeWidgetImageLibrary(canvas, dataModel);
    });
  });
  
  return canvas;
}

function displayFreeWidget(model){
  const itemId = model.itemId;
  let visibleClass = "";
  if(!model.visible){
    visibleClass = "d-none";
  }
  
  const templateFreeElement = document.querySelector("#template-free-element").content.cloneNode(true).firstElementChild;
  templateFreeElement.setAttribute("id", "free-element-" + itemId);
  templateFreeElement.setAttribute("data-id", itemId);
  if(visibleClass !== ""){
    templateFreeElement.classList.add(visibleClass);
  }
  document.querySelector("#box-free-element").append(templateFreeElement);
  
  const blocConfigPerso = templateFreeElement.querySelector(".bloc-config-perso");
  blocConfigPerso.classList.remove("d-flex");
  blocConfigPerso.classList.add("d-none");
  blocConfigPerso.querySelectorAll(".bloc-add-text, .bloc-add-image, .linked_mixed, .linked_library, .linked_upload").forEach((item) => {
    item.classList.add("d-none");
  });
  
  document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-text", "0");
  document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-upload", "0");
  document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-library", "0");
  if(model.freeConfiguration){
    blocConfigPerso.classList.remove("d-none");
    blocConfigPerso.classList.add("d-flex");
    
    if(model.enableText){
      blocConfigPerso.querySelectorAll(".bloc-add-text").forEach((item) => {
        item.classList.remove("d-none");
      });
      document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-text", "1");
    }
    if(model.enableImageUpload || model.enableImageLibrary){
      blocConfigPerso.querySelectorAll(".bloc-add-image").forEach((item) => {
        item.classList.remove("d-none");
      });
      if(model.enableImageUpload){
        blocConfigPerso.querySelectorAll(".linked_upload").forEach((item) => {
          item.classList.remove("d-none");
        });
        document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-upload", "1");
      }
      if(model.enableImageLibrary){
        blocConfigPerso.querySelectorAll(".linked_library").forEach((item) => {
          item.classList.remove("d-none");
        });
        document.querySelector("#wrap-canvas-" + itemId).setAttribute("data-enable-library", "1");
      }
      if(model.enableImageUpload && model.enableImageLibrary){
        blocConfigPerso.querySelectorAll(".linked_mixed").forEach((item) => {
          item.classList.remove("d-none");
        });
      }
    }
  }
}

const clearConfigurator = function clearConfigurator() {
  document.querySelectorAll('.personnalisation-bit').forEach(element => {
    element.remove();
  });
  document.getElementById('js-no-configurator').classList.remove("d-none");
  document.getElementById('js-configurator').classList.add("hide");
}

/*
 * displays a popup for selecting an image from a library
 */
function createLibrariesPopup(libraries, libraryFileId){
  const popupHtml = `<div class="popup popup-libraries">
                        <div class="popup-inner">
                            <div id="libraries-wrap" class="px-md-3 px-2">
                                contenu librairies
                            </div>
                            <div id="btn-wrap" class="row no-gutters justify-content-between">
                              <div class="pr-2 col-auto pl-0"><span id="reset-libraries" class="popup-btn-reset">Annuler</span></div>
                              <div class="pl-3 col pr-0"><span id="valid-image" class="popup-btn-valid ">Valider</span></div>
                            </div>
                        </div>
                    </div>`;
  document.querySelector("body").insertAdjacentHTML("afterbegin", popupHtml);
  
  let librariesHtml = "";
  let countImages = 0;
  for (let i = 0; i < libraries.length; i++) {
    const library = libraries[i];
    librariesHtml += `<div class="library-item">
                                <div class="title-ss-regular color-purple-s mb-1">
                                  ${library.name}
                                </div>`;
    librariesHtml +=   `<div class="padding-visible-library-old">
                                  <div class="position-relative">
                                    <!--
                                    <div class="btn-slide-prev library-prev"></div>
                                    <div class="btn-slide-next library-next"></div>
                                    -->
                                    <div class="swiper-container swiper-library">
                                      <div class="swiper-wrapper ">`;
    for (let j = 0; j < library.files.length; j++) {
      countImages++;
      const file = library.files[j];
      let className = "";
      if (libraryFileId == file.id) {
        className += " selected";
      }
      if (library.darkBackground) {
        className += " dark-background";
      }
      librariesHtml += `        <div class=" swiper-slide library-image-item ${className}">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <img src="/${file.popupPath}" data-id="${file.id}" data-src="/${file.path}"/>
                                        </div>
                                      </div>`;
    }
    librariesHtml += `        </div>
                                      <!--
                                      <div class="swiper-pagination"></div>
                                      -->
                                    </div>
                                  </div>
                                </div>
                               </div>`;
    
  }
  
  document.querySelector("#libraries-wrap").innerHTML = librariesHtml;
  if(document.querySelector("#libraries-wrap .library-image-item.selected") === null){
    if(document.querySelector("#libraries-wrap .library-image-item") !== null){
      document.querySelector("#libraries-wrap .library-image-item").classList.add('selected');
    }
  }
  
  //init_slide_library();
  
  document.querySelectorAll(".library-image-item").forEach((item) => {
    item.addEventListener('click', (e) => {
      document.querySelectorAll(".library-image-item").forEach((itemTmp) => {
        itemTmp.classList.remove("selected");
      });
      item.classList.add("selected");
    });
  });
  
  const popup = document.querySelector(".popup");
  popup.addEventListener('click', (e) => {
    popup.remove();
  });
  
  document.querySelector(".popup-inner").addEventListener('click', (e) => {
    e.stopPropagation();
  });
  
  document.querySelector("#reset-libraries").addEventListener('click', (e) => {
    popup.remove();
  });
  
  return popup;
}

/*
 * displays a popup for cropping an image
 */
function createJcropPopup(imageResize, img, zoneWidth, zoneHeight, jcropOptions = {}) {
  imageResize.style.width = '300px';
  imageResize.style.height = 'auto';
  
  const popupHtml = `<div class="popup">
                                  <div class="popup-inner">
                                      <div>
                                           <input type="hidden" id="jcrop-x" />
                                           <input type="hidden" id="jcrop-y" />
                                           <input type="hidden" id="jcrop-w" />
                                           <input type="hidden" id="jcrop-h" />
                                      </div>
                                      <div id="crop-wrap"></div>
                                      <div id="btn-wrap">
                                          <div class="pr-2 col-auto pl-0"><span id="close-crop" class="popup-btn">Annuler</span></div>
                                          <div class="pl-3 col pr-0"><span id="valid-crop" class="popup-btn">Valider</span></div>
                                      </div>
                                  </div>
                              </div>`;
  document.querySelector("body").insertAdjacentHTML("afterbegin", popupHtml);
  document.querySelector("#crop-wrap").append(imageResize);
  
  const jcrop = Jcrop.attach(img);
  const popup = document.querySelector(".popup");
  popup.addEventListener('click', (e) => {
    if(jcropOptions.inputFile){
      jcropOptions.inputFile.value = "";
    }
    if(jcropOptions.element){
      const element = jcropOptions.element;
      if(element.item(1)){
        element.remove(element.item(1));
        element.canvas.renderAll();
      }
    }
    if(jcropOptions.widget){
      jcropOptions.widget.remove();
    }
    if(jcropOptions.widgetValid){
      jcropOptions.widgetValid.classList.remove('ok-valid');
    }
    popup.remove();
    jcrop.destroy();
    checkConfiguratorValid();
  });
  
  document.querySelector(".popup-inner").addEventListener('click', (e) => {
    e.stopPropagation();
  });
  
  const imgWidth = img.width;
  const imgHeight = img.height;
  
  const pos = getRectPosition(zoneWidth, zoneHeight, imgWidth, imgHeight);
  const rect = Jcrop.Rect.create(pos.x, pos.y, pos.w, pos.h);
  updateInputPosition(pos);
  const options = {
    handles: ['sw', 'nw', 'ne', 'se'],
    aspectRatio: pos.w / pos.h
  }
  jcrop.newWidget(rect, options);
  
  jcrop.listen('crop.change', function (widget, e) {
    updateInputPosition(widget.pos);
  });
  
  document.querySelector("#close-crop").addEventListener('click', (e) => {
    if(jcropOptions.inputFile){
      jcropOptions.inputFile.value = "";
    }
    if(jcropOptions.element){
      const element = jcropOptions.element;
      if(element.item(1)){
        element.remove(element.item(1));
        element.canvas.renderAll();
      }
    }
    if(jcropOptions.widget){
      jcropOptions.widget.remove();
    }
    if(jcropOptions.widgetValid){
      jcropOptions.widgetValid.classList.remove('ok-valid');
    }
    popup.remove();
    jcrop.destroy();
    checkConfiguratorValid();
  });
  
  return {
    popup: popup,
    jcrop: jcrop
  }
}

/*
 * draw the elements on the canvas with the events
 */
function drawCanvasElements(options) {
  const model = options.dataModel;
  const modelId = model.modelId;
  const itemId = model.itemId;
  const digital = model.isDigitalProduct;
  let prefixModelId = "";
  let howManyWidget = 0;
  
  if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
    prefixModelId = "_" + itemId;
  }
  
  const boxConfigPerso = document.querySelector("#box-config-perso");
  let visibleClass = "";
  
  if(!model.visible){
    visibleClass = "d-none";
  }
  
  boxConfigPerso.insertAdjacentHTML("beforeend", `<div id="config-perso-${itemId}" data-digital="${digital}" data-itemId="${itemId}" data-variant-id="${model.variantId}" data-model-id="${model.modelId}" class="config-perso ${visibleClass} advanced"></div>`);
  
  const configPerso = document.querySelector("#config-perso-" + itemId);
  
  const canvasInfo = canvasInfoList.find(info => info.itemId == itemId);
  const canvas = canvasInfo.canvas;
  canvas.remove(...canvas.getObjects());
  
  const scale = canvas.scale;
  
  const clipPath = drawCanvasPrintZone(canvas, model);
  
  model.objects = [];
  options.widgets.forEach((item) => {
    model.objects.push(item);
    const id = item.id;
    const index = item.index;
    
    const widthPx = item.width;
    const heightPx = item.height;
    const width = parseInt(widthPx * scale);
    const height = parseInt(heightPx * scale);
    const angle = item.angle;
    const topPx = item.coordinatesY;
    const leftPx = item.coordinatesX;
    const top = parseInt(topPx * scale);
    const left = parseInt(leftPx * scale);
    
    const zIndex = item.zIndex;
    const visibleOnConfigurator = item.visibleOnConfigurator;
    const visibleOnPrint = item.visibleOnPrint;
    const linkedToPrintZone = item.linkedToPrintZone;
    const linesMax = item.linesMax ? item.linesMax : 1;
    const lineHeight = item.lineSpace === null ? 1 : item.lineSpace;
    const characterLimit = item.characterLimit;
    const characterLineLimit = item.characterLineLimit;
    const textSizeMin = item.textSizeMin;
    const blended = item.blended;
    //let defaultText = item.defaultText;
    //defaultText = defaultText.replace("&#039;", "'");
    //defaultText = defaultText.replace("&amp;", "&");
    let defaultText = defaultTexts[item.id];
    defaultText = limitLinesMax(defaultText, linesMax);
    const imagePath = item.imagePath;
    const imagePathCanva = item.imagePathCanva;
    const imagelibraryPath = item.defaultLibrary ? item.defaultLibrary : "";
    const imagelibraryId = item.defaultLibraryId ? item.defaultLibraryId : 0;
    const enableCurved = item.curvedText;
    let diameterPx = parseInt(item.diameter) ? parseInt(item.diameter) : 0;
    let diameter = parseInt(diameterPx * scale);
    let flipped = false;
    if(diameter < 0){
      flipped = true;
      diameter = Math.abs(diameter);
      diameterPx = Math.abs(diameterPx);
    }
    
    let defaultDatas = {
      widthPx: widthPx,
      heightPx: heightPx,
      width: width,
      height: height,
      coordinatesX: leftPx,
      coordinatesY: topPx,
      left: left,
      top: top,
      mandatory: item.mandatory,
      angle: angle,
      index: index,
      id: id,
      linesMax: linesMax,
      level: zIndex,
      blended: blended,
      type: item.type
    }
    let option = {
      top: top,
      left: left,
      width: width,
      height: height,
      originalWidth: width,
      originalHeight: height,
      angle: angle,
      id: id,
      index: index,
      zIndex: zIndex,
      linesMax: linesMax,
      visibleOnConfigurator: visibleOnConfigurator,
      visibleOnPrint: visibleOnPrint,
      linkedToPrintZone: linkedToPrintZone,
      blended: blended,
      free: false,
      isSVG: false,
      originalClipPath: clipPath,
      libraries: item.libraries,
      widgetType: item.type
    }
    let textOption = {
      top: top,
      left: left,
      angle: angle,
      id: id,
      index: index,
      zIndex: zIndex,
      originalWidth: width,
      originalHeight: height,
      textAlign: "center",
      stroke: "transparent",
      strokeColor: "transparent",
      linesMax: linesMax,
      visibleOnConfigurator: visibleOnConfigurator,
      visibleOnPrint: visibleOnPrint,
      linkedToPrintZone: linkedToPrintZone,
      blended: blended,
      free: false,
      isSVG: false,
      originalClipPath: clipPath,
      widgetType: item.type
    }
    
    if(lineHeight !== null){
      option.lineHeight = lineHeight;
      textOption.lineHeight = lineHeight;
    }
    
    option.stateMovement = 0;
    textOption.stateMovement = 0;
    option.enableMovement = false;
    textOption.enableMovement = false;
    if(item.allowMovement){
      option.enableMovement = true;
      textOption.enableMovement = true;
    }
    if (document.body.clientWidth < 767) {
      option.lockMovementX = true;
      option.lockMovementY = true;
      textOption.lockMovementX = true;
      textOption.lockMovementY = true;
    }else{
      if(!item.allowMovement){
        option.lockMovementX = true;
        option.lockMovementY = true;
        textOption.lockMovementX = true;
        textOption.lockMovementY = true;
      }
    }
    
    if(item.linkedToPrintZone){
      option.clipPath = clipPath;
    }
    if(!item.visibleOnConfigurator){
      option.opacity = 0;
      option.selectable = false;
      option.lockMovementX = true;
      option.lockMovementY = true;
      textOption.opacity = 0;
      textOption.selectable = false;
      textOption.lockMovementX = true;
      textOption.lockMovementY = true;
    }
    
    let element;
    let widget;
    if (item.type === "text") {
      widget = document.querySelector("#template-widget-text").content.cloneNode(true).firstElementChild;
      configPerso.append(widget);
      
      if(!visibleOnConfigurator){
        widget.classList.add("d-none");
      }
      
      if(linesMax > 1){
        widget.querySelector("input.input-text").remove();
        widget.setAttribute("data-lines-max", linesMax);
        if(characterLineLimit){
          widget.setAttribute("data-character-line-limit", characterLineLimit);
          //widget.querySelector("textarea.input-text").setAttribute("maxlength", characterLineLimit * linesMax);
        }
        if(textSizeMin){
          widget.setAttribute("data-text-size-min", textSizeMin);
        }
      } else {
        widget.querySelector("textarea.input-text").remove();
        //characterLimit
        if(characterLimit){
          widget.setAttribute("data-maxlength", characterLimit);
          widget.querySelector("input.input-text").setAttribute("maxlength", characterLimit);
        }
      }
      widget.querySelector(".input-text").value = defaultText;
      displayInfoMaxCharacters(widget);
      displayInfoMaxLines(widget);
      fillNameWidgetText(widget, model, index);
      
      if(!model.textPersonalisation) {
        document.querySelectorAll(".customizable-element").forEach((item) => {
          item.classList.add("d-none");
        });
        //widget.querySelector('.wrap-text-personalisation').classList.add("d-none");
      }
      if(!item.allowLineSpaceChange) {
        if(widget.querySelector('.wrap-line-space') !== null){
          widget.querySelector('.wrap-line-space').classList.add("d-none");
        }
      }
      
      widget.querySelector('.widget-title span.name').textContent = item.elementName;
      if (item.mandatory === "false" || item.mandatory === false) {
        widget.querySelector('.widget-title span.option').style.display = "inline";
      }
      
      if(enableCurved){
        widget.querySelector(".wrap-curved").classList.remove("d-none");
      }
      
      //fontFamily
      const target = widget.querySelector(".fonts");
      if(item.fonts.length < 2){
        widget.querySelector(".wrap-fonts").classList.add("d-none");
      }
      let defaultFont = fillCustomSelectFont(target, item.fonts);
      if (item.defaultTextFont !== null) {
        defaultFont = item.defaultTextFont.fontName + "*" + item.defaultTextFont.filePath;
      }
      setValueCustomSelectFont(target, defaultFont);
      
      // print color
      if (item.forcePrintColor) {
        widget.dataset.printColor = item.printColor;
        //Disable the choice of stroke and shadow color
        item.strokeColors = [{
          colorName: "transparent",
          hexadecimal: "transparent"
        }];
        item.shadowColors = [{
          colorName: "transparent",
          hexadecimal: "transparent"
        }];
      }
      
      //fontColor
      const fontColorTarget = widget.querySelector(".font-color");
      if(item.fontColors.length < 2){
        widget.querySelector(".wrap-font-color").classList.add("d-none");
      }
      let defaultFontColor = fillCustomSelectFontColor(fontColorTarget, item.fontColors);
      if (item.defaultTextFontColor !== null) {
        defaultFontColor = item.defaultTextFontColor.hexadecimal;
      }
      setValueCustomSelectFontColor(fontColorTarget, defaultFontColor);
      
      //strokeColor
      const strokeTarget = widget.querySelector(".stroke-color");
      if(item.strokeColors.length < 2){
        widget.querySelector(".wrap-stroke-color").classList.add("d-none");
      }
      let defaultStrokeColor = fillCustomSelectStroke(strokeTarget, item.strokeColors);
      if (item.defaultStrokeColor !== null && !item.forcePrintColor) {
        defaultStrokeColor = item.defaultStrokeColor.hexadecimal;
      }
      setValueCustomSelectStroke(strokeTarget, defaultStrokeColor);
      
      //shadowColor
      const shadowTarget = widget.querySelector(".shadow-color");
      if(item.shadowColors.length < 2){
        widget.querySelector(".wrap-shadow-color").classList.add("d-none");
      }
      let defaultShadowColor = fillCustomSelectShadow(shadowTarget, item.shadowColors);
      if (item.defaultShadowColor !== null && !item.forcePrintColor) {
        defaultShadowColor = item.defaultShadowColor.hexadecimal;
      }
      setValueCustomSelectShadow(shadowTarget, defaultShadowColor);
      
      let fontWeight = 'normal';
      if (item.textBold) {
        fontWeight = 'bold';
        widget.querySelector(".input-font-weight").checked = true;
      }
      
      let fontStyle = 'normal';
      if (item.textItalic) {
        fontStyle = 'italic';
        widget.querySelector(".input-font-style").checked = true;
      }
      
      widget.querySelector('.input-flipped').checked = false;
      if(flipped){
        widget.querySelector(".input-flipped").checked = true;
      }
      
      widget.querySelector(".input-diameter-reverse").value = 0;
      widget.querySelector(".input-diameter").value = 0;
      if(diameterPx > 0){
        widget.querySelector(".input-diameter-reverse").value = 10000 / diameterPx;
        widget.querySelector(".input-diameter").value = diameterPx;
      }
      
      if(item.textAlignment === ""){
        item.textAlignment = "center";
      }
      if(document.querySelector("#text_align" + prefixModelId + "_" + item.textAlignment + "_" + index) !== null){
        document.querySelector("#text_align" + prefixModelId + "_" + item.textAlignment + "_" + index).checked = true;
      }
      
      let text;
      const optionTmp = {
        ...defaultTextOption, ...option,
        diameter: diameter,
        flipped: flipped,
        top: 0,
        left: 0,
        angle: 0,
        fontWeight: fontWeight,
        fontStyle: fontStyle,
        textAlign: item.textAlignment
      };
      
      if(diameter === 0) {
        
        if(linesMax > 1){
          text = new fabric.Textbox(defaultText, {
            ...optionTmp,
            //backgroundColor: "#e3dac9",
            lockScalingX: true,
          });
        }else{
          text = new fabric.Text(defaultText, optionTmp);
        }
        /*
        text = new fabric.Text(defaultText, optionTmp);
        */
      } else {
        text = new fabric.TextCurved(defaultText, optionTmp);
      }
      
      const rect = createRectForGroup({width: width, height: height});
      element = new fabric.Group([ rect ], {
        ...textOption,
        textAlign: item.textAlignment,
        strokeColor: defaultStrokeColor,
        shadowColor: defaultShadowColor,
      });
      
      element.add(text);
      
      element.clipPath = new fabric.Rect({
        width: 1,
        height: 1,
        top: - element.height / 2,
        left: - element.width / 2
      });
      cachingGroup(element);
      
      canvas.add(element);
      
      setFontColor(element, defaultFontColor);
      updateStrokeColor(element);
      updateShadowColor(element);
      
      
      const textElement = element.item(1);
      
      if (defaultFont != null && defaultFont != "") {
        setFont(element, defaultFont, widget, false);
      } else {
        adjustTextElement(element);
      }
      
      if (item.defaultTextSize !== null) {
        widget.querySelector(".input-size").value = item.defaultTextSize;
        element.set('fontSize', item.defaultTextSize * scale);
        element.set('strokeWidth', item.defaultTextSize * scale / 15);
        element.set('defaultTextSize', item.defaultTextSize);
        if(element.widgetType === "text"){
          const textElement = element.item(1);
          textElement.set('fontSize', item.defaultTextSize * scale);
          textElement.set('strokeWidth', item.defaultTextSize * scale / 15);
          textElement.set('defaultTextSize', item.defaultTextSize);
        }
        updateShadowColor(element);
      }
      
      widget.querySelector(".input-line-space").value = lineHeight;
      if(item.allowLineSpaceChange) {
        if(widget.querySelector('.line-space-item[data-value="' + lineHeight + '"]') !== null){
          widget.querySelector('.line-space-item[data-value="' + lineHeight + '"]').classList.add('selected');
        }
        
        if(widget.querySelector('.line-space-item.selected') === null){
          const firstItem = widget.querySelector('.line-space-item');
          widget.querySelector(".input-line-space").value = firstItem.getAttribute('data-value');
          firstItem.classList.add('selected');
        }
      }
      
      checkTextElementValid(widget);
      howManyWidget+=1;
    } else if (item.type === "image_admin") {
      if(item.imagePath == "userfiles/elements/2395_fond_imprimable.jpg"){
        item.imagePath == "userfiles/elements/gourde_alu.jpg"
      }
      widget = document.querySelector("#template-widget-admin").content.cloneNode(true).firstElementChild;
      configPerso.append(widget);
      widget.dataset.imagePath = item.imagePath;
      
      if(!visibleOnConfigurator){
        widget.classList.add("d-none");
      }
      
      //if(item.visibleOnConfigurator){
      var fileExtension = imagePath.split('.').pop();
      if(fileExtension === "svg"){
        fabric.loadSVGFromURL("/" + imagePath, function(objects, options) {
          fabric.loadSVGFromURL("/" + imagePath, function(objectTmps, optionTmps) {
            element = fabric.util.groupSVGElements(objects, options);
            const elementTmp = fabric.util.groupSVGElements(objectTmps, optionTmps);
            
            element.set({
              left: option.left,
              top: option.top,
              opacity: option.opacity,
              selectable: option.selectable,
              scaleX: option.width / element.width,
              scaleY: option.height / element.height,
              angle: option.angle,
              id: option.id,
              index: option.index,
              zIndex: option.zIndex,
              visibleOnConfigurator: option.visibleOnConfigurator,
              visibleOnPrint: option.visibleOnPrint,
              linkedToPrintZone: option.linkedToPrintZone,
              free: option.free,
              widgetType: option.widgetType,
              evented: false,
              isSVG: true,
              elementTmp: elementTmp
            });
            
            element.setControlsVisibility({
              tl: false, //top-left
              mt: false, // middle-top
              tr: false, //top-right
              ml: false, //middle-left
              mr: false, //middle-right
              bl: false, // bottom-left
              mb: false, //middle-bottom
              br: false, //bottom-right
            });
            
            if (item.allowRotation && item.visibleOnConfigurator) {
              element.setControlsVisibility({
                mtr: true //rotation
              });
            } else {
              element.setControlsVisibility({
                mtr: false //rotation
              });
            }
            
            element.setControlsVisibility({
              deleteControl: false //deletion
            });
            
            cachingGroup(element);
            canvas.add(element);
            canvas.renderAll();
            
            ReorderObjects(canvas);
          });
        });
      } else {
        let path = "/" + item.imagePath;
        if(item.imagePathCanva !== ""){
          path = "/" + item.imagePathCanva;
        }
        
        const rect = createRectForGroup({width: option.originalWidth, height: option.originalHeight, stroke: 'rgba(0,0,0,0)'});
        element = new fabric.Group([ rect ], {
          ...defaultOption, ...option,
          evented: false,
        });
        cachingGroup(element);
        
        canvas.add(element);
        
        fabric.Image.fromURL(path, function (oImg) {
          let scaleX = element.originalWidth / oImg.width;
          let scaleY = element.originalHeight / oImg.height;
          
          const topImg = - oImg.height * scaleY/ 2;
          const leftImg = - oImg.width * scaleX/ 2;
          oImg.set({
              top: topImg,
              left: leftImg,
              scaleX: scaleX,
              scaleY: scaleY
            }
          );
          element.add(oImg);
          canvas.renderAll();
        });
        
        if(blended){
          element.globalCompositeOperation = 'multiply';
        }
      }
      //}
      //howManyWidget+=1;
    } else if (item.type === "image_upload") {
      widget = document.querySelector("#template-widget-upload").content.cloneNode(true).firstElementChild;
      configPerso.append(widget);
      widget.querySelector('.input-file').setAttribute("name", "file" + prefixModelId + "_" + index);
      if (item.mandatory === false || item.mandatory === "false") {
        widget.querySelector('.widget-title span.option').style.display = "inline";
      }
      
      const rect = createRectForGroup({width: option.originalWidth, height: option.originalHeight});
      element = new fabric.Group([ rect ], {
        ...defaultOption, ...option
      });
      cachingGroup(element);
      
      canvas.add(element);
      howManyWidget+=1;
    } else if (item.type === "image_library") {
      widget = document.querySelector("#template-widget-library").content.cloneNode(true).firstElementChild;
      configPerso.append(widget);
      
      if(!visibleOnConfigurator){
        widget.classList.add("d-none");
      }
      if (item.mandatory === false || item.mandatory === "false") {
        widget.querySelector('.widget-title span.option').style.display = "inline";
        widget.classList.add('ok-valid');
        widget.querySelector('.reset-libraries-wrap').classList.remove("d-none");
      }
      
      const rect = createRectForGroup({width: option.originalWidth, height: option.originalHeight});
      element = new fabric.Group([ rect ], {
        ...defaultOption, ...option
      });
      cachingGroup(element);
      canvas.add(element);
      
      if(imagelibraryPath !== "") {
        const src = "/" + imagelibraryPath;
        widget.dataset.libraryFileId = imagelibraryId;
        widget.classList.add('ok-valid');
        setImgFabric(element, imagelibraryId, src);
      }
      howManyWidget+=1;
    } else if (item.type === "image_upload_or_library") {
      widget = document.querySelector("#template-widget-upload-library").content.cloneNode(true).firstElementChild;
      configPerso.append(widget);
      if (item.mandatory === false || item.mandatory === "false") {
        widget.querySelector('.widget-title span.option').style.display = "inline";
      }
      
      widget.querySelector('.input-file').setAttribute("name", "file" + prefixModelId + "_" + index);
      
      const rect = createRectForGroup({width: option.originalWidth, height: option.originalHeight});
      element = new fabric.Group([ rect ], {
        ...defaultOption, ...option
      });
      cachingGroup(element);
      canvas.add(element);
      
      if(imagelibraryPath !== "") {
        const src = "/" + imagelibraryPath;
        widget.dataset.libraryFileId = imagelibraryId;
        //defaultDatas.type = "image_library";
        widget.classList.add('ok-valid');
        setImgFabric(element, imagelibraryId, src);
      }
      howManyWidget+=1;
    }
    
    setDefaultData(widget, defaultDatas);
    
    if (typeof element !== 'undefined') {
      element.setControlsVisibility({
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
      });
      
      if (item.allowRotation && item.visibleOnConfigurator) {
        element.setControlsVisibility({
          mtr: true //rotation
        });
      } else {
        element.setControlsVisibility({
          mtr: false //rotation
        });
      }
      
      if (item.allowMovement) {
        element.setControlsVisibility({
          moveObject: true //rotation
        });
      } else {
        element.setControlsVisibility({
          moveObject: false //rotation
        });
      }
      
      element.setControlsVisibility({
        deleteControl: false //deletion
      });
      
      handleEvent(canvas, model, widget);
      handleDropDown(canvas, widget);
    }
  });
  
  if(howManyWidget === 1){  // desactiver le overflow quand on a qu'un widget Text (pour les couleurs)
    configPerso.style.overflow = "inherit";
  }
  
  if(model.watermarkOnCartRendering){
    drawCanvasWatermark(canvas, model);
  }
  
  const rect = createRectForGroup({width: model.width * scale, height: model.height * scale, fill: 'rgba(0,0,0,0)', stroke: 'rgba(0,0,0,0)'});
  const element = new fabric.Group([ rect ], {
    left: 0,
    top: 0,
    hasControls: false,
    hoverCursor: "auto",
    selectable: false,
    evented: false,
    zIndex: 200,
    visibleOnConfigurator: true,
    widgetType: "limit_zone"
  });
  canvas.add(element);
  //Reput elements according to their levels
  ReorderObjects(canvas);
  
  document.querySelector('body').classList.remove('more-widget');
  configPerso.setAttribute("data-visible-widget", configPerso.querySelectorAll(".personnalisation-bit:not(.d-none)").length);
  if(configPerso.querySelectorAll(".personnalisation-bit:not(.d-none)").length > 2){
    document.querySelector('body').classList.add('more-widget');
  }
}

/*
 * this function resolve bug when group has a text element with shadow
 */
function cachingGroup(element){
  element.objectcaching = true;
  element.shouldCache = function() {
    var ownCache = fabric.Object.prototype.shouldCache.call(this);
    return ownCache;
  }
}

/*
 * Reput elements in cancas according to their levels
 */
function ReorderObjects(canvas){
  let objectTmps = [...canvas.getObjects()];
  objectTmps.sort((a, b) => a.zIndex - b.zIndex);
  objectTmps.map(function (o) {
    canvas.bringToFront(o);
  });
  
  canvas.renderAll();
}

/*
 * Expected behavior when deselecting all objects
 */
function handleDeselectAllObjects(canvas){
  //close all popup before
  document.querySelectorAll(".popup").forEach((popup) => {
    popup.remove();
  });
  
  canvas.discardActiveObject();
  canvas.getObjects().forEach((object) => {
    object.stateMovement = 0;
  });
  document.querySelector("body").classList.remove('body-index-change');
  canvas.renderAll();
  
  document.querySelector('body').classList.remove('focus')
}

/*
 * Expected behavior when deselecting an object
 */
function handleDeselectObject(object){
  const canvas = object.canvas;
  object.dirty = true;
  if (canvas.getActiveObject() == null) {
    //close all popup before
    document.querySelectorAll(".popup").forEach((popup) => {
      popup.remove();
    });
    
    //enableScroll();
    document.querySelectorAll(".personnalisation-bit").forEach((item) => {
      item.classList.remove('focus-mobile');
      item.classList.remove("selected");
    });
    document.querySelector("body").classList.remove('body-index-change');
  }
  canvas.renderAll();
  if (document.body.clientWidth < 767) {
    if(enableRemoveFocus){
      document.querySelector('body').classList.remove("focus");
      var height = document.body.clientHeight;
      var boxValidationHeight = document.querySelector('#box-validation').clientHeight;
      var headerHeight = document.querySelector('.header-body').clientHeight;
      var productsBundlesHeight = document.querySelector('.products-bundles').clientHeight;
      var newHeight = height - boxValidationHeight - headerHeight - productsBundlesHeight;
      document.querySelectorAll('.wrap-canvas').forEach((element) => {
        element.style.height = newHeight + "px";
      });
    }
    
    if(object.enableMovement) {
      object.stateMovement = 0;
      object.lockMovementX = true;
      object.lockMovementY = true;
      object.dirty = true;
    }
  }
}

/*
 * Expected behavior when selecting an object
 */
function handleSelectObject(object){
  const canvas = object.canvas;
  //const modelId = canvas.modelId;
  const itemId = canvas.itemId;
  //disableScroll();
  object.dirty = true;
  document.querySelectorAll(".personnalisation-bit").forEach((item) => {
    item.classList.remove("selected");
  });
  const widgetToSelect = document.querySelector('#config-perso-' + itemId + ' .personnalisation-bit[data-index="' + object.index + '"]');
  widgetToSelect.classList.add("selected");
  
  widgetToSelect.closest(".config-perso").scrollTo({
    top: widgetToSelect.offsetTop - widgetToSelect.offsetHeight,
    behavior: "smooth"
  });
  
  if (document.body.clientWidth < 767) {
    handleEditObject(object);
  }
  
  if (document.body.clientWidth < 767) {
    document.querySelector('body').classList.add("focus");
    
    var height = document.body.clientHeight - 20;
    var personnalisationHeight = document.querySelector('.personnalisation-bit.selected').clientHeight;
    var productsBundlesHeight = document.querySelector('.products-bundles').clientHeight;
    var newHeight = height - personnalisationHeight - productsBundlesHeight;
    document.querySelectorAll('.wrap-canvas').forEach((element) => {
      element.style.height = newHeight + "px";
    });
    
    enableRemoveFocus = false;
    setTimeout(function () {
      enableRemoveFocus = true;
    }, 5);
    
    /*
    if(object.enableMovement){
      setTimeout(function () {
        object.lockMovementX = false;
        object.lockMovementY = false;
        object.dirty = true;
      }, 1000);
    }
    */
  }
}

/*
 * Expected behavior when updating an object
 */
function handleModifyObject(object){
  const canvas = object.canvas;
  //const modelId = canvas.modelId;
  const itemId = canvas.itemId;
  const scale = canvas.scale;
  const widget = document.querySelector('#config-perso-' + itemId + ' .personnalisation-bit[data-index="' + object.index + '"]');
  let defaultDatas = {};
  if( object.widgetType === "free_text" ){
    const diameterPx = Math.round(object.diameter / scale);
    
    const newWidth = object.width * object.scaleX;
    const newWidthPx = newWidth / scale;
    object.width = newWidth;
    const newHeight = object.height * object.scaleY;
    const newHeightPx = newHeight / scale;
    object.height = newHeight;
    const newFonstSize = object.fontSize * object.scaleX;
    object.fontSize = newFonstSize;
    object.strokeWidth = newFonstSize / 15;
    object.scaleX = 1;
    object.scaleY = 1;
    object.dirty = true;
    canvas.renderAll();
    updateShadowColor(object);
    
    defaultDatas = {
      widthPx: Math.round(newWidthPx),
      heightPx: Math.round(newHeightPx),
      width: Math.round(newWidth),
      height: Math.round(newHeight),
      angle: Math.round(object.angle),
      coordinatesY: Math.round(object.top / scale),
      coordinatesX: Math.round(object.left / scale),
      left: Math.round(object.left),
      top: Math.round(object.top),
    };
    widget.querySelector(".input-size").value = parseInt(newFonstSize / scale);
    
    if(object.type === "text-curved"){
      widget.querySelector(".input-diameter-reverse").value = 10000 / diameterPx;
      widget.querySelector(".input-diameter").value = diameterPx;
    }
  } else if (object.widgetType === "free_image_upload" || object.widgetType === "free_image_library") {
    const newWidth = object.width * object.scaleX;
    const newWidthPx = newWidth / scale;
    const newHeight = object.height * object.scaleY;
    const newHeightPx = newHeight / scale;
    defaultDatas = {
      widthPx: Math.round(newWidthPx),
      heightPx: Math.round(newHeightPx),
      width: Math.round(newWidth),
      height: Math.round(newHeight),
      angle: Math.round(object.angle),
      coordinatesY: Math.round(object.top / scale),
      coordinatesX: Math.round(object.left / scale),
      left: Math.round(object.left),
      top: Math.round(object.top),
    };
  } else {
    defaultDatas = {
      angle: Math.round(object.angle),
      coordinatesY: Math.round(object.top / scale),
      coordinatesX: Math.round(object.left / scale),
      left: Math.round(object.left),
      top: Math.round(object.top)
    }
  }
  setDefaultData(widget, defaultDatas);
}

/*
 * function called when creating a free text type element
 * data in case of update
 */
function addFreeWidgetText(canvas, model, datas = null) {
  //update
  const scale = canvas.scale;
  let topPx, leftPx, zIndex;
  if (datas) {
    zIndex = datas.level;
    topPx = parseFloat(datas.coordinatesY);
    leftPx = parseFloat(datas.coordinatesX);
    
    if (datas.level > globalIndex) {
      globalIndex = datas.level;
    }
  } else {
    globalIndex++;
    zIndex = globalIndex;
    topPx = model.printZoneCoordinatesY;
    leftPx = model.printZoneCoordinatesX;
  }
  
  const index = globalIndex;
  let widthPx = model.printZoneWidthPx;
  let heightPx = model.printZoneHeightPx;
  if (datas) {
    widthPx = datas.widthPx;
    heightPx = datas.heightPx;
  }
  const width = parseInt(widthPx * scale);
  const height = parseInt(heightPx * scale);
  const angle = datas ? datas.orientation : 0;
  const top = parseInt(topPx * scale);
  const left = parseInt(leftPx * scale);
  
  let lineHeight = model.lineSpace === null ? 1 : model.lineSpace;
  if (datas) {
    lineHeight = datas.lineHeight;
  }
  
  const visibleOnConfigurator = datas ? datas.visibleOnConfigurator : true;
  const visibleOnPrint = datas ? datas.visibleOnPrint : true;
  const linkedToPrintZone = datas ? datas.linkedToPrintZone : true;
  const defaultText = datas ? datas.textContent : "Add text";
  const linesMax = 15;
  let diameterPx = 0;
  let flipped = false;
  let widgetType = "free_text";
  
  if (datas) {
    if (datas.diameter === null){
      datas.diameter = 0;
    }
    diameterPx = datas.diameter;
    flipped = datas.curvedFlipped;
  }
  
  const diameter = parseInt(diameterPx * scale);
  
  let defaultDatas = {
    widthPx: widthPx,
    heightPx: heightPx,
    width: width,
    height: height,
    angle: angle,
    id: "f" + index,
    index: index,
    linesMax: linesMax,
    mandatory: true,
    blended: false,
    coordinatesX: leftPx,
    coordinatesY: topPx,
    top: top,
    left: left,
    level: zIndex,
    type: widgetType
  }
  
  const widget = document.querySelector("#template-free-widget-text").content.cloneNode(true).firstElementChild;
  
  // hide text personalisation if not allowed
  if (!model.textPersonalisation) {
    document.querySelectorAll(".customizable-element").forEach((item) => {
      item.classList.add("d-none");
    });
    //widget.querySelector('.wrap-text-personalisation').classList.add("d-none");
  }
  document.querySelector("#config-perso-" + model.itemId).append(widget);
  
  const count = document.querySelectorAll("#config-perso-" + model.itemId + " .personnalisation-bit:not(.d-none)").length;
  const overflow = count > 1 ? "auto" : "inherit";
  document.querySelector("#config-perso-" + model.itemId).style.overflow = overflow;
  
  fillNameWidgetText(widget, model, index);
  
  widget.querySelector('.widget-title span.name').textContent = "un texte";
  widget.querySelector('.widget-title span.option').style.display = "inline";
  
  setDefaultData(widget, defaultDatas);
  
  let option = {
    top: top,
    left: left,
    width: width,
    height: height,
    originalWidth: width,
    originalHeight: height,
    angle: angle,
    id: "f" + index,
    index: index,
    zIndex: zIndex,
    linesMax: linesMax,
    visibleOnConfigurator: visibleOnConfigurator,
    visibleOnPrint: visibleOnPrint,
    linkedToPrintZone: linkedToPrintZone,
    free: true,
    clipPath: canvas.originalClipPath,
    diameter: diameter,
    flipped: flipped,
    widgetType: widgetType,
    enableMovement: true,
    stateMovement: 2,
  }
  
  if(lineHeight !== null){
    option.lineHeight = lineHeight;
  }
  
  widget.querySelector(".input-text").value = defaultText;
  
  //fontFamily
  const target = widget.querySelector(".fonts");
  if(model.fonts.length < 2){
    widget.querySelector(".wrap-fonts").classList.add("d-none");
  }
  let defaultFont = fillCustomSelectFont(target, model.fonts);
  let hasFont = false;
  if(datas){
    const font = model.fonts.find( font => font.fontName == datas.fontName);
    if (font !== null && typeof font !== 'undefined') {
      defaultFont = font.fontName + "*" + datas.fontPath;
      hasFont = true;
    }
  }
  if (!hasFont && model.defaultTextFont !== null) {
    defaultFont = model.defaultTextFont.fontName + "*" + model.defaultTextFont.filePath;
  }
  setValueCustomSelectFont(target, defaultFont);
  
  //fontColor
  const fontColorTarget = widget.querySelector(".font-color");
  if(model.fontColors.length < 2){
    widget.querySelector(".wrap-font-color").classList.add("d-none");
  }
  let defaultFontColor = fillCustomSelectFontColor(fontColorTarget, model.fontColors);
  let hasFontColor = false;
  if(datas){
    const color = model.fontColors.find( color => color.hexadecimal == datas.fontColorHexa);
    if (color !== null && typeof color !== 'undefined') {
      defaultFontColor = color.hexadecimal;
      hasFontColor = true;
    }
  }
  if (!hasFontColor && model.defaultTextFontColor !== null) {
    defaultFontColor = model.defaultTextFontColor.hexadecimal;
  }
  setValueCustomSelectFontColor(fontColorTarget, defaultFontColor);
  
  //strokeColor
  const strokeTarget = widget.querySelector(".stroke-color");
  if(model.strokeColors.length < 2){
    widget.querySelector(".wrap-stroke-color").classList.add("d-none");
  }
  let defaultStrokeColor = fillCustomSelectStroke(strokeTarget, model.strokeColors);
  let hasStrokeColor = false;
  if(datas){
    const color = model.strokeColors.find( color => color.hexadecimal == datas.textStroke);
    if (color !== null && typeof color !== 'undefined') {
      defaultStrokeColor = color.hexadecimal;
      hasStrokeColor = true;
    }
  }
  if (!hasStrokeColor && model.defaultStrokeColor !== null) {
    defaultStrokeColor = model.defaultStrokeColor.hexadecimal;
  }
  setValueCustomSelectStroke(strokeTarget, defaultStrokeColor);
  
  //shadowColor
  const shadowTarget = widget.querySelector(".shadow-color");
  if(model.shadowColors.length < 2){
    widget.querySelector(".wrap-shadow-color").classList.add("d-none");
  }
  let defaultShadowColor = fillCustomSelectShadow(shadowTarget, model.shadowColors);
  let hasShadowColor = false;
  if(datas){
    const color = model.shadowColors.find( color => color.hexadecimal == datas.textShadow);
    if (color !== null && typeof color !== 'undefined') {
      defaultShadowColor = color.hexadecimal;
      hasShadowColor = true;
    }
  }
  if (!hasShadowColor && model.defaultShadowColor !== null) {
    defaultShadowColor = model.defaultShadowColor.hexadecimal;
  }
  setValueCustomSelectShadow(shadowTarget, defaultShadowColor);
  
  if (flipped) {
    widget.querySelector('.input-flipped').checked = true;
  } else {
    widget.querySelector('.input-flipped').checked = false;
  }
  
  if (diameterPx == 0) {
    widget.querySelector(".input-diameter-reverse").value = 0;
    widget.querySelector(".input-diameter").value = 0;
  } else {
    widget.querySelector(".input-diameter-reverse").value = 10000 / diameterPx;
    widget.querySelector(".input-diameter").value = diameterPx;
  }
  
  if(!model.allowLineSpaceChange) {
    widget.querySelector('.wrap-line-space').classList.add("d-none");
  }
  
  widget.querySelector(".input-line-space").value = lineHeight;
  if(widget.querySelector('.line-space-item[data-value="' + lineHeight + '"]') !== null){
    widget.querySelector('.line-space-item[data-value="' + lineHeight + '"]').classList.add('selected');
  }
  
  if(widget.querySelector('.line-space-item.selected') === null){
    const firstItem = widget.querySelector('.line-space-item');
    widget.querySelector(".input-line-space").value = firstItem.getAttribute('data-value');
    firstItem.classList.add('selected');
  }
  
  let fontSize = datas ? datas.textSize : 18;
  let element;
  if (diameter > 0) {
    element = new fabric.TextCurved(defaultText, {
      ...defaultTextOption, ...option,
      fontSize: fontSize * scale,
      strokeWidth: fontSize * scale / 15
    });
  } else {
    element = new fabric.Text(defaultText, {
      ...defaultTextOption, ...option,
      fontSize: fontSize * scale,
      strokeWidth: fontSize * scale / 15
    });
  }
  canvas.add(element);
  widget.querySelector(".input-size").value = fontSize;
  
  //fontColor
  setFontColor(element, defaultFontColor);
  
  //stroke
  element.strokeColor = defaultStrokeColor;
  updateStrokeColor(element);
  
  //shadow
  element.shadowColor = defaultShadowColor;
  updateShadowColor(element)
  
  //font
  if(defaultFont !== null && defaultFont !== ""){
    if (datas) {
      setFont(element, defaultFont, widget, false);
    }else{
      setFont(element, defaultFont, widget, true);
    }
  }
  
  if (datas) {
    if (datas.textBold) {
      widget.querySelector('.input-font-weight').checked = true;
      element.fontWeight = "bold";
    }
    if (datas.textItalic) {
      widget.querySelector('.input-font-style').checked = true;
      element.fontStyle = "italic";
    }
    
    if (datas.textAlignment !== "center") {
      widget.querySelectorAll('.input-text-align').forEach(input=>{
        if (input.value === datas.textAlignment) {
          input.checked = true;
          element.textAlign = datas.textAlignment;
        }
        else {
          input.checked = false;
        }
      })
    }
  }
  
  checkTextElementValid(widget);
  element.setControlsVisibility({
    tl: false, //top-left
    mt: false, // middle-top
    tr: false, //top-right
    ml: false, //middle-left
    mr: false, //middle-right
    bl: false, // bottom-left
    mb: false, //middle-bottom
    br: true, //bottom-right
    mtr: true, //rotation
  });
  
  element.lockScalingX = false;
  element.lockScalingY = false;
  
  handleEvent(canvas, model, widget);
  widget.click();
  
  //Reput elements according to their levels
  ReorderObjects(canvas);
  handleDropDown(canvas, widget);
}

/*
 * function called when creating a free image upload element
 */
function addFreeWidgetImageUpload(canvas, model, datas = null){
  globalIndex++;
  //update
  let freeIndex = null;
  let topPx, leftPx, widthPx, heightPx;
  if (datas) {
    freeIndex = datas.index;
    topPx = parseFloat(datas.coordinatesY);
    leftPx = parseFloat(datas.coordinatesX);
    widthPx = parseFloat(datas.widthPx);
    heightPx = parseFloat(datas.heightPx);
  }
  
  let prefixModelId = "";
  if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
    prefixModelId = "_" + model.itemId;
  }
  
  const widget = document.querySelector("#template-free-widget-upload").content.cloneNode(true).firstElementChild;
  document.querySelector("#config-perso-" + model.itemId).append(widget);
  
  const count = document.querySelectorAll("#config-perso-" + model.itemId + " .personnalisation-bit:not(.d-none)").length;
  const overflow = count > 1 ? "auto" : "inherit";
  document.querySelector("#config-perso-" + model.itemId).style.overflow = overflow;
  
  widget.querySelector('.input-file').setAttribute("name", "file" + prefixModelId + "_" + (datas ? freeIndex : globalIndex));
  widget.querySelector(".input-file").click();
  
  widget.querySelector(".input-file").addEventListener('change', (e) => {
    let file = null;
    if (datas) {
      file = input;
    } else {
      file = e.target.files[0];
    }
    
    console.log("file.size", file.size);
    console.log("model.printFormat", model.printFormat);
    
    if(model.printFormat !== "pdf" || file.size <= 3145728){
      const reader = new FileReader();
      reader.onload = function (file) {
        const imgLink = file.target.result;
        
        const validImage = imgLink.startsWith("data:image/png") || imgLink.startsWith("data:image/jpeg") || imgLink.startsWith("data:image/jpg") || imgLink.startsWith("data:image/webp");
        if (validImage) {
          const imageResize = document.createElement("img");
          imageResize.setAttribute('id', 'img-resize');
          imageResize.src = imgLink;
          imageResize.style.width = '300px';
          imageResize.style.height = 'auto';
          Jcrop.load(imageResize).then(img => {
            if (img.naturalWidth > 0 && img.naturalHeight > 0) {
              const jcropOptions = {
                widget: widget,
                element: null,
                widgetValid: null
              }
              const instance = createJcropPopup(imageResize, img, img.width, img.height, jcropOptions);
              
              document.querySelector("#valid-crop").addEventListener('click', (e) => {
                const x = parseInt(document.querySelector("#jcrop-x").value);
                const y = parseInt(document.querySelector("#jcrop-y").value);
                let w = parseInt(document.querySelector("#jcrop-w").value);
                let h = parseInt(document.querySelector("#jcrop-h").value);
                if (w == 0) w = 1;
                if (h == 0) h = 1;
                
                const coeff = img.naturalWidth / img.width;
                
                let widgetWidthPx;
                let widgetHeightPx;
                if ((w / h) >= (model.printZoneWidthPx / model.printZoneHeightPx)) {
                  widgetWidthPx = model.printZoneWidthPx / 2;
                  widgetHeightPx = h * widgetWidthPx / w;
                } else {
                  widgetHeightPx = model.printZoneHeightPx / 2;
                  widgetWidthPx = w * widgetHeightPx / h;
                }
                
                topPx = model.printZoneCoordinatesY + (model.printZoneHeightPx - widgetHeightPx) / 2;
                leftPx = model.printZoneCoordinatesX + (model.printZoneWidthPx - widgetWidthPx) / 2;
                
                const cropX = x * coeff;
                const cropY = y * coeff;
                const cropWidth = w * coeff;
                const cropHeight = h * coeff;
                
                const drawOption = {
                  index: globalIndex,
                  zIndex: globalIndex,
                  imgLink: imgLink,
                  widthPx: widgetWidthPx,
                  heightPx: widgetHeightPx,
                  topPx: topPx,
                  leftPx: leftPx,
                  orientation: 0,
                  mandatory: false,
                  blended: false,
                  id: globalIndex,
                  cropX: cropX,
                  cropY: cropY,
                  imageResizeWidth: cropWidth,
                  imageResizeHeight: cropHeight,
                  visibleOnConfigurator: true,
                  visibleOnPrint: true,
                  linkedToPrintZone: true,
                  free: true,
                  type: "free_image_upload",
                };
                drawImage(canvas, model, widget, drawOption);
                
                instance.popup.remove();
                instance.jcrop.destroy();
                
                if (document.querySelector('#free-box-' + model.itemId + ' .hide-content-image') !== null) {
                  document.querySelector('#free-box-' + model.itemId + ' .hide-content-image').click();
                }
                
                if (document.body.clientWidth < 767) {
                  canvas.discardActiveObject();
                  document.querySelectorAll(".personnalisation-bit").forEach((item) => {
                    item.classList.remove('focus-mobile');
                  });
                  document.querySelector("body").classList.remove('body-index-change');
                  canvas.renderAll();
                }
              });
              
            } else {
              alert("Veuillez télécharger une image valide.")
            }
          });
        } else {
          alert("Veuillez télécharger un fichier image (jpg ou png).")
        }
      }
      if (!datas) {
        reader.readAsDataURL(file);
      }
    }else{
       alert("Veuillez télécharger une image de taille inférieure à 3Mo");
    }
    
  });
  
  
  if (datas) {
    widget.dataset.level = datas.level;
    const cropX = parseInt(datas.cropX) ? parseInt(datas.cropX) : 0;
    const cropY = parseInt(datas.cropY) ? parseInt(datas.cropY) : 0;
    
    const drawOption = {
      index: freeIndex,
      zIndex: freeIndex,
      imgLink: "/" + datas.imagePath,
      widthPx: widthPx,
      heightPx: heightPx,
      topPx: topPx,
      leftPx: leftPx,
      orientation: datas.orientation,
      mandatory: false,
      blended: false,
      id: freeIndex,
      cropX: cropX,
      cropY: cropY,
      imageResizeWidth: datas.imageResizeWidth,
      imageResizeHeight: datas.imageResizeHeight,
      visibleOnConfigurator: true,
      visibleOnPrint: true,
      linkedToPrintZone: true,
      free: true,
      type: "free_image_upload",
    };
    drawImage(canvas, model, widget, drawOption);
  }
}

function drawImage(canvas, model, widget, drawOption){
  const scale = canvas.scale;
  const top = drawOption.topPx * scale;
  const left = drawOption.leftPx * scale;
  const width = drawOption.widthPx * scale;
  const height = drawOption.heightPx * scale;
  
  const defaultDatas = {
    widthPx: drawOption.widthPx,
    heightPx: drawOption.heightPx,
    width: width,
    height: height,
    angle: drawOption.orientation,
    index: drawOption.index,
    mandatory: drawOption.mandatory,
    blended: drawOption.blended,
    coordinatesX: drawOption.leftPx,
    coordinatesY: drawOption.topPx,
    top: top,
    left: left,
    level: drawOption.zIndex,
    id: drawOption.id,
    linesMax: 0,
    cropX: drawOption.cropX,
    cropY: drawOption.cropY,
    cropWidth: drawOption.imageResizeWidth,
    cropHeight: drawOption.imageResizeHeight,
    type: drawOption.type
  };
  setDefaultData(widget, defaultDatas);
  
  const option = {
    originalWidth: width,
    originalHeight: height,
    imageResizeWidth: drawOption.imageResizeWidth,
    imageResizeHeight: drawOption.imageResizeHeight,
    top: top,
    left: left,
    index: drawOption.index,
    zIndex: drawOption.zIndex,
    clipPath: canvas.originalClipPath,
    visibleOnConfigurator: drawOption.visibleOnConfigurator,
    visibleOnPrint: drawOption.visibleOnPrint,
    linkedToPrintZone: drawOption.linkedToPrintZone,
    free: drawOption.free,
    angle: drawOption.orientation,
    widgetType: drawOption.type,
    enableMovement: true,
    stateMovement: 2,
  };
  
  const rect = createRectForGroup({width: width, height: height});
  const element = new fabric.Group([ rect ], {
    ...defaultOption, ...option
  });
  cachingGroup(element);
  
  fabric.Image.fromURL(drawOption.imgLink, function (oImg) {
    oImg.set({
        top: - height / 2,
        left: - width / 2,
        cropX: drawOption.cropX,
        cropY: drawOption.cropY,
        width: drawOption.imageResizeWidth,
        height: drawOption.imageResizeHeight,
        scaleX: width / drawOption.imageResizeWidth,
        scaleY: height / drawOption.imageResizeHeight
      }
    )
    element.add(oImg);
    canvas.renderAll();
  });
  
  element.setControlsVisibility({
    tl: false, //top-left
    mt: false, // middle-top
    tr: false, //top-right
    ml: false, //middle-left
    mr: false, //middle-right
    bl: false, // bottom-left
    mb: false, //middle-bottom
    br: true, //bottom-right
    mtr: true, //rotation
  });
  
  element.lockScalingX = false;
  element.lockScalingY = false;
  
  canvas.add(element);
  
  canvas.renderAll();
  widget.classList.remove('d-none');
  widget.classList.remove('personnalisation-bit-tmp');
  widget.classList.add('personnalisation-bit');
  widget.classList.add('ok-valid');
  handleEvent(canvas, model, widget);
  widget.click();
  checkImageQuality(drawOption.imageResizeWidth, parseInt(widget.getAttribute("data-width-px")), widget);
}

/*
 * function called when creating a free image library element
 * datas in case of update
 */
function addFreeWidgetImageLibrary(canvas, model, datas = null) {
  //update
  const scale = canvas.scale;
  let freeIndex = null;
  if (datas) {
    freeIndex = datas.index;
  }
  
  const popup = createLibrariesPopup(model.libraries);
  
  document.querySelector("#valid-image").addEventListener('click', (e) => {
    globalIndex++;
    const index = datas ? freeIndex : globalIndex;
    const zIndex = datas ? freeIndex : globalIndex;
    
    const visibleOnConfigurator = datas ? datas.visibleOnConfigurator : true;
    const visibleOnPrint = datas ? datas.visibleOnPrint : true;
    const linkedToPrintZone = datas ? datas.linkedToPrintZone : true;
    
    const imgLink = document.querySelector(".library-image-item.selected img").getAttribute("data-src");
    let id = document.querySelector(".library-image-item.selected img").dataset.id;
    
    let defaultDatas = {
      index: index,
      mandatory: false,
      level: zIndex,
      linesMax: 0,
      id: "f" + index,
      type: "free_image_library"
    };
    const widget = document.querySelector("#template-free-widget-library").content.cloneNode(true).firstElementChild;
    document.querySelector("#config-perso-" + model.itemId).append(widget);
  
    const count = document.querySelectorAll("#config-perso-" + model.itemId + " .personnalisation-bit:not(.d-none)").length;
    const overflow = count > 1 ? "auto" : "inherit";
    document.querySelector("#config-perso-" + model.itemId).style.overflow = overflow;
    
    setDefaultData(widget, defaultDatas);
    widget.dataset.libraryFileId = id;
    if (datas) {
      widget.dataset.level = datas.level;
    }
    
    const option = {
      width: 1,
      height: 1,
      index: index,
      zIndex: zIndex,
      clipPath: canvas.originalClipPath,
      free: true,
      visibleOnConfigurator: visibleOnConfigurator,
      visibleOnPrint: visibleOnPrint,
      linkedToPrintZone: linkedToPrintZone,
      widgetType: "free_image_library",
      enableMovement: true,
      stateMovement: 2,
    };
    
    let element = new fabric.Group([], {
      ...defaultOption, ...option
    });
    cachingGroup(element);
    
    fabric.Image.fromURL(imgLink, function (oImg) {
      const w = oImg.width;
      const h = oImg.height;
      
      let widgetWidthPx;
      let widgetHeightPx;
      
      if (datas) {
        widgetWidthPx = datas.widthPx;
        widgetHeightPx = datas.heightPx;
      } else {
        if ((w / h) >= (model.printZoneWidthPx / model.printZoneHeightPx)) {
          widgetWidthPx = model.printZoneWidthPx / 2;
          widgetHeightPx = h * widgetWidthPx / w ;
        } else {
          widgetHeightPx = model.printZoneHeightPx / 2;
          widgetWidthPx = w * widgetHeightPx / h;
        }
      }
      
      let topPx, leftPx;
      
      if (datas) {
        topPx = datas.coordinatesY;
        leftPx = datas.coordinatesX;
      } else {
        topPx = model.printZoneCoordinatesY + (model.printZoneHeightPx - widgetHeightPx ) / 2;
        leftPx = model.printZoneCoordinatesX + (model.printZoneWidthPx - widgetWidthPx ) / 2;
      }
      const top = topPx * scale;
      const left = leftPx * scale;
      const widgetWidth = widgetWidthPx * scale;
      const widgetHeight = widgetHeightPx * scale;
      
      const scaleX = widgetWidth / w;
      const scaleY = widgetHeight / h;
      
      let defaultDatas = {
        blended: false,
        widthPx: widgetWidthPx,
        heightPx: widgetHeightPx,
        width: widgetWidth,
        height: widgetHeight,
        angle: datas ? datas.orientation : 0,
        coordinatesX: leftPx,
        coordinatesY: topPx,
        top: top,
        left: left
      }
      setDefaultData(widget, defaultDatas);
      
      if (datas) {
        element.zIndex = datas.level;
      }
      
      const rect = createRectForGroup({width: widgetWidth, height: widgetHeight});
      element.addWithUpdate(rect);
      
      element.set({
          top: top,
          left: left,
          angle: defaultDatas.angle,
          originalWidth: widgetWidth,
          originalHeight: widgetHeight,
        }
      )
      element.setCoords();
      
      oImg.set({
          top: - widgetHeight / 2,
          left: - widgetWidth / 2,
          scaleX: scaleX,
          scaleY: scaleY
        }
      )
      element.add(oImg);
      
      canvas.renderAll();
    });
    
    element.setControlsVisibility({
      tl: false, //top-left
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: true, //bottom-right
      mtr: true, //rotation
    });
    
    element.lockScalingX = false;
    element.lockScalingY = false;
    
    canvas.add(element);
    handleEvent(canvas, model, widget);
    widget.click();
    
    popup.remove();
    
    if(document.querySelector('#free-box-' + model.itemId + ' .hide-content-image') !== null){
      document.querySelector('#free-box-' + model.itemId + ' .hide-content-image').click();
    }
    
    if (document.body.clientWidth < 767) {
      canvas.discardActiveObject();
      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
        item.classList.remove('focus-mobile');
      });
      document.querySelector("body").classList.remove('body-index-change');
      canvas.renderAll();
    }
  });
  
  // in case of update
  if (datas) {
    document.querySelectorAll(".library-image-item").forEach(item=>{
      if (parseInt(item.querySelector('img').dataset.id) === datas.libraryFileId) {
        item.dispatchEvent(new Event('click'));
        document.querySelector("#valid-image").dispatchEvent(new Event('click'));
      }
    });
  }
}

/*
 * This function allows to manage customization actions for the mobile device
 */
function handleCustomization(displayCartButton = false) {
  let showButton = "d-none";
  if(displayCartButton){
    showButton = "";
  }
  let currentPrice = document.getElementById('article-price-box').innerHTML;
  const customizationHtml = `
    <div id="box-validation" class="hide">
        <div class="free-box-mobile"></div>
        <div class="box-mobile-action d-flex justify-content-between align-items-stretch flex-wrap">
            <!--
            <span id="btn-back">
                <span>Retour</span>
            </span>
            -->
            <span id="btn-next-product" class="d-none">Etape suivante</span>
            <span id="content-qte-tmp" class="d-none">
                <span class="qte-moins-tmp">-</span>
                <span class="wrap-input-qte-tmp"><input type="text" id="input-qte-tmp" value="1"/></span>
                <span class="qte-plus-tmp">+</span>
            </span>
            <span id="price-mobile" class="d-none"><span class="price-mobile-wrap">${currentPrice}</span></span>
            <span id="btn-next-add-panier" class="${showButton}"><span class="btn-next-add-panier-mobile">Ajouter</span><span class="btn-next-add-panier-desktop">Ajouter au panier</span></span>
        </div>
    </div>`;
  document.querySelector('.btn-panier-content').insertAdjacentHTML("beforebegin", customizationHtml);
  
  //free actions
  canvasInfoList.forEach((canvasInfo) => {
    const canvas = canvasInfo.canvas;
    const model = canvasInfo.model;
    //const modelId = model.modelId;
    const itemId = model.itemId;
    const freeBoxMobile = document.querySelector('.free-box-mobile');
    const freeBoxHtml = '<div id="free-box-' + itemId + '" class="free-box  mb-3"><div class="content-image d-none"></div><div class="content-btns d-flex justify-content-between align-items-center"></div></div>';
    freeBoxMobile.innerHTML = freeBoxHtml;
    const contentImage = document.querySelector('#free-box-' + itemId + ' .content-image');
    const contentBtns = document.querySelector('#free-box-' + itemId + ' .content-btns');
    
    let displayImageButton = false;
    const enableUpload = document.querySelector('#wrap-canvas-' + itemId).getAttribute('data-enable-upload');
    const enableLibrary = document.querySelector('#wrap-canvas-' + itemId).getAttribute('data-enable-library');
    const enableText = document.querySelector('#wrap-canvas-' + itemId).getAttribute('data-enable-text');
    
    if(enableUpload === "1" || enableLibrary === "1"){
      displayImageButton = true;
      if(enableUpload === "1" && enableLibrary === "1"){
        contentImage.innerHTML = '<span class="hide-content-image">x</span><div class="font-12">Je choisis l’un de nos designs ou j’importe une image <em class="text-grey d-block pb-2">(PNG, JPG, BMP. Taille : 20 Mo max, 8000px max)</em></div><span class="free-upload btn free-btn-purple">Votre fichier</span> ou <span class="free-library btn free-btn-purple">Notre bibliothèque</span>';
      } else if (enableUpload === "1" && enableLibrary === "0"){
        contentImage.innerHTML = '<span class="hide-content-image">x</span><div><span class="free-upload btn free-btn-purple">Votre fichier</span></div>';
      } else if (enableUpload === "0" && enableLibrary === "1"){
        contentImage.innerHTML = '<span class="hide-content-image">x</span><div><span class="free-library btn free-btn-purple">Notre bibliothèque</span></div>';
      }
    }
    if(displayImageButton){
      contentBtns.insertAdjacentHTML("beforeend", '<span class="free-image btn"><i class="icon-plus color-pink-s"></i> <span>Image</span></span>');
    }
    
    if(enableText === "1"){
      contentBtns.insertAdjacentHTML("beforeend", '<span class="free-text btn"><i class="icon-plus color-pink-s"></i> <span>Texte</span></span>');
    }
    
    if(document.querySelector('#free-box-' + itemId + ' .free-image') !== null){
      document.querySelector('#free-box-' + itemId + ' .free-image').addEventListener('click', (e) => {
        contentImage.classList.remove('d-none');
      });
    }
    
    if(document.querySelector('#free-box-' + itemId + ' .free-image') !== null){
      document.querySelector('#free-box-' + itemId + ' .hide-content-image').addEventListener('click', (e) => {
        contentImage.classList.add('d-none');
      });
    }
    
    if(document.querySelector('#free-box-' + itemId + ' .free-upload') !== null){
      document.querySelector('#free-box-' + itemId + ' .free-upload').addEventListener('click', (e) => {
        addFreeWidgetImageUpload(canvas, model);
      });
    }
    
    if(document.querySelector('#free-box-' + itemId + ' .free-library') !== null){
      document.querySelector('#free-box-' + itemId + ' .free-library').addEventListener('click', (e) => {
        addFreeWidgetImageLibrary(canvas, model);
      });
    }
    
    if(document.querySelector('#free-box-' + itemId + ' .free-text') !== null){
      document.querySelector('#free-box-' + itemId + ' .free-text').addEventListener('click', (e) => {
        contentImage.classList.add('d-none');
        addFreeWidgetText(canvas, model);
      });
    }
  });
  
  // etape suivante
  if(document.querySelectorAll('.select-product-item').length >= 2){
    document.querySelector('#btn-next-product').classList.remove('d-none');
    document.querySelector('.fiche-art-qty').classList.add('d-none');
    document.querySelector('.prix-art-panier').classList.add('d-none');
    document.querySelector('#price-mobile').classList.add("d-none");
    if(document.querySelector('#content-qte-tmp')){
      document.querySelector('#content-qte-tmp').classList.add('d-none');
    }
  }
  
  document.querySelectorAll('.free-box').forEach((element) => {
    element.classList.add('d-none');
  });
  if(document.querySelectorAll('.select-product-item').length == 0){
    if(document.querySelector('.free-box') !== null){
      document.querySelector('.free-box').classList.remove('d-none');
    }
  } else {
    if(document.querySelector('#free-box-' + document.querySelector('.select-product-item.active').getAttribute('data-itemId')) != null){
      document.querySelector('#free-box-' + document.querySelector('.select-product-item.active').getAttribute('data-itemId')).classList.remove('d-none');
    }
  }
  
  document.querySelector('#btn-back').addEventListener('click', (e) => {
    let exitCustomization = true;
    if(document.querySelectorAll(".select-product-item").length >= 2){
      const activeProduct = document.querySelector('.select-product-item.active');
      if(activeProduct.previousElementSibling !== null){
        activeProduct.previousElementSibling.click();
        exitCustomization = false;
      }
    }
    
    if(exitCustomization){
      //editMode = false;
      document.querySelector('.popup-conf-wrap').classList.remove('hide');
      document.querySelector('.btn-back-wrap').classList.add('d-none');
    }
  });
  
  document.querySelector('#btn-next-product').addEventListener('click', (e) => {
    const activeProduct = document.querySelector('.select-product-item.active');
    const itemId = activeProduct.getAttribute("data-itemid");
    
    const nextElement = activeProduct.nextElementSibling;
    if(nextElement !== null){
      nextElement.click();
    } else {
      document.querySelector('.select-product-item').click();
    }
  });
  
  document.querySelector('#btn-next-add-panier').addEventListener('click', (e) => {
    if(document.querySelector('.widgets-not-valid')){
      document.querySelector('.widgets-not-valid').style.display = "none";
    }
    if(e.target.classList.contains('active') || e.target.closest("#btn-next-add-panier").classList.contains('active')){
      document.querySelector(".btn-add-panier").click();
      hideAddToCartBtn();
    } else {
      canvasInfoList.forEach((canvasInfo) => {
        const canvas = canvasInfo.canvas;
        const model = canvasInfo.model;
        const itemId = model.itemId;
        checkItemValid(itemId, true);
        checkItemValid(itemId, true);
      });
      if(document.querySelector('.widgets-not-valid')){
        document.querySelector('.widgets-not-valid').style.display = "block";
      }
    }
  });
  
  document.querySelector('.qte-plus-tmp').addEventListener('click', (e) => {
    const qteValue = parseInt(document.querySelector("#input-qte-tmp").value);
    const newQteValue = qteValue + 1;
    document.querySelector("#input-qte-tmp").value = newQteValue;
    const inputQte = document.querySelector('.sylius-quantity input[type="number"]');
    inputQte.value = newQteValue;
    inputQte.dispatchEvent(new Event('change'));
  });
  
  document.querySelector('.qte-moins-tmp').addEventListener('click', (e) => {
    const qteValue = parseInt(document.querySelector("#input-qte-tmp").value);
    if(qteValue > 1){
      const newQteValue = qteValue - 1;
      document.querySelector("#input-qte-tmp").value = newQteValue;
      const inputQte = document.querySelector('.sylius-quantity input[type="number"]');
      inputQte.value = newQteValue;
      inputQte.dispatchEvent(new Event('change'));
    }
  });
  
  document.querySelector('#input-qte-tmp').addEventListener('change', (e) => {
    let qteValue = parseInt(document.querySelector("#input-qte-tmp").value);
    if(qteValue < 1){
      qteValue = 1;
      document.querySelector("#input-qte-tmp").value = qteValue;
    }
    const inputQte = document.querySelector('.sylius-quantity input[type="number"]');
    inputQte.value = qteValue;
    inputQte.dispatchEvent(new Event('change'));
  });
}

/*
 * display elements (non advanced model)
 */
function displayElements(options) {
  const model = options.dataModel;
  const modelId = model.modelId;
  const itemId = model.itemId;
  
  let prefixModelId = "";
  if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
    prefixModelId = "_" + itemId;
  }
  
  const boxConfigPerso = document.querySelector("#box-config-perso");
  let visibleClass = "";
  if(!model.visible){
    visibleClass = "d-none";
  }
  boxConfigPerso.insertAdjacentHTML("beforeend", `<div id="config-perso-${itemId}" data-itemId="${itemId}" class="config-perso ${visibleClass} non-advanced" data-model-id="${modelId}" data-variant-id="${model.variantId}"></div>`);
  const configPerso = document.querySelector("#config-perso-" + itemId);
  
  model.objects = [];
  options.widgets.forEach((item) => {
    model.objects.push(item);
    const id = item.id;
    const index = item.index;
    const widthPx = item.width;
    const heightPx = item.height;
    //const width = widthPx * scale;
    //const height = heightPx * scale;
    const angle = item.angle;
    const topPx = item.coordinatesY;
    const leftPx = item.coordinatesX;
    //const top = topPx * scale;
    //const left = leftPx * scale;
    const zIndex = item.zIndex;
    const visibleOnConfigurator = item.visibleOnConfigurator;
    const visibleOnPrint = item.visibleOnPrint;
    const linkedToPrintZone = item.linkedToPrintZone;
    const mandatory = false;
    //const defaultText = item.defaultText ? item.defaultText : item.elementName;
    const defaultText = defaultTexts[item.id];
    const characterLimit = item.characterLimit;
    const imagePath = item.imagePath;
    const widgetType = item.type;
    const linesMax = item.linesMax ? item.linesMax : 1;
    
    let defaultDatas = {
      widthPx: widthPx,
      heightPx: heightPx,
      //width: width,
      //height: height,
      angle: angle,
      index: index,
      id: id,
      level: zIndex,
      mandatory: mandatory,
      coordinatesX: leftPx,
      coordinatesY: topPx,
      //left: left,
      //top: top,
      //linesMax: linesMax,
      type: widgetType
    }
    
    let widget;
    if (item.type === "text") {
      widget = document.querySelector("#template-simple-widget-text").content.cloneNode(true).firstElementChild;
      widget.querySelector('.widget-title span.name').textContent = item.elementName;
      if (item.mandatory === "false" || item.mandatory === false) {
        widget.querySelector('.widget-title span.option').style.display = "inline";
      }
      widget.dataset.elementName = item.elementName;
      
      const inputText = widget.querySelector(".input-text");
      inputText.value = defaultText;
      inputText.setAttribute('name', 'textContent' + prefixModelId + '_' + index);
      
      widget.querySelector(".input-text").value = defaultText;
      
      //characterLimit
      if(characterLimit){
        widget.setAttribute("data-maxlength", characterLimit);
        widget.querySelector("input.input-text").setAttribute("maxlength", characterLimit);
      }
      
      displayInfoMaxCharacters(widget);
      displayInfoMaxLines(widget);
      
      checkTextElementValid(widget);
      configPerso.append(widget);
      
      inputText.addEventListener('input', (e) => {
        checkTextElementValid(widget);
        displayInfoMaxCharacters(widget);
        displayInfoMaxLines(widget);
      });
      setDefaultData(widget, defaultDatas);
    } else if (item.type === "image_upload") {
      widget = document.querySelector("#template-simple-widget-upload").content.cloneNode(true).firstElementChild;
      widget.querySelector('.input-file').setAttribute('name', 'file' + prefixModelId + '_' + index);
      if (item.mandatory === false || item.mandatory === "false") {
        widget.querySelector('.widget-title span.option').style.display = "inline";
      }
      configPerso.append(widget);
      
      widget.querySelector('.input-file').addEventListener('change', (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (file) {
          const imgLink = file.target.result;
          
          const validImage = imgLink.startsWith("data:image/png") || imgLink.startsWith("data:image/jpeg") || imgLink.startsWith("data:image/jpg") || imgLink.startsWith("data:image/webp");
          if (validImage) {
            widget.classList.add('ok-valid');
          } else {
            widget.classList.remove('ok-valid');
          }
        }
      });
      
      setDefaultData(widget, defaultDatas);
    }
    
  });
}

function drawCanvasPrintZone(canvas, model) {
  const scale = canvas.scale;
  const index = -1;
  const width = parseInt(model.printZoneWidthPx * scale);
  const height = parseInt(model.printZoneHeightPx * scale);
  const top = parseInt(model.printZoneCoordinatesY * scale);
  const left = parseInt(model.printZoneCoordinatesX * scale);
  const zIndex = 101;
  let fill = 'rgba(0,0,0,0)';
  let stroke = 'rgba(0,200,0,0.2)';
  if (editMode && document.body.clientWidth < 767) {
    stroke = 'rgba(0,200,0,0.8)';
  }
  
  let element = new fabric.Rect({
    ...defaultOption,
    stroke: stroke,
    top: top,
    left: left,
    width: width - 1,
    height: height - 1,
    angle: 0,
    fill: fill,
    index: index,
    zIndex: zIndex,
    hasControls: false,
    hoverCursor: "auto",
    selectable: false,
    evented: false,
    visibleOnConfigurator: true,
    visibleOnPrint: true,
    linkedToPrintZone: false,
    widgetType: "print_zone"
  });
  canvas.add(element);
  
  const clipPath = new fabric.Rect({
    top: top,
    left: left,
    width: width,
    height: height,
    absolutePositioned: true,
    widgetType: "clip_path",
    opacity: 0.5
  });
  canvas.originalClipPath = clipPath;
  
  return clipPath;
}

function drawCanvasWatermark(canvas, model) {
  const scale = canvas.scale;
  const widthModel = model.width * scale;
  const heightModel = model.height * scale;
  const dimZone = 160;
  const countX = parseInt( widthModel / dimZone );
  const countY = parseInt( heightModel / dimZone );
  
  let group = new fabric.Group([], {
    left: 0,
    top: 0,
    hasControls: false,
    hoverCursor: "auto",
    selectable: false,
    evented: false,
    opacity: 0.15,
    zIndex: 200,
    visibleOnConfigurator: true,
    widgetType: "group_watermark"
  });
  canvas.add(group);
  
  fabric.Image.fromURL(watermarkSrc, function (oImg) {
    let scaleX = dimZone / oImg.width;
    let scaleY = dimZone / oImg.height;
    for (let j = 0; j <= countY; j++) {
      for (let i = 0; i <= countX; i++) {
        const imgObject = fabric.util.object.clone(oImg);
        imgObject.set({
            top: j * dimZone,
            left: i * dimZone,
            originalTop: j * dimZone,
            originalLeft: i * dimZone,
            scaleX: scaleX,
            scaleY: scaleY,
            widgetType: "watermark"
          }
        );
        group.addWithUpdate(imgObject);
      }
    }
    canvas.renderAll();
  });
  
  return group
}

/*
 * this function is called in the case of advanced configurators
 */
function updateCanvasModel(dataModel, dataElements, etatStock) {
  inStock = etatStock;
  // don't do anything if out of stock
  if (inStock) {
    const itemId = dataModel.itemId;
    
    const canvasInfo = canvasInfoList.find(info => info.itemId == itemId);
    if (typeof canvasInfo !== 'undefined') {
      canvasInfo.canvas = null;
      canvasInfoList = canvasInfoList.filter(info => info.itemId != itemId);
    }
    
    if(document.querySelector("#wrap-canvas-" + itemId) !== null){
      document.querySelector("#wrap-canvas-" + itemId).remove();
    }
    
    let visibleClass = "";
    if(dataModel.visible){
      document.querySelector("#js-no-configurator").classList.add("d-none");
      document.querySelector("#js-configurator").classList.remove("hide");
    } else {
      visibleClass = "hide";
    }
    const zoneCanvas =`
      <div id="wrap-canvas-${itemId}" class="wrap-canvas ${visibleClass}">
        <canvas id="canvas-${itemId}" class="canvas-item"></canvas>
      </div>`;
    
    document.querySelector("#canvas-wrap").insertAdjacentHTML("beforeend", zoneCanvas);
    const canvas = initCanvas(dataModel);
    
    drawCanvasElements({
      dataModel: dataModel,
      widgets: dataElements
    });
    
    handleCanvasDimension(canvas);
    
    checkConfiguratorValid();
    
    if (document.body.clientWidth < 767) {
      setTimeout(function() {
        //document.querySelector("#btn-personnalisation").click();
        handleCustomizationAction();
      }, 200);
    }
    
    document.querySelector('.logo-footer').addEventListener('click', (e) => {
      //document.querySelector('.bg-digital-version').addEventListener('click', (e) => {
      generatePrintableCanvas(itemId, "print");
    });
  }else{
    // console.log("out of stock");
  }
}

/*
 * this function is called in the case of simple configurators
 */
function updateModel(dataModel, dataElements) {
  // don't do anything if out of stock
  if (inStock) {
    if(dataModel.visible){
      document.querySelector("#js-no-configurator").classList.remove("d-none");
      document.querySelector("#js-configurator").classList.add("hide");
    }
    
    displayElements({
      dataModel: dataModel,
      widgets: dataElements
    });
    
    checkConfiguratorValid();
  }
}


if(document.querySelector('.btn-retour') !== null ){
  document.querySelector('.btn-retour').addEventListener('click', (e) => {
    //document.querySelector('.popup-conf-wrap').classList.remove('hide');
    
    let exitCustomization = true;
    if(document.querySelectorAll(".select-product-item").length >= 2){
      const activeProduct = document.querySelector('.select-product-item.active');
      if(activeProduct.previousElementSibling !== null){
        activeProduct.previousElementSibling.click();
        exitCustomization = false;
      }
    }
    
    if(exitCustomization){
      document.querySelector('.popup-conf-wrap').classList.remove('hide');
      document.querySelector('.btn-back-wrap').classList.add('d-none');
    }
  });
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    if(document.querySelector("#js-configurator") !== null){
      history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', function (event) {
        if(document.querySelector("#js-configurator").classList.contains("active")){
          document.querySelector('.btn-retour').dispatchEvent(new Event('click'));
          history.pushState(null, '', window.location.href);
        }else{
          history.back();
        }
      });
    }
  }
};

/*
 * Update datas of the canvas
 */
function updateCanvasDimensions(canvas, options) {
  let scale = 1;
  let width = options.width;
  let height = options.height;
  
  if (options.width >= options.height) {
    width = canvaSizeMax;
    height = width * options.height / options.width;
  } else {
    height = canvaSizeMax;
    width = height * options.width / options.height;
  }
  
  scale = parseFloat(width) / parseFloat(options.width);
  
  canvas.scale = scale;
  canvas.setWidth(width);
  canvas.setHeight(height);
  canvas.renderAll();
}

function handleDropDownGlobal() {
  document.addEventListener('click', (e) => {
    document.querySelectorAll(".ddt").forEach((item) => {
      item.classList.remove("open");
    });
    document.querySelectorAll(".wrap-line-space").forEach((wrap) => {
      wrap.classList.remove("wrap-open");
    });
  });
}

/*
 * Add events on custom dropdown
 */
function handleDropDown(canvas, widget) {
  widget.querySelectorAll(".dds").forEach((item) => {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      const target = item.closest(".dd").querySelector(".ddt");
      if (target.classList.contains("open")) {
        document.querySelectorAll(".ddt").forEach((ddt) => {
          ddt.classList.remove("open");
        });
        document.querySelectorAll(".wrap-line-space").forEach((wrap) => {
          wrap.classList.remove("wrap-open");
        });
      } else {
        document.querySelectorAll(".ddt").forEach((ddt) => {
          ddt.classList.remove("open");
        });
        document.querySelectorAll(".wrap-line-space").forEach((wrap) => {
          wrap.classList.remove("wrap-open");
        });
        target.classList.add("open");
        if(item.closest(".wrap-line-space") !== null){
          item.closest(".wrap-line-space").classList.add('wrap-open')
        }
      }
      
      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
        item.classList.remove("selected");
      });
      const widget = item.closest(".personnalisation-bit");
      widget.classList.add("selected");
      const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
      selectObject(element);
      canvas.renderAll();
    });
  });
  
  widget.querySelectorAll(".ddt").forEach((item) => {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  });
}

/*
 * Apply font to current element
 */
function setFont(element, font, widget = null, setToCenter = false) {
  const fontInfo = font.split("*");
  let fontFamily = fontInfo[0];
  let fontPath = fontInfo[1];
  if(!fontFamilies.includes(fontFamily)){
    fontFamilies.push(font.fontName);
    const fontFace = new FontFace(fontFamily, "url(/" + fontPath + ")");
    
    document.fonts.add(fontFace);
    fontFace.load().then(
      () => {
        //console.log("apply 1");
        console.log("fontFace", "url(/" + fontPath + ")");
        applyFont(element, fontFamily, widget, setToCenter);
      },
      error => {
        // console.log(error);
      }
    )
  } else {
    document.fonts.load("18px "+fontFamily).then(
      (fonts) => {
        //console.log("apply 2");
        console.log("fontFace", "url(/" + fontPath + ")");
        applyFont(element, fontFamily, widget, setToCenter);
      },
      error => {
        //console.log("fontFamily", fontFamily)
        // console.log(error);
      }
    );
  }
}

/*
 * Apply loaded font
 */
function applyFont(element, fontFamily, widget, setToCenter) {
  const canvas = element.canvas;
  const scale = canvas.scale;
  let printZone = null;
  canvas.getObjects().forEach((object) => {
    if(object.widgetType === "print_zone"){
      printZone = object;
    }
  });
  
  if(element.widgetType === "text"){
    const text = element.item(1);
    if (typeof text !== 'undefined') {
      element.set("fontFamily", fontFamily);
      text.set("fontFamily", fontFamily);
      text.dirty = true;
      element.dirty = true;
      canvas.renderAll();
    }
  } else {
    element.set("fontFamily", fontFamily);
    element.dirty = true;
  }
  canvas.renderAll();
  if(widget !== null){
    evaluateTextSizeWidget(widget, element);
  }
  if(element.widgetType === "text"){
    adjustTextElement(element);
  }
  
  element.setCoords();
  if(element.widgetType === "free_text" && setToCenter){
    const zoom = canvas.getZoom();
    const boundingRect = element.getBoundingRect();
    const left = printZone.left + printZone.width / 2 - ( boundingRect.width / 2 ) / zoom ;
    const top = printZone.top + printZone.height / 2 - ( boundingRect.height / 2 ) / zoom;
    
    // update element position
    element.left = left;
    element.top = top;
    element.setCoords();
    element.dirty = true;
    canvas.renderAll();
    
    // update widget datas
    widget.dataset.left = Math.round(left);
    widget.dataset.top = Math.round(top);
    widget.dataset.coordinatesX = Math.round(left / scale);
    widget.dataset.coordinatesY = Math.round(top / scale);
    
    widget.dataset.width = Math.round(element.width);
    widget.dataset.height = Math.round(element.height);
    widget.dataset.widthPx = Math.round(element.width / scale);
    widget.dataset.heightPx = Math.round(element.height / scale);
  }
}

/*
 * Naming fields according to widget index
 */
function fillNameWidgetText(widget, model, index){
  let prefixModelId = "";
  if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
    prefixModelId = "_" + model.itemId;
  }
  
  widget.querySelector(".input-text").setAttribute("name", "textContent" + prefixModelId + "_" + index);
  //fontFamily
  widget.querySelector(".input-font-family").setAttribute("name", "font_family" + prefixModelId + "_" + index );
  //fontColor
  widget.querySelector(".font-color .input-text-color").setAttribute("name", "color" + prefixModelId + "_" + index);
  //strokeColor
  widget.querySelector(".stroke-color .input-stroke-color").setAttribute("name", "strokecolor" + prefixModelId + "_" + index);
  //shadowColor
  widget.querySelector(".shadow-color .input-shadow-color").setAttribute("name", "shadowcolor" + prefixModelId + "_" + index);
  
  widget.querySelector(".input-size").setAttribute("id", "size" + prefixModelId + "_" + index);
  widget.querySelector(".input-size").setAttribute("name", "size" + prefixModelId + "_" + + index);
  
  //lineHeight
  widget.querySelector(".input-line-space").setAttribute("name", "line_space" + prefixModelId + "_" + index);
  
  widget.querySelector(".input-font-weight").setAttribute("id", "bold" + prefixModelId + "_" + index);
  widget.querySelector(".input-font-weight").setAttribute("name", "bold" + prefixModelId + "_" + index);
  widget.querySelector(".label-font-weight").setAttribute("for", "bold" + prefixModelId + "_" + index);
  
  widget.querySelector(".input-font-style").setAttribute("id", "italic" + prefixModelId + "_" + index);
  widget.querySelector(".input-font-style").setAttribute("name", "italic" + prefixModelId + "_" + index);
  widget.querySelector(".label-font-style").setAttribute("for", "italic" + prefixModelId + "_" + index);
  
  widget.querySelector(".input-flipped").setAttribute("id", "flipped" + prefixModelId + "_" + index);
  widget.querySelector(".input-flipped").setAttribute("name", "flipped" + prefixModelId + "_" + index);
  widget.querySelector(".label-flipped").setAttribute("for", "flipped" + prefixModelId + "_" + index);
  
  widget.querySelector(".input-diameter").setAttribute("id", "diameter" + prefixModelId + "_" + index);
  widget.querySelector(".input-diameter").setAttribute("name", "diameter" + prefixModelId + "_" + index);
  
  widget.querySelectorAll(".input-text-align").forEach((item) => {
    const value = item.getAttribute("value");
    item.setAttribute("name", "text_align" + prefixModelId + "_" + index);
    item.setAttribute("id", "text_align" + prefixModelId + "_" + value + "_" + index);
  });
  widget.querySelectorAll(".label-text-align").forEach((item) => {
    item.setAttribute("for", "text_align" + prefixModelId + "_" + index);
  });
}

/*
 * fill custom select font
 */
function fillCustomSelectFont(target, fonts) {
  target.querySelector(".ddt").innerHTML = "";
  let defaultFont = "";
  for (let i = 0; i < fonts.length; i++) {
    const font = fonts[i];
    
    if(!fontFamilies.includes(font.fontName)){
      const fontsCss ="<style>@font-face{font-family: '"+ font.fontName +"font'; src: url('/"+ font.filePath +"') format('truetype');}</style>";
      document.querySelector("#custom-styles").insertAdjacentHTML("beforeend", fontsCss);
      fontFamilies.push(font.fontName);
      const fontFace = new FontFace(font.fontName, "url(/" + font.filePath + ")");
      document.fonts.add(fontFace);
      fontFace.load().then(
        () => {/*console.log("loaded "+font.fontName);*/},
        error => {
          // console.log("font.fontName", font.fontName); console.log(error);
        }
      );
    }
    
    const fontItem = `<span class="dds-item font-item" data-fontname="${font.fontName}" data-value="${font.fontName}*${font.filePath}">
                <span class="label" style="font-family: ${font.fontName}font">${target.getAttribute("data-text-item")}</span>
               </span>`;
    
    if (i === 0) {
      defaultFont = font.fontName + "*" + font.filePath;
    }
    target.querySelector(".ddt").insertAdjacentHTML("beforeend", fontItem);
  }
  
  return defaultFont;
}

/*
 * fill custom select stroke
 */
function fillCustomSelectFontColor(target, fontColors) {
  target.querySelector(".ddt").innerHTML = "";
  let defaultFontColor = "#000000";
  for (let i = 0; i < fontColors.length; i++) {
    const fontColor = fontColors[i];
    const value = fontColor.hexadecimal;
    
    const colorItem = `<span class="dds-item text-color-item" data-value="${value}" data-color-name="${fontColor.colorName}">
                <span class="label">${fontColor.colorName}</span>
                <span class="color" style="background: ${value}"></span>
               </span>`;
    target.querySelector(".ddt").insertAdjacentHTML("beforeend", colorItem);
    if (i === 0) {
      defaultFontColor = value;
    }
  }
  
  return defaultFontColor;
}

/*
 * fill custom select stroke
 */
function fillCustomSelectStroke(target, strokeColors) {
  target.querySelector(".ddt").innerHTML = "";
  let defaultStrokeColor = "transparent";
  for (let i = 0; i < strokeColors.length; i++) {
    const strokeColor = strokeColors[i];
    const value = strokeColor.hexadecimal;
    let transparentClass = "";
    let styleBackground = "background: "+value;
    if(value === "transparent"){
      transparentClass = "transparent";
      styleBackground = "";
    }
    const colorItem = `<span class="dds-item stroke-color-item ${transparentClass}" data-value="${value}" data-color-name="${strokeColor.colorName}">
              <span class="label">${strokeColor.colorName}</span>
              <span class="color" style="${styleBackground}"></span>
             </span>`;
    target.querySelector(".ddt").insertAdjacentHTML("beforeend", colorItem);
    if (i === 0) {
      defaultStrokeColor = value;
    }
  }
  
  if(strokeColors.length <= 1){
    target.closest(".wrap-col").classList.add("d-none");
  }
  
  return defaultStrokeColor;
}

/*
 * fill custom select shadow
 */
function fillCustomSelectShadow(target, shadowColors) {
  target.querySelector(".ddt").innerHTML = "";
  let defaultShadowColor = "transparent";
  for (let i = 0; i < shadowColors.length; i++) {
    const shadowColor = shadowColors[i];
    const value = shadowColor.hexadecimal;
    let transparentClass = "";
    let styleBackground = "background: "+value;
    if(value === "transparent"){
      transparentClass = "transparent";
      styleBackground = "";
    }
    const colorItem = `<span class="dds-item shadow-color-item ${transparentClass}" data-value="${value}" data-color-name="${shadowColor.colorName}">
              <span class="label">${shadowColor.colorName}</span>
              <span class="color" style="${styleBackground}"></span>
             </span>`;
    target.querySelector(".ddt").insertAdjacentHTML("beforeend", colorItem);
    if (i === 0) {
      defaultShadowColor = value;
    }
  }
  
  if(shadowColors.length <= 1){
    target.closest(".wrap-col").classList.add("d-none");
  }
  
  return defaultShadowColor;
}

/*
 * set value of custom select font
 */
function setValueCustomSelectFont(target, value) {
  const option = target.querySelector('.font-item[data-value="'+ value +'"]');
  if(option != null){
    target.querySelectorAll(".font-item").forEach( optionTmp => {
      optionTmp.classList.remove("selected");
    })
    option.classList.add("selected");
    target.querySelector(".input-font-family").value = value;
    const words = value.split('*');
    const fontName = words[0];
    target.querySelector(".dds strong").innerHTML = `<span class="label" style="font-family: ${fontName}font">${target.getAttribute("data-text-item")}</span>`;
  }
}

/*
 * set value of custom select font color
 */
function setValueCustomSelectFontColor(target, value) {
  const option = target.querySelector('.text-color-item[data-value="'+ value +'"]');
  if(option != null){
    target.querySelectorAll(".text-color-item").forEach( optionTmp => {
      optionTmp.classList.remove("selected");
    })
    option.classList.add("selected");
    
    const value = option.getAttribute("data-value");
    const colorName = option.getAttribute("data-color-name");
    
    target.querySelector(".input-text-color").value = value;
    target.querySelector(".input-text-color").dataset.colorName = value;
    target.querySelector(".dds").dataset.hexadecimal = value;
    target.querySelector(".dds strong").style.background = value;
  }
}

/*
 * set value of custom select stroke
 */
function setValueCustomSelectStroke(target, value) {
  const option = target.querySelector('.stroke-color-item[data-value="'+ value +'"]');
  if(option != null){
    target.querySelectorAll(".stroke-color-item").forEach( optionTmp => {
      optionTmp.classList.remove("selected");
    })
    option.classList.add("selected");
    
    target.querySelector(".input-stroke-color").value = value;
    target.querySelector(".dds label").style.borderColor = value;
  }
}

/*
 * set value of custom select shadow
 */
function setValueCustomSelectShadow(target, value) {
  const option = target.querySelector('.shadow-color-item[data-value="'+ value +'"]');
  if(option != null){
    target.querySelectorAll(".shadow-color-item").forEach( optionTmp => {
      optionTmp.classList.remove("selected");
    })
    option.classList.add("selected");
    target.querySelector(".input-shadow-color").value = value;
    target.querySelector(".dds label").style.borderColor = value;
  }
}

/*
 * set value of custom select line space
 */
function setValueCustomSelectLineSpace(target, value) {
  const option = target.querySelector('.line-space-item[data-value="'+ value +'"]');
  if(option != null){
    target.querySelectorAll(".line-space-item").forEach( optionTmp => {
      optionTmp.classList.remove("selected");
    })
    option.classList.add("selected");
    target.querySelector(".input-line-space").value = value;
  }
}

/*
 * Apply color to current element
 */
function setFontColor(element, color) {
  const canvas = element.canvas;
  if(element.widgetType === "text"){
    const textElement = element.item(1);
    textElement.fill = color;
    textElement.dirty = true;
    element.dirty = true;
  } else {
    element.fill = color;
    element.dirty = true;
  }
  
  canvas.renderAll();
}

/*
 * Apply stroke color to current element
 */
function updateStrokeColor(element) {
  const canvas = element.canvas;
  if(element.widgetType === "text"){
    const textElement = element.item(1);
    textElement.stroke = element.strokeColor;
    textElement.dirty = true;
    element.dirty = true;
  } else {
    element.stroke = element.strokeColor;
    element.dirty = true;
  }
  
  canvas.renderAll();
}

/*
 * Apply shadow color to current element
 */
function updateShadowColor(element) {
  const canvas = element.canvas;
  /*
  const shadow = new fabric.Shadow({
    color: element.shadowColor,
    offsetX: 1,
    offsetY: 1,
    blur: 2
  });
  */
  const shadow = new fabric.Shadow({
    color: element.shadowColor,
    offsetX: element.fontSize / 40,
    offsetY: element.fontSize / 40,
    blur: element.fontSize / 20
  });
  if(element.widgetType === "text"){
    const textElement = element.item(1);
    textElement.set({shadow: shadow});
    textElement.dirty = true;
    element.dirty = true;
  } else {
    element.set({shadow: shadow});
    element.dirty = true;
  }
  
  canvas.renderAll();
}

/*
 * Redraw the textbox until it fits in the predefined area
 */
function evaluateTextSizeWidget(widget, element) {
  const canvas = element.canvas;
  const scale = canvas.scale;
  let textSize = parseInt(widget.querySelector(".input-size").value);
  if(element.widgetType === "text"){
    const widgetWidth = parseInt(widget.getAttribute("data-width"));
    const widgetHeight = parseInt(widget.getAttribute("data-height"));
    const linesMax = parseInt(widget.getAttribute("data-lines-max"));
    let textSizeMin = 2;
    if(widget.hasAttribute("data-text-size-min")){
      textSizeMin = parseInt(widget.getAttribute("data-text-size-min")) < 2 ? 2 : parseInt(widget.getAttribute("data-text-size-min"));
    }
    
    let evaluate = false;
    let textElement = element.item(1);
    
    if(widget.querySelector(".input-text").getAttribute("data-from-cart")){
      if(widget.querySelector(".input-text").getAttribute("data-from-cart") == 1){
        if (typeof widgetTexts[widget.getAttribute("data-element-id")] !== 'undefined') {
          const newText = widgetTexts[widget.getAttribute("data-element-id")];
          textElement.text = newText;
          textElement.dirty = true;
          element.dirty = true;
          canvas.renderAll();
          widget.querySelector(".input-text").setAttribute("data-from-cart", "0")
        }
      }
    }
    
    /*
    //evaluate = (textElement.width > widgetWidth || textElement.height > widgetHeight) && textSize >= textSizeMin;
    console.log("textElement.width", textElement.width)
    console.log("widgetWidth", widgetWidth)
    console.log("textElement.height", textElement.height)
    console.log("widgetHeight", widgetHeight)
    console.log("textSize", textSize)
    console.log("textSizeMin", textSizeMin)
    */

    /*** A revoir ***/
    element.fontSize = textSize * scale;
    textElement.fontSize = textSize * scale;
    textElement.strokeWidth = textSize * scale / 15;
    textElement.width = widgetWidth;
    updateShadowColor(element);
    textElement.dirty = true;
    element.dirty = true;
    canvas.renderAll();
    
    textElement.initDimensions();
    /*** A revoir fin ***/

    evaluate = (textElement.width > widgetWidth) && textSize >= textSizeMin;
    if (evaluate) {
      textSize = parseInt(textSize * widgetWidth / textElement.width);
      if(textSize < textSizeMin){
        textSize = textSizeMin;
      }
      element.fontSize = textSize * scale;
      textElement.fontSize = textSize * scale;
      textElement.strokeWidth = textSize * scale / 15;
      textElement.width = widgetWidth;
      textElement.dirty = true;
      element.dirty = true;
      canvas.renderAll();
      updateShadowColor(element);
    }
    
    evaluate = (textElement.width > widgetWidth || textElement.height > widgetHeight) && textSize >= textSizeMin;
    while (evaluate) {
      let decrement = 1;
      
      if(textSize >= 151 ){
        decrement = 15;
      } else if(textSize >= 81 ){
        decrement = 10;
      }else if(textSize >= 43 ){
        decrement = 7;
      }else if(textSize >= 21 ){
        decrement = 4;
      }else if(textSize >= 11 ){
        decrement = 2;
      }
      
      textSize = textSize - decrement;
      const textSizeTmp = textSize;
      if(textSize < textSizeMin){
        textSize = textSizeMin;
      }
      element.fontSize = textSize * scale;
      textElement.fontSize = textSize * scale;
      textElement.strokeWidth = textSize * scale / 15;
      //textElement.textBackgroundColor = "blue";
      textElement.width = widgetWidth;
      updateShadowColor(element);
      textElement.initDimensions();
      textElement.dirty = true;
      element.dirty = true;
      canvas.renderAll();
      textElement.initDimensions();
      textSize = textSizeTmp;
      evaluate = (textElement.width > widgetWidth || textElement.height > widgetHeight) && textSize >= textSizeMin;
    }
    
    if(textElement.diameter == 0){
      let evaluateLines = false;
      evaluateLines = textElement.textLines.length > linesMax;
      while (evaluateLines) {
        textSize--;
        element.fontSize = textSize * scale;
        textElement.fontSize = textSize * scale;
        textElement.strokeWidth = textSize * scale / 15;
        textElement.dirty = true;
        element.dirty = true;
        canvas.renderAll();
        updateShadowColor(element);
        
        evaluateLines = textElement.textLines.length > linesMax;
      }
    }
    
    if(textSize < textSizeMin){
      if(textSize < 2){
        textSize = parseInt(textElement.defaultTextSize / 4);
      }else{
        textSize = textSizeMin;
      }
      
      element.fontSize = textSize * scale;
      textElement.fontSize = textSize * scale;
      textElement.strokeWidth = textSize * scale / 15;
      textElement.dirty = true;
      element.dirty = true;
      canvas.renderAll();
      updateShadowColor(element);
      
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
    }
  
    if(textElement.diameter == 0){
      if(textElement.textLines.length > linesMax){
        let countLines = 0;
        let newText = "";
        textElement.textLines.forEach((textLine) => {
          countLines++;
          if(countLines <= linesMax ){
            newText += textLine;
            if(countLines < linesMax ){
              newText += "\n";
            }
          }
        });
        
        textElement.text = newText;
        textElement.dirty = true;
        element.dirty = true;
        canvas.renderAll();
        widget.querySelector(".input-text").blur();
        alertTextModified(widget.querySelector(".input-text"), newText);
      }
      
      let newText = "";
      let countLines = 0;
      textElement.textLines.forEach((textLine) => {
        countLines++;
        newText += textLine;
        if(countLines < textElement.textLines.length){
          newText += "\n";
        }
      });
      
      if(newText != widget.querySelector(".input-text").value){
        widget.querySelector(".input-text").value = newText;
        textElement.text = newText;
      }
    }
  
    widget.querySelector(".input-size").value = textSize;
  }
  
  return textSize;
}

/*
 * textarea linesMax
 */
function limitLinesMax(text, linesMax) {
  const lines = text.split("\n");
  let newText = text;
  if(lines.length > linesMax) {
    newText = lines.slice(0, linesMax).join("\n");
  }
  return newText;
}

/*
 * Replace element in text Group
 */
function adjustTextElement(element) {
  const canvas = element.canvas;
  const text = element.item(1);
  let top = - text.height / 2;
  if(text.type === "text-curved"){
    if(text.flipped){
      top = (element.height / 2) - text.height;
    }else{
      top = - element.height / 2;
    }
  }
  
  let left = - text.width / 2;
  if(element.textAlign === "left"){
    left = - element.width / 2
  }
  if(element.textAlign === "right"){
    left = - text.width / 2 + element.width / 2 - text.width / 2;
  }
  text.set({
    top: top,
    left: left,
    textAlign: element.textAlign
  });
  
  element.clipPath = new fabric.Rect({
    width: 1,
    height: 1,
    top: - element.height / 2,
    left: - element.width / 2
  });
  element.dirty = true;
  canvas.renderAll();
  
  if(element.linkedToPrintZone){
    element.clipPath = element.originalClipPath;
  } else {
    element.clipPath = null;
  }
  text.dirty = true;
  element.dirty = true;
  canvas.renderAll();
}

function init_slide_library(){
  let sliders_list = [];
  document.querySelectorAll(".swiper-library").forEach((item, index) => {
    let options = {
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.library-next'+index,
        prevEl: '.library-prev'+index,
      },
      pagination: {
        el: '.swiper-pagination-library'+index,
        clickable : true
      },
      slidesPerView: 3,
      grid: {
        rows: 2
      },
      spaceBetween: 5,
      loop: false,
      speed: 900,
      breakpoints: {
        575: {
          slidesPerView: 4,
          spaceBetween: 5,
        }
      }
    }
    if(item.querySelectorAll('.library-image-item').length <= 5){
      options.grid = {
        rows: 1
      }
    }
    
    item.closest('.library-item').querySelector('.library-next').classList.add('library-next'+index);
    item.closest('.library-item').querySelector('.library-prev').classList.add('library-prev'+index);
    item.classList.add('swiper-librarycol-'+index);
    item.querySelector('.swiper-pagination').classList.add('swiper-pagination-library'+index);
    
    let slider_list = new Swiper('.swiper-librarycol-'+index, options);
    sliders_list.push(slider_list);
    let nbre_pags= item.querySelectorAll('.swiper-pagination .swiper-pagination-bullet').length;
    if(nbre_pags <= 1){
      item.closest('.library-item').classList.add('just-one-item');
    } else {
      item.closest('.library-item').classList.remove('just-one-item');
    }
  });
}

/*
 * This function allows to occupy the area for the canvas in width or height
 */
function handleCanvasDimension(canvas) {
  const itemId = canvas.itemId;
  
  //the object which represents the outline of the canvas
  let object = canvas.getObjects().find(obj => obj.widgetType === "limit_zone");
  if (document.body.clientWidth < 767) {
    object = canvas.getObjects().find(obj => obj.widgetType === "print_zone");
  }
  const wrapWidth = document.querySelector("#wrap-canvas-" + itemId).clientWidth;
  const zoom = canvas.getZoom();
  const limitZoneWidth = object.width * zoom;
  let newZoom = zoom * wrapWidth / limitZoneWidth;
  
  if(object.widgetType == "limit_zone" && object.width < object.height){
    const limitZoneHeight = object.height * zoom;
    newZoom = zoom * wrapWidth / limitZoneHeight;
  }
  
  if (document.body.clientWidth >= 767) {
    if(object.width >= object.height){
      const ratioCanvas = object.height / object.width;
      canvas.setWidth(document.querySelector("#wrap-canvas-" + itemId).clientWidth);
      canvas.setHeight(document.querySelector("#wrap-canvas-" + itemId).clientWidth * ratioCanvas);
    }else{
      const ratioCanvas = object.width / object.height;
      canvas.setHeight(document.querySelector("#wrap-canvas-" + itemId).clientWidth);
      canvas.setWidth(document.querySelector("#wrap-canvas-" + itemId).clientWidth * ratioCanvas);
    }
  }else{
    canvas.setHeight(object.height * newZoom);
    canvas.setWidth(object.width * newZoom);
  }
  
  canvas.setZoom(newZoom);
}

/*
 * This function adds event to the different fields and buttons of the widget
 */
function handleEvent(canvas, model, widget) {
  const scale = canvas.scale;
  const widgetIndex = parseInt(widget.getAttribute("data-index"));
  const widgetWidth = parseInt(widget.getAttribute("data-width"));
  const widgetHeight = parseInt(widget.getAttribute("data-height"));
  const widgetWidthPx = parseInt(widget.getAttribute("data-width-px"));
  const widgetHeightPx = parseInt(widget.getAttribute("data-height-px"));
  
  widget.addEventListener('click', (e) => {
    const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
    e.stopPropagation();
    
    document.querySelectorAll(".ddt").forEach((ddt) => {
      ddt.classList.remove("open");
    });
    document.querySelectorAll(".wrap-line-space").forEach((wrap) => {
      wrap.classList.remove("wrap-open");
    });
    document.querySelectorAll(".personnalisation-bit").forEach((item) => {
      item.classList.remove("selected");
    });
    widget.classList.add("selected");
    selectObject(element);
    canvas.renderAll();
  });
  
  if (widget.getAttribute("data-type") === "text" || widget.getAttribute("data-type") === "free_text") {
    widget.querySelector(".btn-plus-options").addEventListener('click', (e) => {
      widget.querySelector(".box-plus-options").classList.remove('d-none')
    });
    
    widget.querySelector(".btn-close-plus-options").addEventListener('click', (e) => {
      widget.querySelector(".box-plus-options").classList.add('d-none')
    });
    
    
    /*
    widget.querySelector('.input-text').addEventListener('blur', function (e) {
      document.querySelector("body").classList.remove("focus");
    });

    widget.querySelector('.input-text').addEventListener('focus', function (e) {
      document.querySelector("body").classList.add("focus");
    });
    */
    
    widget.querySelector('.input-text').addEventListener('keydown', function (e) {
      const target = e.target;
      if(!characters.includes(e.key) && !keys.includes(e.key)){
        e.preventDefault();
      }else{
        cursorPosition = target.selectionStart;
        if(e.keyCode == 13){
          if(widget.hasAttribute("data-lines-max")){
            const lines = target.value.split("\n")
            const lastLineIndex = lines.length - 1;
            if(lines[lastLineIndex].trim() != "" && cursorPosition < target.value.length && parseInt(widget.getAttribute("data-lines-max")) > 1){
              const maxlines = parseInt(widget.getAttribute("data-lines-max"));
              const linesLength = lines.length;
              if(linesLength >= maxlines){
                e.preventDefault();
                alertTextEnter(target, maxlines);
                /*
                if (!window.confirm("Vous avez droit à "+maxlines+" lignes au maximum.\nVoulez vous prendre en compte ce passage à la ligne?\nCela supprimera votre dernière ligne.")) {
                  e.preventDefault();
                }
                */
              }
            }
          }
        }
      }
    });
    
    widget.querySelector('.input-text').addEventListener('paste', function (e) {
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      setTimeout(function(){
        let finalValue = "";
        const initialValue = e.target.value;
        for (let i = 0; i < initialValue.length; i++) {
          const character = initialValue.charAt(i);
          if(characters.includes(character)){
            finalValue += character;
          }
        }
        e.target.value = finalValue;
        checkTextElementValid(widget);
        
        displayInfoMaxCharacters(widget);
        displayInfoMaxLines(widget);
        if(element.widgetType === "free_text"){
          handleModifyObject(element);
        }
      }, 0);
    });
    
    widget.querySelector(".input-text").addEventListener('input', (e) => {
      let finalValue = "";
      const initialValue = e.target.value;
      for (let i = 0; i < initialValue.length; i++) {
        const character = initialValue.charAt(i);
        if(characters.includes(character)){
          finalValue += character;
        }
      }
      e.target.value = finalValue;
      
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      clearTimeout(timeOutClear[widgetIndex]);
      timeOutClear[widgetIndex] = setTimeout(function () {
        let text = e.target.value;
        const newText = limitLinesMax(text, element.linesMax);
        
        if(text !== newText){
          e.target.value = newText;
          text = newText;
        }
        
        if(element.widgetType === "text"){
          const textElement = element.item(1);
          textElement.text = text;
          textElement.dirty = true;
          element.dirty = true;
        } else {
          element.text = text;
          element.width = parseInt(widget.getAttribute("data-width"));
          element.height = parseInt(widget.getAttribute("data-height"));
          element.dirty = true;
          canvas.renderAll();
        }
        selectObject(element);
        canvas.renderAll();
        
        evaluateTextSizeWidget(widget, element);
        if(element.widgetType === "text"){
          adjustTextElement(element);
        }
        checkTextElementValid(widget);
        checkConfiguratorValid();
        
        widget.dataset.heightPx = element.height / scale;
        
        displayInfoMaxCharacters(widget);
        displayInfoMaxLines(widget);
        if(element.widgetType === "free_text"){
          handleModifyObject(element);
        }
      }, 150);
    });
    
    widget.querySelectorAll(".font-color .dds-item").forEach((item) => {
      item.addEventListener('click', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        e.stopPropagation();
        const value = item.getAttribute("data-value");
        const colorName = item.getAttribute("data-color-name");
        const fontColorTarget = item.closest(".font-color");
        setValueCustomSelectFontColor(fontColorTarget, value);
        
        fontColorTarget.querySelector(".ddt").classList.remove("open");
        selectObject(element);
        setFontColor(element, value);
      });
    });
    
    widget.querySelectorAll(".stroke-color .dds-item").forEach((item) => {
      item.addEventListener('click', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        e.stopPropagation();
        const value = item.getAttribute("data-value");
        element.strokeColor = value;
        const strokeTarget = item.closest(".stroke-color");
        
        setValueCustomSelectStroke(strokeTarget, value);
        strokeTarget.querySelector(".ddt").classList.remove("open");
        selectObject(element);
        updateStrokeColor(element);
      });
    });
    
    widget.querySelectorAll(".shadow-color .dds-item").forEach((item) => {
      item.addEventListener('click', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        e.stopPropagation();
        const value = item.getAttribute("data-value");
        const shadowTarget = item.closest(".shadow-color");
        
        setValueCustomSelectShadow(shadowTarget, value);
        shadowTarget.querySelector(".ddt").classList.remove("open");
        
        element.shadowColor = value;
        updateShadowColor(element);
        
        selectObject(element);
        evaluateTextSizeWidget(widget, element);
        if(element.widgetType === "text"){
          adjustTextElement(element);
        }
        widget.dataset.heightPx = element.height / scale;
      });
    });
    
    widget.querySelectorAll(".line-space-item").forEach((item) => {
      item.addEventListener('click', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        e.stopPropagation();
        const value = parseFloat(item.getAttribute("data-value"));
        const boxTarget = item.closest(".line-space");
        
        setValueCustomSelectLineSpace(boxTarget, value);
        boxTarget.querySelector(".ddt").classList.remove("open");
        document.querySelectorAll(".wrap-line-space").forEach((wrap) => {
          wrap.classList.remove("wrap-open");
        });
        
        element.lineHeight = value;
        if(element.widgetType === "text"){
          const textElement = element.item(1);
          textElement.lineHeight = value;
          
          textElement.dirty = true;
          element.dirty = true;
        } else {
          //element.width = widgetWidthTmp;
          //element.height = widgetHeightTmp;
          element.dirty = true;
          canvas.renderAll();
        }
        selectObject(element);
        canvas.renderAll();
        evaluateTextSizeWidget(widget, element);
        if(element.widgetType === "text"){
          adjustTextElement(element);
        }
        widget.dataset.heightPx = element.height / scale;
      });
    });
    
    widget.querySelectorAll(".fonts .font-item").forEach((item) => {
      item.addEventListener('click', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        e.stopPropagation();
        const target = item.closest(".fonts");
        
        const value = item.getAttribute("data-value");
        setValueCustomSelectFont(target, value);
        
        target.querySelector(".ddt").classList.remove("open");
        selectObject(element);
        setFont(element, value, widget, false);
        widget.dataset.heightPx = element.height / scale;
        
        evaluateTextSizeWidget(widget, element);
      });
    });
    
    widget.querySelector(".input-font-weight").addEventListener('change', (e) => {
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      let fontWeight = "normal";;
      if (e.target.checked) {
        fontWeight = "bold";
      }
      
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.fontWeight = fontWeight;
        textElement.dirty = true;
        element.dirty = true;
      } else {
        element.fontWeight = fontWeight;
        element.dirty = true;
        canvas.renderAll();
      }
      
      selectObject(element);
      canvas.renderAll();
      evaluateTextSizeWidget(widget, element);
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
      widget.dataset.heightPx = element.height / scale;
    });
    
    if(widget.querySelector(".input-diameter-reverse") != null) {
      widget.querySelector(".input-diameter-reverse").addEventListener('change', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        let optionTmp = getTextOption(element);
        
        if(optionTmp.previousType === "text" && parseInt(e.target.value) === 0) {
          //do nothing
        }else if(optionTmp.previousType === "text-curved" && parseInt(e.target.value) > 0){
          const diameterPx = 10000 / parseInt(e.target.value);
          const defaultDatas = {
            diameterPx: diameterPx,
          };
          widget.querySelector(".input-diameter").value = parseInt(diameterPx);
          
          if(element.widgetType === "text"){
            const textElement = element.item(1);
            textElement.diameter = diameterPx * scale;
            textElement.dirty = true;
            element.dirty = true;
          } else {
            element.diameter = diameterPx * scale;
            element.dirty = true;
          }
          canvas.renderAll();
          
          evaluateTextSizeWidget(widget, element);
          if(element.widgetType === "text"){
            adjustTextElement(element);
          }
          selectObject(element);
        } else {
          const defaultText = widget.querySelector(".input-text").value;
          let diameterPx = 0;
          if(optionTmp.widgetType === "text") {
            const textElement = element.item(1);
            if(textElement !== null){
              element.remove(textElement);
            }
            
            let text;
            if (e.target.value > 0) {
              diameterPx = 10000 / e.target.value;
              optionTmp.diameter = diameterPx * scale;
              text = new fabric.TextCurved(defaultText, {
                ...optionTmp,
                angle: 0
              });
            } else {
              const linesMax = widget.getAttribute("data-lines-max");
              optionTmp.diameter = 0;
              if(linesMax > 1){
                text = new fabric.Textbox(defaultText, {
                  ...optionTmp,
                  //backgroundColor: "#e3dac9",
                  lockScalingX: true,
                  angle: 0
                });
              }else{
                text = new fabric.Text(defaultText, {
                  ...optionTmp,
                  angle: 0
                });
              }
              /*
              text = new fabric.Text(defaultText, {
                ...optionTmp,
                angle: 0
              });
              */
            }
            element.add(text);
            
            evaluateTextSizeWidget(widget, element);
            adjustTextElement(element);
            selectObject(element);
          } else {
            canvas.remove(element);
            
            let newElement;
            if (parseInt(e.target.value) > 0) {
              diameterPx = 10000 / parseInt(e.target.value);
              optionTmp.diameter = diameterPx * scale;
              newElement = new fabric.TextCurved(defaultText, {
                ...optionTmp
              });
            } else {
              optionTmp.diameter = 0;
              newElement = new fabric.Text(defaultText, {
                ...optionTmp
              });
            }
            
            newElement.setControlsVisibility(newElement._controlsVisibility);
            newElement.dirty = true;
            canvas.add(newElement);
            evaluateTextSizeWidget(widget, newElement);
            
            //Reput elements according to their levels
            ReorderObjects(canvas);
            if(document.querySelector("#box-validation") != null){
              if(!document.querySelector("#box-validation").classList.contains('hide')){
                handleEditObject(newElement, false);
              }
            }
            
            widget.dataset.widthPx = newElement.width / scale;
            widget.dataset.heightPx = newElement.height / scale;
          }
          
          widget.querySelector(".input-diameter").value = parseInt(diameterPx);
        }
      });
    }
    
    if(widget.querySelector(".input-flipped") != null) {
      widget.querySelector(".input-flipped").addEventListener('change', (e) => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        let flipped = false;
        if (e.target.checked) {
          flipped = true;
        }
        if(element.widgetType === "text"){
          const textElement = element.item(1);
          textElement.flipped = flipped;
          textElement.dirty = true;
          element.dirty = true;
        } else {
          element.flipped = flipped;
          element.dirty = true;
          canvas.renderAll();
        }
        selectObject(element);
        canvas.renderAll();
        if(element.widgetType === "text"){
          adjustTextElement(element);
        }
      });
    }
    
    widget.querySelector(".input-font-style").addEventListener('change', (e) => {
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      let fontStyle = "normal";
      if (e.target.checked) {
        fontStyle = "italic";
      }
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.fontStyle = fontStyle;
        textElement.dirty = true;
        element.dirty = true;
      } else {
        element.fontStyle = fontStyle;
        element.dirty = true;
        canvas.renderAll();
      }
      selectObject(element);
      canvas.renderAll();
      evaluateTextSizeWidget(widget, element);
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
      widget.dataset.heightPx = element.height / scale;
    });
    
    
    let prefixModelId = "";
    if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
      prefixModelId = "_" + model.itemId;
    }
    
    widget.querySelectorAll(".input-text-align").forEach(radio => {
      radio.addEventListener('change', () => {
        const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
        const value = widget.querySelector('input[name="text_align' + prefixModelId + "_" + widgetIndex + '"]:checked').value;
        element.textAlign = value;
        element.dirty = true;
        selectObject(element);
        if(element.widgetType === "text"){
          adjustTextElement(element);
        } else {
          canvas.renderAll();
        }
        widget.dataset.heightPx = element.height / scale;
      })
    });
    
    widget.querySelector(".input-size").addEventListener('blur', (e) => {
      e.preventDefault();
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      let currentValue = parseInt(widget.querySelector(".input-size").value);
      const widgetWidthTmp = parseInt(widget.getAttribute("data-width"));
      const widgetHeightTmp = parseInt(widget.getAttribute("data-height"));
      if (isNaN(currentValue)) {
        currentValue = 1;
      }
      if (currentValue < 1) {
        currentValue = 1;
      }
      
      widget.querySelector(".input-size").value = currentValue;
      element.fontSize = currentValue * scale;
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.fontSize = currentValue * scale;
        textElement.strokeWidth = currentValue * scale / 15;
        textElement.dirty = true;
        element.dirty = true;
      } else {
        element.width = widgetWidthTmp;
        element.height = widgetHeightTmp;
        element.dirty = true;
        canvas.renderAll();
      }
      selectObject(element);
      canvas.renderAll();
      evaluateTextSizeWidget(widget, element);
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
      widget.dataset.heightPx = element.height / scale;
    });
    
    widget.querySelector(".size-more").addEventListener('click', (e) => {
      e.preventDefault();
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      let currentValue = parseInt(widget.querySelector(".input-size").value);
      const widgetWidthTmp = parseInt(widget.getAttribute("data-width"));
      const widgetHeightTmp = parseInt(widget.getAttribute("data-height"));
      currentValue++;
      widget.querySelector(".input-size").value = currentValue;
      element.fontSize = currentValue * scale;
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.fontSize = currentValue * scale;
        textElement.strokeWidth = currentValue * scale / 15;
        textElement.dirty = true;
        element.dirty = true;
      } else {
        element.width = widgetWidthTmp;
        element.height = widgetHeightTmp;
        element.dirty = true;
        canvas.renderAll();
      }
      
      selectObject(element);
      canvas.renderAll();
      evaluateTextSizeWidget(widget, element);
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
      widget.dataset.heightPx = parseInt(element.height / scale);
      widget.dataset.widthPx = parseInt(element.width / scale);
    });
    
    widget.querySelector(".size-less").addEventListener('click', (e) => {
      e.preventDefault();
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      let currentValue = parseInt(widget.querySelector(".input-size").value);
      const widgetWidthTmp = parseInt(widget.getAttribute("data-width"));
      const widgetHeightTmp = parseInt(widget.getAttribute("data-height"));
      if (currentValue > 1) {
        currentValue--;
      } else {
        currentValue = 1;
      }
      
      widget.querySelector(".input-size").value = currentValue;
      element.fontSize = currentValue * scale;
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.fontSize = currentValue * scale;
        textElement.strokeWidth = currentValue * scale / 15;
        textElement.dirty = true;
        element.dirty = true;
      } else {
        element.width = widgetWidthTmp;
        element.height = widgetHeightTmp;
        element.dirty = true;
        canvas.renderAll();
      }
      selectObject(element);
      canvas.renderAll();
      evaluateTextSizeWidget(widget, element);
      if(element.widgetType === "text"){
        adjustTextElement(element);
      }
      widget.dataset.heightPx = element.height / scale;
    });
    
    widget.querySelector(".btn-reset-text").addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const element = canvas.getObjects().find(obj => obj.index == widgetIndex);
      canvas.discardActiveObject();
      
      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
        item.classList.remove('focus-mobile');
      });
      document.querySelector("body").classList.remove('body-index-change');
      
      resetTextWidget(widget, resetData);
      
      element.text = resetData.text;
      if(element.widgetType === "text"){
        const textElement = element.item(1);
        textElement.text = resetData.text;
        textElement.dirty = true;
        canvas.renderAll();
        adjustTextElement(element);
      }
      /*
      element.fill = resetData.fill;
      element.fontFamily = resetData.fontFamily;
      element.fontSize = resetData.fontSize * scale;
      element.fontStyle = resetData.fontStyle;
      element.fontWeight = resetData.fontWeight;
      element.textAlign = resetData.textAlign;
      element.shadow = resetData.shadow;
      element.strokeWidth = resetData.strokeWidth;
      element.stroke = resetData.stroke;
      element.strokeColor = resetData.strokeColor;
      */
      element.dirty = true;
      canvas.renderAll();
    });
    
    widget.querySelector(".btn-valid-text").addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      canvas.discardActiveObject();
      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
        item.classList.remove('focus-mobile');
      });
      document.querySelector("body").classList.remove('body-index-change');
      canvas.renderAll();
    });
  }
  
  if (widget.getAttribute("data-type") === "image_upload" || widget.getAttribute("data-type") === "image_upload_or_library") {
    const inputFile = widget.querySelector(".input-file");
    inputFile.addEventListener('change', (e) => {
      const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
      if (element) {
        const file = e.target.files[0];
  
        console.log("file.size2", file.size);
        console.log("model.printFormat", model.printFormat);
        
        if(model.printFormat !== "pdf" || file.size <= 3145728){
          const reader = new FileReader();
          reader.onload = function (file) {
            const imgLink = file.target.result;
            
            const validImage = imgLink.startsWith("data:image/png") || imgLink.startsWith("data:image/jpeg") || imgLink.startsWith("data:image/jpg") || imgLink.startsWith("data:image/webp");
            if (validImage) {
              const imageResize = document.createElement("img");
              imageResize.setAttribute('id', 'img-resize');
              imageResize.src = imgLink;
              Jcrop.load(imageResize).then(img => {
                if (img.naturalWidth > 0 && img.naturalHeight > 0) {
                  const jcropOptions = {
                    widget: null,
                    element: element,
                    inputFile: inputFile,
                    widgetValid: widget
                  }
                  const instance = createJcropPopup(imageResize, img, widgetWidth, widgetHeight, jcropOptions);
                  
                  document.querySelector("#valid-crop").addEventListener('click', (e) => {
                    widget.dataset.type = "image_upload";
                    const x = document.querySelector("#jcrop-x").value;
                    const y = document.querySelector("#jcrop-y").value;
                    const w = document.querySelector("#jcrop-w").value;
                    const h = document.querySelector("#jcrop-h").value;
                    
                    const coeff = img.naturalWidth / img.width;
                    let pos = {x: x, y: y, w: w, h: h};
                    
                    checkImageQuality(w * coeff, widgetWidthPx, widget);
                    fabric.Image.fromURL(imgLink, function (oImg) {
                      const imageElement = element.item(1);
                      if(imageElement !== null){
                        element.remove(imageElement);
                      }
                      
                      const scaleX = widgetWidth / (w * coeff);
                      const scaleY = widgetHeight / (h * coeff);
                      const cropX = x * coeff;
                      const cropY = y * coeff;
                      const cropWidth = w * coeff;
                      const cropHeight = h * coeff;
                      oImg.set({
                          top: - element.height / 2,
                          left: - element.width / 2,
                          cropX: cropX,
                          cropY: cropY,
                          width: cropWidth,
                          height: cropHeight,
                          scaleX: scaleX,
                          scaleY: scaleY
                        }
                      )
                      element.add(oImg);
                      
                      widget.dataset.cropX = cropX;
                      widget.dataset.cropY = cropY;
                      widget.dataset.cropWidth = cropWidth;
                      widget.dataset.cropHeight = cropHeight;
                      canvas.renderAll();
                      
                    });
                    widget.classList.add('ok-valid');
                    if (widget.dataset.mandatory === "false") {
                      widget.classList.add('optionnal');
                    }
                    checkConfiguratorValid();
                    
                    instance.popup.remove();
                    instance.jcrop.destroy();
                    
                    if(document.querySelector('#free-box-' + model.itemId + ' .hide-content-image') !== null){
                      document.querySelector('#free-box-' + model.itemId + ' .hide-content-image').click();
                    }
                    
                    if (document.body.clientWidth < 767) {
                      canvas.discardActiveObject();
                      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
                        item.classList.remove('focus-mobile');
                      });
                      document.querySelector("body").classList.remove('body-index-change');
                      canvas.renderAll();
                    }
                  });
                } else {
                  inputFile.value = "";
                  widget.classList.remove('ok-valid');
                  checkConfiguratorValid();
                  if(element.item(1)){
                    element.remove(element.item(1));
                    canvas.renderAll();
                  }
                  alert("Veuillez télécharger une image valide.");
                }
              });
            } else {
              inputFile.value = "";
              widget.classList.remove('ok-valid');
              checkConfiguratorValid();
              if(element.item(1)){
                element.remove(element.item(1));
                canvas.renderAll();
              }
              alert("Veuillez télécharger un fichier image (jpg ou png).")
            }
          }
          reader.readAsDataURL(file);
        }else{
          inputFile.value = "";
          widget.classList.remove('ok-valid');
          checkConfiguratorValid();
          if(element.item(1)){
            element.remove(element.item(1));
            canvas.renderAll();
          }
          alert("Veuillez télécharger une image de taille inférieure à 3Mo");
        }
      }
    });
  }
  
  if (widget.getAttribute("data-type") === "image_upload_or_library" || widget.getAttribute("data-type") === "image_library") {
    const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
    if (element) {
      const btnLibraries = widget.querySelector(".btn-libraries");
      btnLibraries.addEventListener('click', (e) => {
        //const object = model.objects.find(element => element.index == widget.getAttribute("data-index"));
        let libraryFileId = 0;
        if(widget.hasAttribute('data-library-file-id')){
          libraryFileId = widget.getAttribute('data-library-file-id');
        }
        const popup = createLibrariesPopup(element.libraries, libraryFileId);
        
        document.querySelector("#valid-image").addEventListener('click', (e) => {
          const imgLink = document.querySelector(".library-image-item.selected img").getAttribute("data-src");
          let id = document.querySelector(".library-image-item.selected img").dataset.id;
          widget.dataset.libraryFileId = id;
          widget.dataset.type = "image_library";
          widget.classList.add('ok-valid');
          if (widget.dataset.mandatory === "false") {
            widget.classList.add('optionnal');
          }
          checkConfiguratorValid();
          
          setImgFabric(element, id, imgLink);
          
          popup.remove();
          
          if(document.querySelector('#free-box-' + model.itemId + ' .hide-content-image') !== null){
            document.querySelector('#free-box-' + model.itemId + ' .hide-content-image').click();
          }
          
          if (document.body.clientWidth < 767) {
            canvas.discardActiveObject();
            document.querySelectorAll(".personnalisation-bit").forEach((item) => {
              item.classList.remove('focus-mobile');
            });
            document.querySelector("body").classList.remove('body-index-change');
            canvas.renderAll();
          }
        });
      });
    }
  }
  
  if (widget.getAttribute("data-type") === "image_library") {
    const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
    if (element) {
      widget.querySelector(".btn-reset-libraries").addEventListener('click', (e) => {
        const imageElement = element.item(1);
        if(imageElement !== null){
          element.remove(imageElement);
        }
        
        //element.set({libraryFileId: id});
        widget.removeAttribute("data-library-file-id");
        canvas.renderAll();
      });
    }
  }
  
  if (widget.getAttribute("data-type") === "free_image_upload" || widget.getAttribute("data-type") === "free_image_library" || widget.getAttribute("data-type") === "free_text") {
    widget.querySelector(".btn_to_front").addEventListener('click', (e) => {
      const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
      globalIndex++;
      element.zIndex = globalIndex;
      setDefaultData(widget, {level: element.zIndex});
      
      ReorderObjects(canvas);
    });
    widget.querySelector(".btn_to_back").addEventListener('click', (e) => {
      const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
      let minZIndex = 100;
      let objectTmps = [...canvas.getObjects()];
      objectTmps.map(function (o) {
        if(o.free){
          if(minZIndex >= parseInt(o.zIndex)){
            minZIndex = parseInt(o.zIndex);
          }
          if(globalIndex < parseInt(o.zIndex) + 1){
            globalIndex = parseInt(o.zIndex) + 1;
          }
          o.zIndex = parseInt(o.zIndex) + 1;
          const widgetTmp = document.querySelector('.personnalisation-bit[data-index="' + o.index + '"]');
          setDefaultData(widgetTmp, {level: o.zIndex});
        }
      });
      element.zIndex = minZIndex;
      setDefaultData(widget, {level: element.zIndex});
      
      ReorderObjects(canvas);
    });
    widget.querySelector(".btn_remove").addEventListener('click', (e) => {
      e.stopPropagation();
      
      const element = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
      const configPerso = document.querySelector('.personnalisation-bit[data-index="' + element.index + '"]').closest('.config-perso');
      document.querySelector('.personnalisation-bit[data-index="' + element.index + '"]').remove();
      canvas.remove(element);
      handleDeselectAllObjects(canvas);
  
      const count = configPerso.querySelectorAll(".personnalisation-bit:not(.d-none)").length;
      const overflow = count > 1 ? "auto" : "inherit";
      configPerso.style.overflow = overflow;
      
      canvas.renderAll();
    });
  }
  
  if(widget.querySelector(".close-widget") != null) {
    widget.querySelector(".close-widget").addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      document.querySelectorAll(".popup").forEach((popup) => {
        popup.remove();
      });
      
      //enableScroll();
      document.querySelectorAll(".personnalisation-bit").forEach((item) => {
        item.classList.remove('focus-mobile');
        item.classList.remove("selected");
      });
      document.querySelector("body").classList.remove('body-index-change');
    });
  }
}

function getTextOption(object) {
  let option;
  if(object.widgetType === "text"){
    const textElement = object.item(1);
    option = {
      transparentCorners: textElement.transparentCorners,
      cornerColor: textElement.cornerColor,
      cornerSize: textElement.cornerSize,
      cornerStyle: textElement.cornerStyle,
      strokeUniform: textElement.strokeUniform,
      lockScalingX: textElement.lockScalingX,
      lockScalingY: textElement.lockScalingY,
      centeredRotation: textElement.centeredRotation,
      hasControls: textElement.hasControls,
      stroke: textElement.stroke,
      strokeColor: textElement.strokeColor,
      strokeWidth: textElement.strokeWidth,
      kerning: textElement.kerning,
      flipped: textElement.flipped,
      paintFirst: textElement.paintFirst,
      shadow: textElement.shadow,
      shadowColor: object.shadowColor,
      affectStroke: textElement.affectStroke,
      fontFamily: textElement.fontFamily,
      fontSize: textElement.fontSize,
      fontWeight: textElement.fontWeight,
      fontStyle: textElement.fontStyle,
      editable: textElement.editable,
      lockScalingFlip: textElement.lockScalingFlip,
      textAlign: textElement.textAlign,
      id: textElement.id,
      widgetType: textElement.widgetType,
      index: textElement.index,
      zIndex: textElement.zIndex,
      fill: textElement.fill,
      top: object.top,
      left: object.left,
      width: object.width,
      height: object.height,
      originalWidth: object.originalWidth,
      originalHeight: object.originalHeight,
      angle: object.angle,
      lockMovementX: object.lockMovementX,
      lockMovementY: object.lockMovementY,
      _controlsVisibility: object._controlsVisibility,
      visibleOnConfigurator: object.visibleOnConfigurator,
      visibleOnPrint: object.visibleOnPrint,
      linkedToPrintZone: object.linkedToPrintZone,
      clipPath: object.clipPath,
      free: object.free,
      previousType: textElement.type,
    };
  } else {
    option = {
      transparentCorners: object.transparentCorners,
      cornerColor: object.cornerColor,
      cornerSize: object.cornerSize,
      cornerStyle: object.cornerStyle,
      strokeUniform: object.strokeUniform,
      lockScalingX: object.lockScalingX,
      lockScalingY: object.lockScalingY,
      centeredRotation: object.centeredRotation,
      hasControls: object.hasControls,
      stroke: object.stroke,
      strokeColor: object.strokeColor,
      strokeWidth: object.strokeWidth,
      kerning: object.kerning,
      flipped: object.flipped,
      paintFirst: object.paintFirst,
      shadow: object.shadow,
      shadowColor: object.shadowColor,
      affectStroke: object.affectStroke,
      fontFamily: object.fontFamily,
      fontSize: object.fontSize,
      fontWeight: object.fontWeight,
      fontStyle: object.fontStyle,
      editable: object.editable,
      lockScalingFlip: object.lockScalingFlip,
      textAlign: object.textAlign,
      id: object.id,
      widgetType: object.widgetType,
      index: object.index,
      zIndex: object.zIndex,
      fill: object.fill,
      top: object.top,
      left: object.left,
      width: object.width,
      height: object.height,
      originalWidth: object.originalWidth,
      originalHeight: object.originalHeight,
      angle: object.angle,
      lockMovementX: object.lockMovementX,
      lockMovementY: object.lockMovementY,
      _controlsVisibility: object._controlsVisibility,
      visibleOnConfigurator: object.visibleOnConfigurator,
      visibleOnPrint: object.visibleOnPrint,
      linkedToPrintZone: object.linkedToPrintZone,
      clipPath: object.clipPath,
      free: object.free,
      previousType: object.type,
    };
  }
  
  return option;
}

/*
 * Expected behavior when editing object
 */
function editObject(eventData, transform) {
  const target = transform.target;
  handleEditObject(target, true);
}

/*
 * handle editing object
 */
function handleEditObject(target, updateResetData = true) {
  //close all popup before
  document.querySelectorAll(".popup").forEach((popup) => {
    popup.remove();
  });
  
  const canvas = target.canvas;
  const scale = canvas.scale;
  //const modelId = canvas.modelId;
  const itemId = canvas.itemId;
  document.querySelectorAll(".personnalisation-bit").forEach((item) => {
    item.classList.remove('focus-mobile');
  });
  document.querySelector("body").classList.add('body-index-change');
  const widget = document.querySelector('#config-perso-' + itemId + ' .personnalisation-bit[data-index="' + target.index + '"]');
  widget.classList.add('focus-mobile');
  if (target.widgetType === "text" || target.widgetType === "free_text") {
    var object = target;
    if(target.widgetType === "text"){
      object = target.item(1);
    }
    if(updateResetData){
      resetData = {
        text: object.text,
        textAlign: object.textAlign,
        fill: object.fill,
        fontFamily: object.fontFamily,
        fontSize: parseInt(object.fontSize / scale),
        fontWeight: object.fontWeight,
        fontStyle: object.fontStyle,
        shadow: object.shadow,
        strokeWidth: object.strokeWidth,
        stroke: object.stroke,
        strokeColor: object.strokeColor,
      };
    }
    /*
    setTimeout(function () {
      document.querySelector('.personnalisation-bit[data-index="' + target.index + '"] .input-text').focus();
    }, 50);
    */
  } else if (target.widgetType === "image_upload" || target.widgetType === "image_upload_or_library") {
    //document.querySelector('.personnalisation-bit[data-index="' + target.index + '"] .input-file').click();
  } else if (target.widgetType === "image_library") {
    //document.querySelector('.personnalisation-bit[data-index="' + target.index + '"] .btn-libraries').click();
  }
}

function renderIcon(ctx, left, top, styleOverride, fabricObject) {
  if (editMode && document.body.clientWidth < 767) {
    const size = this.cornerSize;
    //const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    if (fabricObject.widgetType === "text" || fabricObject.widgetType === "free_text") {
      ctx.drawImage(edittextIcon, -size / 2, -size / 2, size, size);
    } else {
      ctx.drawImage(editimageIcon, -size / 2, -size / 2, size, size);
    }
    ctx.restore();
  }
}

function renderIconRotation(ctx, left, top, styleOverride, fabricObject) {
  const size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(imgRotationIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

function renderIconResizing(ctx, left, top, styleOverride, fabricObject) {
  const size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(imgResizingIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

function renderIconDeletion(ctx, left, top, styleOverride, fabricObject) {
  const size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(imgDeletionIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

function renderIconMove(ctx, left, top, styleOverride, fabricObject) {
  const size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(moveIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

/*
 * get the coordinates of the rectangle to put in the jcrop
 */
function getRectPosition(widgetWidth, widgetHeight, imgWidth, imgHeight) {
  let originX;
  let originY;
  let rectWidth;
  let rectHeight;
  if (widgetWidth / widgetHeight > imgWidth / imgHeight) {
    rectWidth = imgWidth;
    rectHeight = imgWidth * widgetHeight / widgetWidth;
    originX = 0;
    originY = (imgHeight - rectHeight) / 2;
  } else {
    rectHeight = imgHeight;
    rectWidth = imgHeight * widgetWidth / widgetHeight;
    originY = 0;
    originX = (imgWidth - rectWidth) / 2;
  }
  
  return {
    x: originX,
    y: originY,
    w: rectWidth,
    h: rectHeight
  }
}

function updateInputPosition(pos) {
  document.querySelector("#jcrop-x").value = pos.x;
  document.querySelector("#jcrop-y").value = pos.y;
  document.querySelector("#jcrop-w").value = pos.w;
  document.querySelector("#jcrop-h").value = pos.h;
}

// données qui correspondent à tous les éléments
function setDefaultData(widget, defaultDatas) {
  if (typeof defaultDatas.id !== 'undefined') {
    widget.dataset.elementId = defaultDatas.id;
  }
  if (typeof defaultDatas.index !== 'undefined') {
    widget.dataset.index = defaultDatas.index;
  }
  if (typeof defaultDatas.level !== 'undefined') {
    widget.dataset.level = defaultDatas.level;
  }
  if (typeof defaultDatas.width !== 'undefined') {
    widget.dataset.width = defaultDatas.width;
  }
  if (typeof defaultDatas.height !== 'undefined') {
    widget.dataset.height = defaultDatas.height;
  }
  if (typeof defaultDatas.widthPx !== 'undefined') {
    widget.dataset.widthPx = defaultDatas.widthPx;
  }
  if (typeof defaultDatas.heightPx !== 'undefined') {
    widget.dataset.heightPx = defaultDatas.heightPx;
  }
  if (typeof defaultDatas.angle !== 'undefined') {
    widget.dataset.angle = defaultDatas.angle;
  }
  if (typeof defaultDatas.linesMax !== 'undefined') {
    widget.dataset.linesMax = defaultDatas.linesMax;
  }
  if (typeof defaultDatas.type !== 'undefined') {
    widget.dataset.type = defaultDatas.type;
  }
  if (typeof defaultDatas.mandatory !== 'undefined') {
    widget.dataset.mandatory = defaultDatas.mandatory;
  }
  if (typeof defaultDatas.coordinatesX !== 'undefined') {
    widget.dataset.coordinatesX = defaultDatas.coordinatesX;
  }
  if (typeof defaultDatas.coordinatesY !== 'undefined') {
    widget.dataset.coordinatesY = defaultDatas.coordinatesY;
  }
  if (typeof defaultDatas.left !== 'undefined') {
    widget.dataset.left = defaultDatas.left;
  }
  if (typeof defaultDatas.top !== 'undefined') {
    widget.dataset.top = defaultDatas.top;
  }
  if (typeof defaultDatas.cropX !== 'undefined') {
    widget.dataset.cropX = defaultDatas.cropX;
  }
  if (typeof defaultDatas.cropY !== 'undefined') {
    widget.dataset.cropY = defaultDatas.cropY;
  }
  if (typeof defaultDatas.cropWidth !== 'undefined') {
    widget.dataset.cropWidth = defaultDatas.cropWidth;
  }
  if (typeof defaultDatas.cropHeight !== 'undefined') {
    widget.dataset.cropHeight = defaultDatas.cropHeight;
  }
  if (typeof defaultDatas.blended !== 'undefined') {
    widget.dataset.blended = defaultDatas.blended;
  }
}

// vérifier si on est sur la modification du produit configurable, si oui, mettre les valeurs de la configuration sauvegardée
const checkIfOnUpdatedProduct = function checkIfOnUpdatedProduct() {
  let url = new URL(document.location.href);
  let configurationId = url.searchParams.get("configurationId");
  let variantId = url.searchParams.get("variantId");
  let quantity = url.searchParams.get("quantity");
  let options = url.searchParams.get("options");
  let optionsList = null;
  
  let bundleItemsDetails = url.searchParams.get("productBundleOrderItems");
  
  // update configuration simple
  if (configurationId && configurationId !== "null" && variantId && options !== null) {
    // afficher le bon variant
    optionsList = options.split(';');
    // sachant que s'il y a plusieurs options, la sélection des options se fait les unes après les autres, il faut mettre un time out entre chaque
    let nbrOptions = 0;
    document.querySelectorAll('.selectfitre-bit select').forEach(select=>{
      nbrOptions++;
      setTimeout(function () {
        optionsList.forEach(opt=>{
          if (opt.split(':')[0] === select.dataset.option) {
            select.querySelectorAll('option').forEach(selectOption=>{
              if (selectOption.value === opt.split(':')[1]) {
                select.value = selectOption.value;
                select.dispatchEvent(new Event('change'));
              }
            })
          }
        })
      }, 100);
    });
    // simuler le click sur le bouton personnalisé une fois que les options sont sélectionnées
    setTimeout(function () {
      document.querySelector("#btn-start-customization").click();
      let variantDetail = document.querySelector('#sylius-variants-details > [data-variantid="'+variantId+'"]');
      let advanced = variantDetail.dataset.advanced;
      
      // récupérer la configuration existante
      $.ajax({
        type: 'get',
        url: "/configurator/get-configuration",
        data: {configurationId: configurationId},
        success(response) {
          let configurationId = response.configurationId;
          document.querySelectorAll('.canvas-item').forEach(canva=>{
            canva.dataset.configurationId = configurationId;
          })
          //let array = Object.entries(response.data);
          setTimeout(function() {
            response.data.forEach(elementArray => {
              const datas = JSON.parse(elementArray);
              datas.itemId = 0;
              fillInElements(datas, advanced);
            });
            updateQuantity(variantId, quantity);
          }, 1000);
        },
        error(jqXHR, textStatus, errorThrown) {
          // console.log(`ajax error ${textStatus} ${errorThrown}`);
        },
      });
    }, 1000 + 100 * nbrOptions);
  }
  
  // récupérer la configuration existante pour chaque productBundleItems
  if (bundleItemsDetails && bundleItemsDetails !== "null" && variantId) {
    setTimeout(function () {
      document.querySelector("#btn-start-customization").click();
    }, 30);
    
    let bundleItems = bundleItemsDetails.split(',');
    
    bundleItems.forEach(bundleItem=>{
      let bundleItemDetail = bundleItem.split(':');
      let bundleOrderItemId = bundleItemDetail[0];
      //let bundleItemId = bundleItemDetail[1];
      let configurationId = bundleItemDetail[2];
      $.ajax({
        type: 'get',
        url: "/configurator/get-configuration",
        data: {configurationId: configurationId},
        success(response) {
          let configurationId = response.configurationId;
          let itemId = response.itemId;
          
          // trouver l'id du model
          let variantDetail = document.querySelector('#sylius-variants-details > [data-itemid="'+itemId+'"]');
          let advanced = variantDetail.dataset.advanced;
          
          if(advanced == 1) {
            // sélectionner le bon canvas-item
            if(document.querySelector('#canvas-' + itemId) !== null){
              let canvaItems = document.querySelector('#canvas-' + itemId).parentElement.querySelectorAll('.canvas-item');
              canvaItems.forEach(canva => {
                canva.dataset.configurationId = configurationId;
              });
            }
          }
          //let array = Object.entries(response.data);
          setTimeout(function() {
            response.data.forEach(elementArray => {
              const datas = JSON.parse(elementArray);
              datas.itemId = itemId;
              fillInElements(datas, advanced);
            });
            updateQuantity(variantId, quantity)
          }, 1000);
        },
        error(jqXHR, textStatus, errorThrown) {
          // console.log(`ajax error ${textStatus} ${errorThrown}`);
        },
      });
    });
  }
}

function updateQuantity(variantId, quantity) {
  document.querySelector('#content-qte-tmp input#input-qte-tmp').value = quantity;
  document.querySelector('.sylius-quantity input[type="number"]').value = quantity;
}

//fill input Elements with saved values and trigger event to update canvas (advanced case)
function fillInElements(datas, advanced = 1) {
  const index = datas.index;
  const itemId = datas.itemId;
  const widget = document.querySelector('#config-perso-' + itemId + ' .personnalisation-bit[data-index="' + index + '"]');
  
  if(advanced == 0){
    switch (datas.type) {
      case "text":
        widget.querySelector('.input-text').value = datas.textContent;
        break;
      case "image_upload":
        //console.log("handling image_upload")
        break;
    }
  }else{
    let prefixModelId = "";
    let canvasInfo = canvasInfoList[0];
    if(itemId != 0){
      if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
        prefixModelId = "_" + itemId;
      }
      canvasInfo = canvasInfoList.find(info => info.itemId == itemId);
    }
    
    const canvas = canvasInfo.canvas;
    const scale = canvas.scale;
    const model = canvasInfo.model;
    
    if (!widget) { // free element of free configuration
      switch (datas.type) {
        case "image_library":
          addFreeWidgetImageLibrary(canvas, model, datas);
          break;
        case "image_upload":
          addFreeWidgetImageUpload(canvas, model, datas);
          break;
        case "text":
          addFreeWidgetText(canvas, model, datas);
          break;
      }
    } else { // standard element
      const element = canvas.getObjects().find(obj => obj.index == index);
      
      if (typeof element !== 'undefined') {
        let widgetWidth = null;
        let widgetHeight = null;
        let widgetWidthPx = null;
        element.angle = datas.orientation;
        element.left = datas.coordinatesX * scale;
        element.top = datas.coordinatesY * scale;
        widget.dataset.coordinatesX = datas.coordinatesX;
        widget.dataset.coordinatesY = datas.coordinatesY;
        widget.dataset.angle = datas.orientation;
        switch (datas.type) {
          case "text":
            console.log("text", element.widgetType, datas.textContent)
            widget.querySelector('.input-text').value = datas.textContent;
  
            //widget.querySelector('.input-text').dispatchEvent(new Event('input'));
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.text = datas.textContent;
            } else {
              element.text = datas.textContent;
              element.width = parseInt(widget.getAttribute("data-width"));
              element.height = parseInt(widget.getAttribute("data-height"));
            }
  
            widget.querySelector('.input-text').setAttribute("data-from-cart", 1);
            widgetTexts[widget.getAttribute("data-element-id")] = datas.textContent;
            widget.querySelector('.input-size').value = datas.textSize;
  
            //widget.querySelector('.input-size').dispatchEvent(new Event('blur'));
            element.fontSize = datas.textSize * scale;
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.fontSize = datas.textSize * scale;
              textElement.strokeWidth = datas.textSize * scale / 15;
            }
  
            if(datas.diameter > 0){
              widget.querySelector(".input-diameter-reverse").value = 10000 / datas.diameter;
              widget.querySelector(".input-diameter").value = datas.diameter;
  
              //widget.querySelector(".input-diameter-reverse").dispatchEvent(new Event('change'));
              if(element.widgetType === "text"){
                const textElement = element.item(1);
                textElement.diameter = datas.diameter * scale;
              } else {
                element.diameter = datas.diameter * scale;
              }
            }
  
            let flipped = false;
            if (datas.curvedFlipped) {
              flipped = true;
              widget.querySelector('.input-flipped').checked = true;
            } else {
              widget.querySelector('.input-flipped').checked = false;
            }
  
            //widget.querySelector(".input-flipped").dispatchEvent(new Event('change'));
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.flipped = flipped;
            } else {
              element.flipped = flipped;
            }
            
            element.fontSize = datas.textSize * scale;
            element.strokeWidth = datas.textSize * scale / 15;
            let fontWeight = "normal";
            if (datas.textBold){
              fontWeight = "bold";
              widget.querySelector('.input-font-weight').checked = true;
            }else{
              widget.querySelector('.input-font-weight').checked = false;
            }
  
            //widget.querySelector('.input-font-weight').dispatchEvent(new Event('change'));
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.fontWeight = fontWeight;
            } else {
              element.fontWeight = fontWeight;
            }
  
            let fontStyle = "normal";
            if (datas.textItalic){
              fontStyle = "italic";
              widget.querySelector('.input-font-style').checked = true;
            } else {
              widget.querySelector('.input-font-style').checked = false;
            }
  
            //widget.querySelector('.input-font-style').dispatchEvent(new Event('change'));
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.fontStyle = fontStyle;
            } else {
              element.fontStyle = fontStyle;
            }
            
            const value = datas.fontName + "*" + datas.fontPath;
            setValueCustomSelectFont(widget.querySelector('.fonts'), value);
            setFont(element, value, widget, false);
            
            //fontColor
            if (datas.fontColorHexa !== null) {
              const fontColorTarget = widget.querySelector(".font-color");
              setValueCustomSelectFontColor(fontColorTarget, datas.fontColorHexa);
              setFontColor(element, datas.fontColorHexa);
            }
            //strokeColor
            let textStrokeColor = "transparent";
            if (datas.textStroke !== null) {
              textStrokeColor = datas.textStroke;
            }
            const strokeTarget = widget.querySelector(".stroke-color");
            setValueCustomSelectStroke(strokeTarget, textStrokeColor);
            element.strokeColor = datas.textStroke;
            updateStrokeColor(element);
            
            //shadowCOlor
            let textShadowColor = "transparent";
            if (datas.textShadow !== null) {
              textShadowColor = datas.textShadow;
            }
            const shadowTarget = widget.querySelector(".shadow-color");
            setValueCustomSelectShadow(shadowTarget, textShadowColor);
            element.shadowColor = datas.textShadow;
            updateShadowColor(element);
            
            //lineHeight
            let lineHeight = datas.lineHeight;
            if (datas.lineHeight === null) {
              lineHeight = 1;
            }
            widget.querySelector('.input-line-space').value = lineHeight;
            const lineHeightTarget = widget.querySelector(".line-space");
            setValueCustomSelectLineSpace(lineHeightTarget, lineHeight);
            element.lineHeight = lineHeight;
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              textElement.lineHeight = lineHeight;
            }
            
            canvas.renderAll();
            
            //text align
            if (datas.textAlignment == "left" && widget.querySelector('#text_align' + prefixModelId + '_left_' + index)){
              widget.querySelector('#text_align' + prefixModelId + '_left_' + index).checked = true;
              //widget.querySelector('#text_align' + prefixModelId + '_left_' + index).dispatchEvent(new Event('change'));
              element.textAlign = "left";
            } else if (datas.textAlignment == "right" && widget.querySelector('#text_align' + prefixModelId + '_right_' + index)){
              widget.querySelector('#text_align' + prefixModelId + '_right_' + index).checked = true;
              //widget.querySelector('#text_align' + prefixModelId + '_right_' + index).dispatchEvent(new Event('change'));
              element.textAlign = "right";
            } else if (widget.querySelector('#text_align' + prefixModelId + '_center_' + index)) {
              widget.querySelector('#text_align' + prefixModelId + '_center_' + index).checked = true;
              //widget.querySelector('#text_align' + prefixModelId + '_center_' + index).dispatchEvent(new Event('change'));
              element.textAlign = "center";
            }
  
            if(element.widgetType === "text"){
              const textElement = element.item(1);
              adjustTextElement(element);
              textElement.dirty = true;
            }
            element.dirty = true;
            canvas.renderAll();
            
            // event trigger
            //widget.querySelector('.input-text').dispatchEvent(new Event('input'));
            //widget.querySelector('.input-size').dispatchEvent(new Event('blur'));
            //widget.querySelector('.input-font-weight').dispatchEvent(new Event('change'));
            //widget.querySelector('.input-font-style').dispatchEvent(new Event('change'));
            
            //widget.querySelector(".input-flipped").dispatchEvent(new Event('change'));
            //widget.querySelector(".input-diameter-reverse").dispatchEvent(new Event('change'));
            //widget.querySelector(".input-line-space").dispatchEvent(new Event('input'));
            
            break;
          case "image_upload":
            let imgPath = "/" + datas.imagePath;
            widgetWidth = parseInt(widget.getAttribute("data-width"));
            widgetHeight = parseInt(widget.getAttribute("data-height"));
            widgetWidthPx = parseInt(widget.getAttribute("data-width-px"));
            
            const imageResize = document.createElement("img");
            imageResize.setAttribute('id', 'img-resize');
            imageResize.src = imgPath;
            Jcrop.load(imageResize).then(img => {
              imageResize.style.width = '300px';
              imageResize.style.height = 'auto';
              const realWidth = img.naturalWidth;
              const realHeight = img.naturalHeight;
              if (realWidth > 0 && realHeight > 0) {
                const width = datas.widthPx * scale;
                const height = datas.heightPx * scale;
                const cropWidth = datas.imageResizeWidth;
                const cropHeight = datas.imageResizeHeight;
                const cropX = parseInt(datas.cropX) ? parseInt(datas.cropX) : 0;
                const cropY = parseInt(datas.cropY) ? parseInt(datas.cropY) : 0;
                const scaleX = width / cropWidth;
                const scaleY = height / cropHeight;
                checkImageQuality(cropWidth, datas.widthPx, widget);
                fabric.Image.fromURL(imgPath, function(oImg) {
                  oImg.set({
                      top: - element.height / 2,
                      left: - element.width / 2,
                      cropX: cropX,
                      cropY: cropY,
                      width: cropWidth,
                      height: cropHeight,
                      scaleX: scaleX,
                      scaleY: scaleY
                    }
                  )
                  element.add(oImg);
                  
                  element.setCoords();
                  widget.dataset.cropX = cropX;
                  widget.dataset.cropY = cropY;
                  widget.dataset.cropWidth = cropWidth;
                  widget.dataset.cropHeight = cropHeight;
                  widget.dataset.type = "image_upload";
                  widget.dataset.update = true;
                  canvas.renderAll();
                });
              } else {
                alert("Veuillez télécharger une image valide.")
              }
              widget.classList.add('ok-valid');
            });
            break;
          case "image_library":
            let id = datas.libraryFileId;
            widgetWidth = parseInt(widget.getAttribute("data-width"));
            widgetHeight = parseInt(widget.getAttribute("data-height"));
            widget.dataset.libraryFileId = id;
            widget.dataset.type = "image_library";
            let imgLink = "/" + datas.libraryFile;
            
            setImgFabric(element, id, imgLink);
            widget.classList.add('ok-valid');
            break;
        }
      }
    }
  }
  
  setTimeout(function() {
    checkConfiguratorValid();
  }, 50);
}

function setImgFabric(element, id, imgLink) {
  const canvas = element.canvas;
  fabric.Image.fromURL(imgLink, function (oImg) {
    const imageElement = element.item(1);
    if(imageElement !== null){
      element.remove(imageElement);
    }
    
    let scaleTmp = element.originalWidth / oImg.width;
    if((element.originalWidth / element.originalHeight) >= (oImg.width / oImg.height)){
      scaleTmp = element.originalHeight / oImg.height;
    }
    
    const topImg = - oImg.height * scaleTmp / 2;
    const leftImg = - oImg.width * scaleTmp / 2;
    oImg.set({
        top: topImg,
        left: leftImg,
        scaleX: scaleTmp,
        scaleY: scaleTmp
      }
    )
    element.add(oImg);
    element.set({libraryFileId: id});
    canvas.renderAll();
  });
}

function createRectForGroup(options) {
  const rect = new fabric.Rect({
    top: 0,
    left: 0,
    strokeWidth: 1,
    strokeDashArray: [5, 4],
    //stroke: 'rgba(180,180,180,0.5)',
    stroke: 'rgba(80,80,80,0.8)',
    fill: 'rgba(0,0,0,0)',
    width: 10,
    height: 10,
    ...options
  });
  
  return rect;
}

function handleClickBack(){
  editMode = false;
  document.querySelector('#js-configurator').classList.remove("active");
  document.getElementById('sylius-product-name').classList.remove('d-none');
  canvasInfoList.forEach((canvasInfo) => {
    const canvas = canvasInfo.canvas
    canvas.dispose();
  });
  canvasInfoList = [];
  /*
  if(document.querySelector(".popup-conf-wrap") !== null) {
    document.querySelector(".popup-conf-wrap").classList.add("hide");
  }
  */
  if(document.querySelector(".popup-conf-wrap") !== null) {
    document.querySelector(".popup-conf-wrap").remove();
  }
  document.querySelector(".btn-retour").classList.remove("show");
  document.querySelector("#canvas-wrap").innerHTML = "";
  
  document.querySelector('.article-right .descr-art').classList.remove("d-none");
  document.querySelector('.tab-article-content').classList.remove("d-none");
  document.querySelector('.article-bottom').classList.remove("d-none");
  document.querySelector("#js-no-configurator").classList.remove("d-none");
  document.querySelector("#js-configurator").classList.add("hide");
  document.querySelector("#sylius-product-selecting-variant").classList.remove("d-none");
  document.querySelector("#btn-start-customization").classList.remove("d-none");
  document.querySelector(".btn-panier").classList.add("disabled");
  document.querySelector(".btn-panier").setAttribute('disabled', '');
  document.querySelector('.prix-art-panier').classList.remove('d-none');
  
  if(document.querySelector('#content-qte-tmp')){
    document.querySelector('#content-qte-tmp').classList.add('d-none');
  }
  
  document.querySelector("#box-config-perso").innerHTML = "";
  document.querySelector("#box-free-element").innerHTML = "";
  if(document.querySelector(".products-bundles") !== null) {
    document.querySelector(".products-bundles").innerHTML = "";
  }
  
  if(document.querySelector("#box-validation") !== null) {
    document.querySelector('#box-validation').remove();
  }
  document.querySelector('.ariane-box').classList.remove('d-none');
  document.querySelector('body').classList.remove('no-scrolling');
  if(document.querySelector(".hide-header") !== null) {
    document.querySelector('.hide-header').remove();
  }
  if(document.querySelector(".box-transparent") !== null) {
    document.querySelector('.box-transparent').remove();
  }
  if(document.querySelector(".widgets-not-valid") !== null) {
    document.querySelector('.widgets-not-valid').style.display = "none";
  }
}

function checkImageQuality(imgWidthPx, widgetWidthPx, widget) {
  console.log("checkImageQuality")
  if (imgWidthPx >= widgetWidthPx) {
    widget.querySelector('.quality-img-message').style.display = "none";
  } else {
    widget.querySelector('.quality-img-message').style.display = "inline-block";
  }
}

function selectObject(object) {
  const canvas = object.canvas;
  const selectedObject = canvas.getActiveObject();
  if(selectedObject == null){
    canvas.setActiveObject(object);
  } else if (selectedObject.index != object.index) {
    canvas.discardActiveObject();
    canvas.setActiveObject(object);
  }
}

// valider le widget texte
function checkTextElementValid(widget) {
  if (widget.dataset.mandatory === "true" && (widget.querySelector('.input-text').value === "" || typeof widget.querySelector('.input-text').value === "undefined")) {
    widget.classList.remove('ok-valid');
  } else {
    if (widget.dataset.mandatory === "false") {
      widget.classList.add('optionnal');
    }
    widget.classList.add('ok-valid');
  }
}

// tous les widgets doivent être validés pour pouvoir ajouter le produit au panier
function checkConfiguratorValid() {
  // attendre que tous les éléments se soient chargés
  setTimeout(function () {
    let widgets = document.querySelectorAll('.personnalisation-bit');
    let configuratorValid = true;
    widgets.forEach(widget => {
      // il faut que le champs soit requis et non remplis pour ne pas valider le widget
      if (!widget.classList.contains('d-none') && !widget.classList.contains('ok-valid') && (widget.dataset.mandatory === true || widget.dataset.mandatory === "true")) {
        configuratorValid = false;
      }
    });
    if (configuratorValid) {
      if(document.querySelector('.widgets-not-valid')){
        document.querySelector('.widgets-not-valid').style.display = "none";
        document.querySelector('.widgets-not-valid').style.display = "none";
      }
      
      if(document.querySelector('.btn-panier')){
        document.querySelector('.btn-panier').setAttribute('disabled', '');
        document.querySelector('.btn-panier').classList.add('disabled');
      }
      
      if(document.querySelector('.products-bundles') && document.querySelector('.products-bundles').getAttribute("data-bundle") == 1){
        const list = document.querySelectorAll('.select-product-item');
        if(list.length > 1){
          const last = list[list.length - 1];
          if(last.classList.contains("active")){
            if(document.querySelector('#content-qte-tmp')){
              document.querySelector('#content-qte-tmp').classList.remove('d-none');
            }
            document.querySelector('.btn-panier').removeAttribute('disabled');
            document.querySelector('.btn-panier').classList.remove('disabled');
          }
        }
      }else{
        if(document.querySelector('#content-qte-tmp')) {
          document.querySelector('#content-qte-tmp').classList.remove('d-none');
        }
        if(document.querySelector('.btn-panier')) {
          document.querySelector('.btn-panier').removeAttribute('disabled');
          document.querySelector('.btn-panier').classList.remove('disabled');
        }
        if (document.getElementById('price-mobile'))
          document.getElementById('price-mobile').classList.remove('d-none');
      }
      
      let url = new URL(document.location.href);
      let quantity = url.searchParams.get("quantity");
      // hors update
      if (document.querySelector("#input-qte-tmp") && !quantity) {
        const inputQuantity = document.querySelector('.sylius-quantity input[type="number"]');
        document.querySelector("#input-qte-tmp").value = inputQuantity.value;
      }
      if(document.querySelector('#btn-next-add-panier') !== null){
        document.querySelector('#btn-next-add-panier').classList.add("active");
        document.querySelector('.btn-panier').removeAttribute('disabled');
        document.querySelector('.btn-panier').classList.remove('disabled');
      }
    } else {
      if(document.querySelector('.widgets-not-valid')){
        document.querySelector('.widgets-not-valid').style.display = "block";
      }
      if(document.querySelector('.btn-panier')) {
        document.querySelector('.btn-panier').setAttribute('disabled', '');
        document.querySelector('.btn-panier').classList.add('disabled');
        document.querySelector('.art-qte').classList.add('d-none');
      }
      
      if(document.querySelector('#content-qte-tmp')){
        document.querySelector('#content-qte-tmp').classList.add('d-none');
      }
      
      if(document.querySelector('#btn-next-add-panier') !== null){
        document.querySelector('#btn-next-add-panier').classList.remove("active");
      }
    }
  }, 100);
}

function checkItemValid(itemId, checkObjects = false) {
  let widgets = document.querySelectorAll('#config-perso-' + itemId + ' .personnalisation-bit:not(.d-none)');
  let configuratorValid = true;
  
  const canvasInfo = canvasInfoList.find(info => info.itemId == itemId);
  let canvas = null;
  if(canvasInfo){
    canvas = canvasInfo.canvas;
  }
  widgets.forEach(widget => {
    // il faut que le champs soit requis et non remplis pour ne pas valider le widget
    if (!widget.classList.contains('ok-valid') && widget.dataset.mandatory === "true") {
      if(checkObjects){
        if(canvas){
          const object = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
          if(object){
            const rectElement = object.item(0);
            //rectElement.stroke = 'rgba(249, 138, 138, 1)';
            rectElement.stroke = 'rgba(207, 8, 8, 1)';
            canvas.renderAll();
          }
        }
      }
      configuratorValid = false;
    }else{
      if(checkObjects){
        if(canvas){
          const object = canvas.getObjects().find(obj => obj.index == widget.getAttribute("data-index"));
          if(object){
            const rectElement = object.item(0);
            rectElement.stroke = 'rgba(80,80,80,0.8)';
            canvas.renderAll();
          }
        }
      }
    }
  });
  return configuratorValid;
}

// function checkSimpleConfiguratorValid() {
//   // attendre que tous les éléments se soient chargés
//   setTimeout(function () {
//     let widgets = document.querySelectorAll('.personnalisation-bit');
//     let configuratorValid = true;
//     widgets.forEach(widget => {
//       // il faut que le champs soit requis et non remplis pour ne pas valider le widget
//       if (!widget.classList.contains('d-none') && !widget.classList.contains('ok-valid') && (widget.dataset.mandatory === true || widget.dataset.mandatory === "true")) {
//         configuratorValid = false;
//       }
//     });
//     if (configuratorValid) {
//       if(document.querySelector('.widgets-not-valid')){
//         document.querySelector('.widgets-not-valid').style.display = "none";
//       }
//       if (document.querySelector('.btn-panier').hasAttribute('disabled')){
//         document.querySelector('.btn-panier').removeAttribute('disabled');
//         document.querySelector('.btn-panier').classList.remove('disabled');
//       }
//     } else {
//       if(document.querySelector('.widgets-not-valid')){
//         document.querySelector('.widgets-not-valid').style.display = "block";
//       }
//       document.querySelector('.btn-panier').setAttribute('disabled', '');
//       document.querySelector('.btn-panier').classList.add('disabled');
//     }
//   }, 100);
// }

/*
 * Reset the values of the widget fields according to the given parameters
 */
function resetTextWidget(widget, data) {
  widget.querySelector(".input-text").value = data.text;
  /*
  widget.querySelector(".input-size").value = data.fontSize;
  widget.querySelector('.input-text-align').checked = false;
  widget.querySelector('.input-text-align-' + data.textAlign).checked = true;

  const strokeTarget = widget.querySelector(".stroke-color");
  setValueCustomSelectStroke(strokeTarget, data.stroke);
  const shadowTarget = widget.querySelector(".shadow-color");
  setValueCustomSelectShadow(shadowTarget, data.shadow);
  const fontColorTarget = widget.querySelector(".font-color");
  setValueCustomSelectFontColor(fontColorTarget, data.fill);

  const fontTarget = widget.querySelector(".fonts");
  if(fontTarget.querySelector(".font-item[data-fontname='"+data.fontFamily+"']") !== null){
    const defaultFont = fontTarget.querySelector(".font-item[data-fontname='"+data.fontFamily+"']").getAttribute("data-value");
    setValueCustomSelectFont(fontTarget, defaultFont);
  }

  if (data.fontWeight === "bold") {
    widget.querySelector('.input-font-weight').checked = true;
  } else {
    widget.querySelector('.input-font-weight').checked = false;
  }

  if (data.fontStyle === "italic") {
    widget.querySelector('.input-font-style').checked = true;
  } else {
    widget.querySelector('.input-font-style').checked = false;
  }
  */
}

function disableScroll(){
  canvas.allowTouchScrolling = false;
}

function enableScroll(){
  canvas.allowTouchScrolling = true;
}

function displayInfoMaxCharacters(widget){
  if(widget.hasAttribute("data-maxlength")){
    const maxlength = parseInt(widget.getAttribute("data-maxlength"));
    const valueLength = widget.querySelector(".input-text").value.length;
    widget.querySelector(".info-characters").innerHTML = "<span> caractères (" + valueLength + "/" + maxlength + ")</span>";
  }
}

function displayInfoMaxLines(widget){
  if(widget.hasAttribute("data-lines-max")){
    if(parseInt(widget.getAttribute("data-lines-max")) > 1){
      const maxlines = parseInt(widget.getAttribute("data-lines-max"));
      const linesLength = widget.querySelector(".input-text").value.split("\n").length;
      widget.querySelector(".info-lines").innerHTML = "<span> lignes (" + linesLength + "/" + maxlines + ")</span>";
    }
  }
}

function handleCustomizationAction(){
  editMode = true;
  document.querySelector('#box-validation').classList.remove('hide');
  document.querySelector('.btn-back-wrap').classList.remove('d-none');
  
  canvasInfoList.forEach((canvasInfo) => {
    const canvas = canvasInfo.canvas;
    const model = canvasInfo.model;
    const itemId = model.itemId;
    
    canvas.discardActiveObject();
    
    const object = canvas.getObjects().find(obj => obj.widgetType === "print_zone");
    if (object) {
      const stroke = 'rgba(0,200,0,0.8)';
      object.stroke = stroke;
      object.dirty = true;
      canvas.renderAll();
      
      const bodyWidth = document.body.clientWidth;
      const windowHeight = window.innerHeight;
      
      let bodyHeight = windowHeight;
      bodyHeight = bodyHeight - document.querySelector('#box-validation').offsetHeight;
      bodyHeight = bodyHeight - document.querySelector('.header-body').offsetHeight;
      bodyHeight = bodyHeight - document.querySelector('.art-top-desc').offsetHeight;
      bodyHeight = bodyHeight - document.querySelector('.products-bundles').offsetHeight;
      
      // if(canvas.height < bodyHeight){
      //   bodyHeight = canvas.height;
      // }
      
      document.querySelector("#wrap-canvas-"+itemId).style.height = bodyHeight + 'px';
      document.querySelector("#wrap-canvas-"+itemId).style.display = 'flex';
      document.querySelector("#wrap-canvas-"+itemId).style.alignItems = 'center';
      const padding = 40;
      const canvasContainer = document.querySelector('#wrap-canvas-' + itemId + ' .canvas-container');
      
      let zoom = 1;
      if(bodyWidth / bodyHeight > object.width / object.height){
        zoom = (bodyHeight - padding) / object.height;
      } else {
        zoom = (bodyWidth - padding) / object.width;
      }
      canvas.setZoom(zoom);
      let panX = object.left * zoom - (canvas.width - object.width * zoom) / 2 + object.strokeWidth * zoom / 2;
      let panY = object.top * zoom - (canvas.height - object.height * zoom) / 2 + object.strokeWidth * zoom / 2;
      canvas.absolutePan({
        x: panX,
        y: panY
      });
    }
  });
  
  document.querySelector('.ariane-box').classList.add('d-none');
  
  if(document.querySelector(".select-product-item") !== null){
    if(document.querySelector(".products-bundles") !== null){
      document.querySelector(".products-bundles").setAttribute("data-init", 1);
    }
    document.querySelector(".select-product-item").click();
  }
  
  let noScrolling = false;
  if(document.querySelectorAll('.select-product-item').length >= 1){
    if(document.querySelector(".select-product-item.active").getAttribute("data-advanced") == "1"){
      noScrolling = true;
    }
  } else {
    canvasInfoList.forEach((canvasInfo) => {
      const canvas = canvasInfo.canvas;
      const model = canvasInfo.model;
      if(model.advanced){
        noScrolling = true;
      }
    });
  }
  
  window.scrollTo({
    top: 0,
  });
  if(noScrolling){
    document.querySelector('body').classList.add('no-scrolling');
    if(!document.querySelector(".hide-header")) {
      document.querySelector('#header').insertAdjacentHTML("afterbegin", "<div class='hide-header'></div>");
    }
  }
}

function generatePrintableCanvas(itemId, type, test){
  const canvasInfo = canvasInfoList.find(info => info.itemId == itemId);
  const canvas = canvasInfo.canvas;
  const scale = canvas.scale;
  //const printZoneRealWidthPx = canvasInfo.model.printZoneWidthPx;
  //const printZoneRealHeightPx = canvasInfo.model.printZoneHeightPx;
  let canvasRealWidthPx = canvasInfo.model.width;
  let canvasRealHeightPx = canvasInfo.model.height;
  
  let realScale = 1 / scale;
  if(type === "print"){
    realScale = realScale * 4,166667;
  }else{
    const max = 700;
    const maxDim = canvasRealWidthPx > canvasRealHeightPx ? canvasRealWidthPx : canvasRealHeightPx;
    if(maxDim > max){
      realScale = realScale * max / maxDim;
      canvasRealWidthPx = canvasRealWidthPx * max / maxDim;
      canvasRealHeightPx = canvasRealHeightPx * max / maxDim;
    }
  }
  
  if(document.querySelector('#div-tmp2') == null){
    document.querySelector('#js-configurator').insertAdjacentHTML("afterbegin", "<div id='div-tmp2' class='d-none'></div>");
  }
  
  if(pCanvas == null){
    let printableCanvas = document.createElement("canvas");
    printableCanvas.id = "printable-canvas";
    
    if(document.querySelector('#printable-canvas') == null){
      document.querySelector('#div-tmp2').appendChild(printableCanvas);
    }
    
    pCanvas = new fabric.Canvas('printable-canvas', {
      preserveObjectStacking: true,
      selection: false
    });
  }
  pCanvas.remove(...pCanvas.getObjects());
  pCanvas.setWidth(canvasRealWidthPx);
  pCanvas.setHeight(canvasRealHeightPx);
  
  const clipObject = canvas.getObjects().find(obj => obj.widgetType === "print_zone");
  let clipPath = null;
  if (clipObject) {
    clipPath = new fabric.Rect({
      left: clipObject.left * realScale,
      top: clipObject.top * realScale,
      width: clipObject.width * realScale,
      height: clipObject.height * realScale,
      absolutePositioned: true,
    });
  }
  
  canvas.getObjects().forEach((object) => {
    if((type === "print" && object.visibleOnPrint) || (type === "cart" && object.visibleOnConfigurator)){
      const option = {
        left: object.left * realScale,
        top: object.top * realScale,
        angle: object.angle,
        diameter: object.diameter * realScale,
        flipped: object.flipped,
        evented: false,
        editable: false
      }
      const textOption = {
        left: object.left * realScale,
        top: object.top * realScale,
        angle: object.angle,
        evented: false,
        editable: false
      }
      
      textOption.originalClipPath = null;
      if(object.linkedToPrintZone){
        option.clipPath = clipPath;
        textOption.originalClipPath = clipPath;
      }
      
      if(object.widgetType === "print_zone" && type === "cart"){
        let element = new fabric.Rect({
          ...option,
          width: object.width * realScale,
          height: object.height * realScale,
          //stroke: '#00ff00',
          stroke: 'rgba(0,0,0,0)',
          strokeWidth: 1,
          fill: 'rgba(0,0,0,0)'
        });
        pCanvas.add(element);
      } else if (object.widgetType === "image_admin" && object.isSVG) {
        const element = object.elementTmp;
        
        element.set({ ...option,
          scaleX: object.scaleX * realScale,
          scaleY: object.scaleY * realScale
        });
        
        pCanvas.add(element).renderAll();
      } else if (object.widgetType === "image_admin" || object.widgetType === "image_upload" || object.widgetType === "image_library" || object.widgetType === "image_upload_or_library" || object.widgetType === "free_image_library" || object.widgetType === "free_image_upload") {
        const widthTmp = object.originalWidth * object.scaleX * realScale;
        const heightTmp = object.originalHeight * object.scaleY * realScale;
        const rect = createRectForGroup({width: widthTmp, height: heightTmp, stroke: 'rgba(0,0,0,0)'});
        let element = new fabric.Group([ rect ], {
          ...option,
        });
        cachingGroup(element);
        
        
        const imageElement = object.item(1);
        if(imageElement !== null && typeof imageElement !== 'undefined'){
          const oImg = new fabric.Image(imageElement._element, {
            width: imageElement.width,
            height: imageElement.height,
            top: imageElement.top * object.scaleY * realScale,
            left: imageElement.left * object.scaleX * realScale,
            scaleX: imageElement.scaleX * object.scaleX * realScale,
            scaleY: imageElement.scaleY * object.scaleY * realScale,
            cropX: imageElement.cropX,
            cropY: imageElement.cropY
          }, { crossOrigin: 'anonymous' });
          
          element.add(oImg);
          
        }
        if(object.blended){
          element.globalCompositeOperation = 'multiply';
        }
        pCanvas.add(element);
        
      } else if (object.widgetType === "text") {
        const textElement = object.item(1);
        let width = object.originalWidth * realScale;
        let height = object.originalHeight * realScale;
        
        let text;
        /*
        const shadow = new fabric.Shadow({
          color: object.shadowColor,
          offsetX: 1 * realScale,
          offsetY: 1 * realScale,
          blur: parseInt(2 * realScale)
        });
        */
        const shadow = new fabric.Shadow({
          color: object.shadowColor,
          offsetX: textElement.fontSize * realScale / 40,
          offsetY: textElement.fontSize * realScale / 40,
          blur: parseInt(textElement.fontSize * realScale / 20)
        });
        
        if(textElement.stroke === null || textElement.stroke === "transparent"){
          textElement.stroke = 'rgba(0,0,0,0)';
        }
        
        const strokeWidth = parseInt(textElement.fontSize * realScale / 15);
        
        if(textElement.diameter > 0){
          text = new fabric.TextCurved(textElement.text, {
            top: 0,
            left: 0,
            diameter: textElement.diameter * realScale,
            flipped: textElement.flipped,
            fill: textElement.fill,
            stroke: textElement.stroke,
            //strokeWidth: 3 * realScale,
            strokeWidth: strokeWidth,
            paintFirst: "stroke",
            shadow: shadow,
            affectStroke: true,
            strokeUniform: true,
            fontFamily: textElement.fontFamily,
            fontStyle: textElement.fontStyle,
            fontWeight: textElement.fontWeight,
            fontSize: textElement.fontSize * realScale,
            textAlign: textElement.textAlign
          });
        } else {
          text = new fabric.Text(textElement._text.join(''), {
            top: 0,
            left: 0,
            fill: textElement.fill,
            stroke: textElement.stroke,
            //strokeWidth: 3 * realScale,
            strokeWidth: strokeWidth,
            paintFirst: "stroke",
            shadow: shadow,
            affectStroke: true,
            strokeUniform: true,
            fontFamily: textElement.fontFamily,
            fontStyle: textElement.fontStyle,
            fontWeight: textElement.fontWeight,
            fontSize: textElement.fontSize * realScale,
            lineHeight: textElement.lineHeight,
            textAlign: textElement.textAlign
          });
        }
        
        const rect = createRectForGroup({width: width, height: height, stroke: 'rgba(0,0,0,0)'});
        let element = new fabric.Group([ rect ], {
          ...textOption,
          textAlign: object.textAlign,
          widthOriginal: width,
          heightOriginal: height
        });
        element.add(text);
        
        cachingGroup(element);
        pCanvas.add(element);
        pCanvas.renderAll();
        
        const centerPoint = element.getCenterPoint();
        element.set({
          width: element.widthOriginal + element.widthOriginal / 4,
          height: element.heightOriginal + element.heightOriginal,
        });
        element.setPositionByOrigin(centerPoint, 'center', 'center');
        element.dirty = true;
        pCanvas.renderAll();
        
        let top = - text.height / 2;
        if(textElement.diameter > 0){
          if(textElement.flipped){
            top = (height / 2) - text.height;
          }else{
            top = - height / 2;
          }
        }
        let left = - text.width / 2;
        if(element.textAlign === "left"){
          left = - element.widthOriginal / 2
        }
        if(element.textAlign === "right"){
          left = - text.width / 2 + element.widthOriginal / 2 - text.width / 2;
        }
        text.set({
          top: top,
          left: left
        });
        text.dirty = true;
        element.clipPath = new fabric.Rect({
          width: 1,
          height: 1,
          top: - element.height / 2,
          left: - element.width / 2
        });
        element.dirty = true;
        pCanvas.renderAll();
        
        if(element.originalClipPath !== null){
          element.clipPath = element.originalClipPath;
        }else{
          element.clipPath = null;
        }
        element.dirty = true;
        pCanvas.renderAll();
      } else if(object.widgetType === "free_text" && object.type !== "text-curved") {
        const width = object.width * realScale;
        const height = object.height * realScale;
        
        const strokeWidth = parseInt(object.fontSize * realScale / 15);
        
        let element = new fabric.Text(object._text.join(''), {
          ...option,
          width: width,
          height: height,
          stroke: object.stroke,
          fill: object.fill,
          //strokeWidth: 3 * realScale,
          strokeWidth: strokeWidth,
          paintFirst: "stroke",
          affectStroke: true,
          strokeUniform: true,
          fontFamily: object.fontFamily,
          fontStyle: object.fontStyle,
          fontWeight: object.fontWeight,
          fontSize: object.fontSize * realScale,
          textAlign: object.textAlign,
          lineHeight: object.lineHeight,
        });
        /*
        const shadow = new fabric.Shadow({
          color: object.shadowColor,
          offsetX: 1 * realScale,
          offsetY: 1 * realScale,
          blur: parseInt(2 * realScale)
        });
        */
        const shadow = new fabric.Shadow({
          color: object.shadowColor,
          offsetX: object.fontSize * realScale / 40,
          offsetY: object.fontSize * realScale / 40,
          blur: parseInt(object.fontSize * realScale / 20)
        });
        
        element.set({shadow: shadow});
        
        pCanvas.add(element);
      } else if(object.widgetType === "free_text" && object.type === "text-curved"){
        const width = object.width * realScale;
        const height = object.height * realScale;
        const diameter = object.diameter * realScale;
        let element = new fabric.TextCurved(object.text, {
          ...option,
          width: width,
          height: height,
          diameter: diameter,
          flipped: object.flipped,
          stroke: object.stroke,
          fill: object.fill,
          strokeWidth: 3 * realScale,
          paintFirst: "stroke",
          shadow: object.shadow,
          affectStroke: true,
          strokeUniform: true,
          fontFamily: object.fontFamily,
          fontStyle: object.fontStyle,
          fontWeight: object.fontWeight,
          fontSize: object.fontSize * realScale,
          textAlign: object.textAlign
        });
        
        const shadow = new fabric.Shadow({
          color: object.shadowColor,
          offsetX: 1 * realScale,
          offsetY: 1 * realScale,
          blur: parseInt(2 * realScale)
        });
        element.set({shadow: shadow});
        
        pCanvas.add(element);
      }else if(object.widgetType === "group_watermark" && canvasInfo.model.watermarkOnCartRendering){
        /*
        for (let i = 0; i < object._objects.length; i++) {
          const objectGroup = object._objects[i];
          let element = new fabric.Image(objectGroup._element, {
            ...option,
            top: objectGroup.originalTop * realScale,
            left: objectGroup.originalLeft * realScale,
            opacity: 0.16,
            width: objectGroup.width,
            height: objectGroup.height,
            scaleX: objectGroup.scaleX * realScale,
            scaleY: objectGroup.scaleY * realScale,
            cropX: objectGroup.cropX,
            cropY: objectGroup.cropY
          }, { crossOrigin: 'Anonymous' });

          pCanvas.add(element);
        }
        */
      }
    }
  });
  
  pCanvas.renderAll();
  
  /*
  if(document.querySelector('#div-tmp') == null){
    document.querySelector('#js-configurator').insertAdjacentHTML("afterbegin", "<div id='div-tmp'><div id='print-canvas'></div><div id='cart-canvas'></div></div>");
  }
  */
  
  if(type === "print") {
    const imgUrl = pCanvas.toDataURL({
      format: "png",
      left: clipObject.left * realScale,
      top: clipObject.top * realScale,
      width: clipObject.width * realScale,
      height: clipObject.height * realScale,
    });
    
    return imgUrl;
  }
  if(type === "cart"){
    //document.querySelector('#cart-canvas').innerHTML = "";
    const imgUrl = pCanvas.toDataURL({
      format: "png",
//      format: "jpeg",
      quality: 0.5
    });
    
    // const imageHtml = "<img src='" + imgUrl + "' />";
    // document.querySelector('#cart-canvas').innerHTML = imageHtml;
    
    return imgUrl;
  }
}

// FIN TEST

function updateBundleImage(item) {
  // trouver la bonne image
  let bundleItemImage = null;
  document.querySelectorAll('#product-bundle-items-images > .bundle-items-details').forEach(bundleItemDetails=> {
    if (item.hasAttribute('data-variantid')) {
      if (bundleItemDetails.dataset.variantId === item.dataset.variantid) {
        bundleItemImage = bundleItemDetails.dataset.variantImage;
      }
    }
    else if (item.hasAttribute('data-variant-id')) {
      if (bundleItemDetails.dataset.variantId === item.dataset.variantId) {
        bundleItemImage = bundleItemDetails.dataset.variantImage;
      }
    }
    
  })
  if (bundleItemImage) {
    document.querySelector('#js-no-configurator .img-bit-article > a > #big').src = bundleItemImage;
  }
}

function hideAddToCartBtn() {
  document.getElementById('btn-next-add-panier').style.visibility = "hidden";
}

function alertTextModified(target, text){
  let content = `Cette zone de texte est complète. (au delà cela serait peu lisible sur le produit final). Voici ci-dessous le contenu retenu :<br><strong>${text.replace(/\n/g, "<br />")}</strong><br>Si le texte n'est pas complet, reformulez le pour faire apparaitre les éléments importants.`;
  
  if(!document.querySelector('.alert-text-modified')){
    const html = `  <div class="wrap-alert-text-modified">
                        <div class="alert-text-modified">
                            <span class="alert-text-close">x</span>
                            <div class="alert-text-content">
                            ${content}
                            </div>
                            <div class="d-flex justify-content-center">
                            <span class="alert-text-btn-valid">OK</span>
                            </div>
                         </div>
                    </div>`;
    document.querySelector("body").insertAdjacentHTML("beforeend", html);
    
    document.querySelector(".alert-text-close").addEventListener('click', (e) => {
      document.querySelector('.wrap-alert-text-modified').remove();
      target.focus();
    });
    
    document.querySelector(".alert-text-btn-valid").addEventListener('click', (e) => {
      document.querySelector('.wrap-alert-text-modified').remove();
      target.focus();
    });
  }else{
    document.querySelector(".alert-text-content").innerHTML = content;
  }
}

function alertTextEnter(target, maxlines){
  const currentValue = target.value;
  let content = `Vous avez droit à ${maxlines} lignes au maximum.<br>Voulez vous prendre en compte ce passage à la ligne?<br>Cela supprimera votre dernière ligne.`;
  
  if(!document.querySelector('.alert-text-enter')){
    const html = `  <div class="wrap-alert-text-enter">
                        <div class="alert-text-enter">
                            <span class="alert-text-enter-close">x</span>
                            <div class="alert-text-enter-content">
                                ${content}
                            </div>
                            <div class="d-flex justify-content-center">
                              <span class="alert-text-enter-valid">OK</span>
                              <span class="alert-text-enter-reset">Annuler </span>
                            </div>
                         </div>
                    </div>`;
    document.querySelector("body").insertAdjacentHTML("beforeend", html);
    
    document.querySelector(".alert-text-enter-close").addEventListener('click', (e) => {
      document.querySelector('.wrap-alert-text-enter').remove();
      target.focus();
      target.setSelectionRange(cursorPosition, cursorPosition);
    });
    
    document.querySelector(".alert-text-enter-reset").addEventListener('click', (e) => {
      document.querySelector('.wrap-alert-text-enter').remove();
      target.focus();
      target.setSelectionRange(cursorPosition, cursorPosition);
    });
    
    document.querySelector(".alert-text-enter-valid").addEventListener('click', (e) => {
      document.querySelector('.wrap-alert-text-enter').remove();
      var newValue = [currentValue.slice(0, cursorPosition), "\n", currentValue.slice(cursorPosition)].join('');
      target.value = newValue;
      target.dispatchEvent(new Event('input'));
      target.focus();
      const currentCursorPosition = cursorPosition + 1;
      target.setSelectionRange(currentCursorPosition, currentCursorPosition);
    });
  }else{
    document.querySelector(".alert-text-enter-content").innerHTML = content;
  }
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export {checkIfOnUpdatedProduct, updateCanvasModel, updateModel, clearConfigurator, generatePrintableCanvas, handleCustomization, checkItemValid, handleClickBack, updateBundleImage}
