import $ from 'jquery';

$(document).ready(function() {

  $(".custom-file-input").each(function(){
    $(this).next(".custom-file-label").text('Ajouter des pièces jointes');
  });
  $(".custom-file-input").change(function(){
    $(this).next(".custom-file-label").text(this.files[0].name);
  });
  /*********FILTRE CATEG*****************/
  $('body').on('click', '.mobile-label-filtre', function(){

    $(this).toggleClass("active");
    $('.cell-facette').fadeIn();
    $('body').toggleClass('no-scrolling');
  });
	$('body').on('click', '.close-filtre-categ', function(){
		$('body').removeClass('no-scrolling');
	});
	$('body').on('submit', '.content-fitre-categ > form', function(){
		$('body').removeClass('no-scrolling');
	});
  $('body').on('click', '.close-filtre-categ', function(){
    $('.cell-facette').fadeOut();
    $('body').removeClass('no-scrolling');
  });
  $('body').on('click', '.select-f', function(event){
    var thisAnchor = $(this);
    var tab = thisAnchor.parents('.content-fitre-categ');
    tab.find('.filtreContenu').slideUp();
    tab.children().children('.filtre-c-bit').find('.select-f').removeClass('active');

    if(thisAnchor.next('.filtreContenu').css('display') !== 'none'){
      thisAnchor.removeClass('active');
      thisAnchor.removeClass('has-selection');
      thisAnchor.next('.filtreContenu').slideUp();
    }
    else{
      tab.children().children('.filtre-c-bit').find('.select-f').removeClass('active');
      tab.children().children('.filtre-c-bit').find('.select-f').removeClass('has-selection');
      thisAnchor.siblings('.filtreContenu').slideDown();
      thisAnchor.addClass('active');

    }
  });
	$(".click-search").click(function(e){
		e.preventDefault();
		$('.center-search').toggleClass('open-s');
		$('.center-search').parent().parent().toggleClass('open-parent');
	});
	$(".search-link").click(function(e){
		e.preventDefault();
		$('.col-parent-search').toggleClass('open-sticky');

	});
	
	$(".search-link-mobile").click(function(e){
		e.preventDefault();
		$('.col-parent-search').addClass('open');
		$('#header').addClass('zindex_big');
		$('body').addClass('no-show');

	});
	$(".close-search-mobile").click(function(){
		$('.col-parent-search').removeClass('open');
		$('#header').removeClass('zindex_big');
		$('body').removeClass('no-show');


	});
	/*** Header sticky **/
	$(function () {
	  var msie6 = $.browser == 'msie' && $.browser.version < 7;

	  if (!msie6) {
		var top = $('.header-body').offset().top - parseFloat($('.header-body').css('margin-top').replace(/auto/, 0));
		$(window).scroll(function (event) {
		  // what the y position of the scroll is
		  var y = $(this).scrollTop();

		  // whether that's below the form
		  if (y > top) {
			// if so, ad the fixed class
			$('.header-body').addClass('headerFixed');
			$('.big-wrapper').addClass('padding-body');
		  } else {
			// otherwise remove it
			$('.header-body').removeClass('headerFixed');
			$('.big-wrapper').removeClass('padding-body');
		  }
		});
	  }
	});

	 /********SUB MENU OVERLAY******/
	 $(".navItem").each(function() {
           if ($(this).children('.navLink').next('.subMenu').length){
			$(this).addClass('has-sub-item');
		}
	});
	 if ($(window).width() > 993) {
		var timeoutId ;
		var $navOverlay_ = $('<div class="menu-overlay">');
		$('.navItem').hover(function () {
				var $this = $(this);
			if ($this.find('.subMenu').length) {
				timeoutId = setTimeout(function(){
				$this.find('.navLink').addClass('active');

				$('.header-body').addClass('relative');
				$(document.body).append($navOverlay_);
				}, 250);
			}
		}, function () {


			if ($(this).find('.subMenu').length) {
				clearTimeout(timeoutId);
				$(this).find('.navLink').removeClass('active');

				if ($(document.body).find($navOverlay_)) {
					$(document.body).find($navOverlay_).remove();
					$('.header-body').removeClass('relative');
				}
			}
		});
	 }

		$(".navigation-site-mobile .navItem").each(function () {
	      if ($(this).children('.navLink').next('.subMenu').length == 1) {
	          $(this).addClass('has-sub');
	          $(this).children(".navLink").click(function (e) {
	              e.preventDefault();
	              $(this).next('.subMenu').addClass('open');
	              $('.navigation-site').addClass('remove_mob_parent');
	              setTimeout(function () {
	                  $('body, html, .scroll-me-mobile_').scrollTop(0);
	              }, 100);

	          });
	      }
	      $('.back_menu').click(function (e) {
	          $('.subMenu').removeClass('open');
	          $('.navigation-site').removeClass('remove_mob_parent');
	          $('.subMenu').parent('.navItem').removeClass('active');
	      });
	  });


	  /** Scroll top Top **/
		$(window).scroll(function () {
			if ($(this).scrollTop() > 600) {
				$('.goToTop').fadeIn();
			} else {
				$('.goToTop').fadeOut();
			}
		});
		$('.goToTop').click(function () {
			$("html, body").animate({
				scrollTop: 0
			}, 600);
			return false;
		});



	 /******* MENU TOOGLE *********/

	 $('.toggle-menu').click(function(e){
			 e.preventDefault();
			 $('.navigation-site').toggleClass('open-mob');
			  $('.menu-mob-overlay').fadeToggle();
			   $('body').toggleClass('no-scrolling');

		});
		$('.close-menu-mob').click(function(e){
			 e.preventDefault();
			 $('.navigation-site').removeClass('open-mob');
			 $('.menu-mob-overlay').fadeOut();
			  $('body').toggleClass('no-scrolling');
			  $('body').removeClass('no-show');

			$('.subMenu').removeClass('open');
			$('.navigation-site').removeClass('remove_mob_parent');
			$('.subMenu').parent('.navItem').removeClass('active');
		});
		$('.menu-mob-overlay').click(function(e){
			 e.preventDefault();
			$('.navigation-site').removeClass('open-mob');
			$('.menu-mob-overlay').fadeOut();
			$('body').toggleClass('no-scrolling');
			$('.subMenu').removeClass('open');
			$('.navigation-site').removeClass('remove_mob_parent');
			$('.subMenu').parent('.navItem').removeClass('active');
			$('body').removeClass('no-show');
		});
		/********MENU FOOTER TOGGLE********/

			 $('.toogle-menu-mob').click(function(){
				 $('.other-menu').slideToggle();
			});

			$('.toggle-me').click(function(){
				var thisAnchor = $(this);
				var tab = thisAnchor.parents('.bloc-footer-menu');
				if(thisAnchor.next('.toggle-m-c ').css('display') !== 'none'){
				thisAnchor.removeClass('open');
				thisAnchor.next('.toggle-m-c').slideUp();

				}
				else{
				tab.find('.toggle-m-c').slideUp();
				tab.find('.toggle-me').removeClass('open');
				thisAnchor.siblings('.toggle-m-c ').slideDown();
				thisAnchor.addClass('open');}

			});
	   /*******TAB ARTICLE *************/

		$('.toggle-tab').click(function(){
			var thisAnchor = $(this);
			var tab = thisAnchor.parents('.tab-article-content');


			tab.find('.toggle-content-tab').slideUp();

			if(thisAnchor.next('.toggle-content-tab').css('display') !== 'none'){
			thisAnchor.removeClass('active');
			thisAnchor.next('.toggle-content-tab').slideUp();

			}
			else{
			tab.children().find('.toggle-tab').removeClass('active');
			thisAnchor.siblings('.toggle-content-tab').slideDown();
			thisAnchor.addClass('active');


			}


		});

	$('.bg-play').click(function(){
		$("#video-index")[0].src += "&autoplay=1";
		$('.bg-play').hide();
	});


	/***WOW****/
	// var wow = new WOW(
  // {
  //   boxClass:     'wow',
  //   animateClass: 'animated',
  //   offset:       0,
  //   mobile:       false,
  //   live:         true,
  //   callback:     function(box) {
  //   },
  //   scrollContainer: null
	//   }
	// );
	// wow.init();


/********TAB**********/
var triggerTabList = [].slice.call(document.querySelectorAll('#TabDescription a'))
triggerTabList.forEach(function (triggerEl) {
  var tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
	event.preventDefault()
	tabTrigger.show()
  })
});


  /*********PANIER DEROULANT*****************/
  $('.li-panier').hover(function () {
		$(".panier-deroulant").toggleClass('focus');
	});

  $('.toggle-fav').click(function(e){
    e.preventDefault();
    var $this = $(this);

    if($this.hasClass("addfav-link")){
      //favoris animate
      $this.parent().find('.c1-a').addClass('animate1_me');
      $this.parent().find('.c2-a').addClass('animate2_me');
      $this.addClass('myfavoris');
      $this.removeClass('addfav-link');
    }else{
      $this.parent().find('.c1-a').removeClass('animate1_me');
      $this.parent().find('.c2-a').removeClass('animate2_me');
      $this.removeClass('myfavoris');
      $this.addClass('addfav-link');
    }

  });

  $('.addfav-link').click(function(){
    var id = $(this).attr('data-option');
    $.ajax({
        url: '/wishlist/add/' + id,
        type: 'POST',
        success:function(){
        },
        error:function(){

        }
    })
  });



  $('.card-header').click(function(){
    var thisA = $(this);
    var tabA = thisA.parents('#accordion');
    if(thisA.next('.collapse').css('display') !== 'none'){
      thisA.removeClass('open');
      thisA.next('.collapse').slideUp();
    }
    else{
      tabA.find('.collapse').slideUp();
      tabA.find('.card-header').removeClass('open');
      thisA.siblings('.collapse').slideDown();
      thisA.addClass('open');}
  });


	$('#addToCartModal').on('hidden.bs.modal', function () {
		// Code à exécuter après la fermeture de la modal
		location.replace(location.pathname);
		console.log('La modal a été fermée');
	});


});

