import $ from 'jquery';

$('body').on('click', '.app-cart-remove-button', (event) => {
  const url = event.currentTarget.getAttribute('data-js-remove-item-ajax');
  const csrfToken = event.currentTarget.getAttribute('data-js-remove-item-ajax-csrf-token');
  $.ajax({
    url: url,
    type: "DELETE",
    data: { _csrf_token: csrfToken },
    success(response) {
      $.ajax({
        url: '/app_summary',
        type: "GET",
        success: function (data) {
          $("#contenuPanierCommunWidget")
            .html(data);
        },
        error: function () {
          console.log("error popup");
        }
      });

      $.ajax({
        url: '/app_summary_popup',
        type: "GET",
        success: function (data) {
          $("#contenuPanierCommunPopup")
            .html(data);
        },
        error: function () {
          console.log("error popup");
        }
      });
    },
    error() {
      $('form [name="sylius_checkout_address[shippingAddress][city]"]').attr("is-invalid");
    },
  });

});
