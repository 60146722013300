/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

import { checkIfOnUpdatedProduct, updateCanvasModel, updateModel, clearConfigurator, handleCustomization, checkItemValid, handleClickBack, updateBundleImage} from './configurator.js';

let productOptionsLoading = false;
let globalDetailsElements = [];

function minimalQuantity() {
    let input;
    if (this.id === "sylius_add_to_cart_cartItem_quantity")
        input = this;
    else {
        input = this.closest('.qte-panier').querySelector('input');
    }

    let selector = '';
    input.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
        const select = element;
        const option = select[select.selectedIndex].value;
        selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
        select.addEventListener('change', function () {
            document.querySelector('[name="sylius_add_to_cart[cartItem][quantity]"]').dispatchEvent(new Event('change'));
        })
    });

    let detailsElement = null;
    if (selector !== "") {
      detailsElement = document.querySelector('#sylius-variants-details').querySelector(selector);
    }
    else {
      detailsElement = document.querySelector('#sylius-variants-details > div')
    }
    if (detailsElement !== null) {
        let minimalQuantity = detailsElement.dataset.minimalQuantity;
        if (minimalQuantity && minimalQuantity !== "null") {
            if (input.value < parseInt(minimalQuantity)) {
                input.value = parseInt(minimalQuantity);
            }
        }
    }
}

const handleProductOptionsChange = function handleProductOptionsChange() {
  //if(document.querySelector("#sylius-product-adding-to-cart")){
    document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
      item.addEventListener('change', (event) => {
        if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length === 2) {
          disableIncompatibleVariants(event.target);
        }
        else if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 2) {
          stepByStepProductOptions(event.target);
          //todo: enlever le prix et indiquer qu'il fautremplir les sélect
          document.querySelector('[data-js-product-price]').classList.add('d-none');
          document.getElementById('choose-your-option-info-msg').classList.remove('d-none');
        }
        let selector = '';
        event.target.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
          const select = element;
          if (select[select.selectedIndex]) {
            const option = select[select.selectedIndex].value;
            selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
          }
        
        });
      
        const priceElement = document.querySelector('#sylius-variants-pricing').querySelector(selector);
        let price = '';
        let originalPrice = '';
        let percentage = '';
        if (priceElement !== null) {
          price = priceElement.getAttribute('data-value');
          originalPrice = priceElement.getAttribute('data-original-price');
          percentage = priceElement.getAttribute('data-percentage');
        }
      
        if (price !== '') {
          document.querySelector('[data-js-product-price]').innerHTML = price;
          document.querySelector('button[type=submit]').removeAttribute('disabled');
          document.querySelector('[data-js-product-price]').classList.remove('d-none');
          if (!document.getElementById('choose-your-option-info-msg').classList.contains('d-none')) {
            document.getElementById('choose-your-option-info-msg').classList.add('d-none');
          }
        } else {
          document.querySelector('[data-js-product-price]').innerHTML = document.querySelector('#sylius-variants-pricing').getAttribute('data-unavailable-text');
          document.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
        }
      
        if(originalPrice !== '' && price !== ''){
          document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;
        }else {
          document.querySelector('[data-js-product-original-price]').innerHTML = '';
        }
      
        if(percentage !== null && price !== ''){
          if(document.querySelector('[data-js-product-percentage]') != null){
            document.querySelector('[data-js-product-percentage]').innerHTML = percentage + '%';
          }
        
          if (document.querySelector('.picto-promo'))
            document.querySelector('.picto-promo').style.display = 'inline-block';
        }else{
          if(document.querySelector('.picto-promo')){
            document.querySelector('.picto-promo').style.display = 'none';
          }
          if(document.querySelector('[data-js-product-percentage]')){
            document.querySelector('[data-js-product-percentage]').innerHTML = '';
          }
        }
      
        const detailsElement = document.querySelector('#sylius-variants-details').querySelector(selector);
        let variantDescription = '';
        let tracked = '';
        let onHand = '';
        let onHold = '';
        let variantId = '';
        let stockFamily = '';
        let stockFamilyHas = false;
        let stockFamilyOnHand = '';
        let stockFamilyOnHold = '';
        if (detailsElement !== null) {
          variantDescription = detailsElement.getAttribute('data-description');
          tracked = detailsElement.getAttribute('data-tracked');
          onHand = detailsElement.getAttribute('data-onHand');
          onHold = detailsElement.getAttribute('data-onHold');
          variantId = detailsElement.getAttribute('data-variantId');
          stockFamily = detailsElement.getAttribute('data-stock-family');
          if(detailsElement.getAttribute('data-stock-family-has') == 1){
            stockFamilyHas = true;
          }
          stockFamilyOnHand = detailsElement.getAttribute('data-stock-family-onHand');
          stockFamilyOnHold = detailsElement.getAttribute('data-stock-family-onHold');
          $('#enStock').removeClass("d-none");
          $('#horsStock').removeClass("d-none");
        }
        // console.log("tracked", tracked)
        // console.log("onHand", onHand)
        // console.log("onHold", onHold)
        // console.log("stockFamily", stockFamily)
        // console.log("stockFamilyHas", stockFamilyHas)
        // console.log("stockFamilyOnHand", stockFamilyOnHand)
        // console.log("stockFamilyOnHold", stockFamilyOnHold)
        // console.log("productOptionsLoading", productOptionsLoading)
        if((stockFamilyHas && stockFamilyOnHand - stockFamilyOnHold < 1) || (!stockFamilyHas && tracked == 1 && onHand - onHold < 1) && (!productOptionsLoading)){
        //if(((tracked == 1 && onHand < 1) || (stockFamily !== "null" && stockFamily < 1)) && (!productOptionsLoading)){
          $('#enStock').hide();
          $('.btn-panier-content').hide();
          $('#horsStock').show();
          $('#availability-notifier').removeClass("d-none");
          if(document.querySelector(".wrap-btn-start-customization")){
            document.querySelector(".wrap-btn-start-customization").classList.add("d-none");
          }
          document.forms['app_availability_notifier'].action="/shop/availability_notifier/add-availability-notifier?productVariantId="+variantId+"&template=@SyliusShop/Product/Show/_availability_notifier.html.twig";
        }else{
          $('#enStock').show()
          $('#horsStock').hide();
          $('#variantQte').innerHTML=onHand;
          $('.btn-panier-content').show();
          $('#availability-notifier').addClass("d-none");
          if(document.querySelector(".wrap-btn-start-customization")){
            document.querySelector(".wrap-btn-start-customization").classList.remove("d-none");
          }
        }
      
      
        if(document.querySelector('#canvas-wrap') !== null && detailsElement !== null) {
          if(parseInt(detailsElement.getAttribute("data-modelId")) > 0){
            globalDetailsElements = [detailsElement];
            document.querySelector("#btn-start-customization").classList.remove("d-none");
            document.querySelector("#add-to-cart-btn").classList.add("d-none");
            document.querySelector('.btn-panier').setAttribute('disabled', '');
            document.querySelector('.btn-panier').classList.add('disabled');
  
            const modelAdvanced = detailsElement.getAttribute('data-advanced');
            //console.log("log1", modelAdvanced)
            if(modelAdvanced != 0){
              document.querySelector('.art-qte').classList.add('d-none');
              document.querySelector('.art-qte').closest('.quantity-container').classList.add('d-none');
            }
          
            if(document.querySelector('#content-qte-tmp')){
              document.querySelector('#content-qte-tmp').classList.add('d-none');
            }
          }else{
            globalDetailsElements = [];
            if (document.querySelector("#btn-start-customization")) {
              document.querySelector("#btn-start-customization").classList.add("d-none");
            }
            document.querySelector('.btn-panier').removeAttribute('disabled');
            document.querySelector('.btn-panier').classList.remove('disabled');
            document.querySelector('.btn-panier').classList.remove('d-none');
            document.querySelector('.art-qte').classList.remove('d-none');
            
            if(document.querySelector('.quantity-container')) {
              document.querySelector('.quantity-container').classList.remove('d-none');
            }
          
            if(document.querySelector('#content-qte-tmp')){
              document.querySelector('#content-qte-tmp').classList.remove('d-none');
            }
          
            if (document.querySelector("#input-qte-tmp")) {
              const inputQuantity = document.querySelector('.sylius-quantity input[type="number"]');
              document.querySelector("#input-qte-tmp").value = inputQuantity.value;
            }
          }
        } else{
          if (document.querySelector("#btn-start-customization")) {
            document.querySelector("#btn-start-customization").classList.add("d-none");
          }
          globalDetailsElements = [];
          document.querySelector('.btn-panier').removeAttribute('disabled');
          document.querySelector('.btn-panier').classList.remove('disabled');
          document.querySelector('.art-qte').classList.remove('d-none');
        
          if(document.querySelector('#content-qte-tmp')){
            document.querySelector('#content-qte-tmp').classList.remove('d-none');
          }
        
          if (document.querySelector("#input-qte-tmp")) {
            const inputQuantity = document.querySelector('.sylius-quantity input[type="number"]');
            document.querySelector("#input-qte-tmp").value = inputQuantity.value;
          }
        }
      
      });
    
    });
  //}
};

// VARIANTS INCOMPATIBLES (dans le cas de 2 product options)
const disableIncompatibleVariants = (select) => {
  let selectValue = select.value;
  let selector = `[data-${select.getAttribute('data-option')}="${selectValue}"]`;
  select.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => { // tous les select (de product option)

    if (select !== element) { // faire le test sur tous les select sauf sur celui sur lequel est l'événement
      let firstValid = null;
      let changeValue = false;
      element.querySelectorAll('option').forEach(option => {
        let newSelector = selector;
        newSelector += `[data-${element.getAttribute('data-option')}="${option.value}"]`;
        // rechercher le variant qui correspond à cette combinaison
        let variantElement = document.querySelector('#sylius-variants-pricing').querySelector(newSelector);
        if (variantElement) {
          option.style.display = "block";
          if (!firstValid) {
            firstValid = option;
          }
        }
        else { // le variant n'existe pas
          option.style.display = "none";
          if (element.value === option.value) {
            changeValue = true;
          }
        }

      })
      // remplacer la valeur affichée si celle qui l'était n'est plus disponible
      if (changeValue) {
        firstValid.setAttribute("selected", true)
        element.value = firstValid.value;
      }
    }

  });
}

// VARIANTS INCOMPATIBLES (à partir de 3 product options)
const stepByStepProductOptions = (currentSelect, onPageLoaded = false) => {
  productOptionsLoading = true;
  let index = null;
  let chooseText = document.getElementById('sylius-variants-pricing').dataset.chooseOptionText;
  for (let i = 0; i < document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length; i++) {
    let select = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[i];

    // find index | trouver l'index du select en question
    if (select === currentSelect) {
      index = i;
    }

    // greater indexes should be disabled | tous les select avec un index supérieur doivent être disabled
    if (typeof index != null && index != null && i > index) {
      deselectAllOptions(select);
      select.setAttribute("disabled", true)
    }

    // create if not exists a placeholder to force to choose in the list | mettre un placeholder pour obliger le client à choisir parmi la liste, s'il n'existe pas déjà
    let placeholder = null;
    select.querySelectorAll('option').forEach(option => {
      if (option.value === "") {
        placeholder = option;
      }
    })
    if (!placeholder) {
      placeholder = document.createElement('option');
    }
    placeholder.value = "";
    if (select === currentSelect) {
      placeholder.textContent = chooseText;
      if (onPageLoaded) {
        placeholder.setAttribute("selected", true)
      }
    }
    else {
      placeholder.setAttribute("selected", true)
    }
    placeholder.setAttribute("disabled", true)
    select.prepend(placeholder);
  }

  // able next select once the choice is made | une fois que le choix est fait sur ce select, rendre disponible le select suivant etc.
  if (currentSelect.value && currentSelect.value !== "") {
    document.getElementById('step-by-step-info-msg').style.display = "block";
    document.getElementById('add-to-cart-btn').setAttribute("disabled", true);
    let nextSelect = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[index + 1];
    if (nextSelect) {
      deselectAllOptions(nextSelect);
      let nextSelectPlaceholder = null;
      nextSelect.removeAttribute("disabled");
      nextSelect.querySelectorAll('option').forEach(option => {
        if (option.value === "") {
          nextSelectPlaceholder = option;
        }
      })
      nextSelectPlaceholder.textContent = chooseText;
      nextSelectPlaceholder.setAttribute("selected", true);

      // only show  n'afficher que les options disponibles sue le select suivant
      let selector = getSelector(index);
      let elements = document.querySelector('#sylius-variants-pricing').querySelectorAll(selector);
      let optionValues = [];
      elements.forEach(element=>{
        optionValues.push(element.getAttribute("data-" + nextSelect.dataset.option.toLowerCase()));
      })
      // array unique
      optionValues = optionValues.filter(function (value, index) {
        return optionValues.indexOf(value) === index;
      });
      nextSelect.querySelectorAll('option').forEach(option => {
        if (option.value === "" || optionValues.includes(option.value)) {
          option.style.display = "block";
        }
        else {
          option.style.display = "none";
        }
      })
    }
    else {
      // cette variable sert à savoir si on doit afficher une rupture de stock ou un message affirmant qu'il faut choisir toutes les options avant d'ajouter au panier
      document.getElementById('step-by-step-info-msg').style.display = "none";
      document.getElementById('add-to-cart-btn').removeAttribute("disabled");
      productOptionsLoading = false;
    }

  }

}

// get selector to find options
function getSelector(index) {
  let selector = "";

  for (let i = 0; i <= index; i++) {
    let select = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[i];
    const option = select[select.selectedIndex].value;
    selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
  }
  return selector;
}

// remove selected attribute from all select options | enlever l'attribut selected sur toutes les options du select
function deselectAllOptions(select) {
  select.querySelectorAll('option').forEach(option => {
    option.removeAttribute('selected');
  })
}

const handleProductVariantsChange = function handleProductVariantsChange() {
    document.querySelectorAll('[name="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
        item.addEventListener('change', (e) => {

            const price = e.target.getAttribute('data-value');
            const originalPrice = e.target.getAttribute('data-original-value');
            const percentage = e.target.getAttribute('data-percentage');
            const stock = e.target.hasAttribute('data-stock');
            if (originalPrice !== '') {
                document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;
            } else {
                document.querySelector('[data-js-product-original-price]').innerHTML = '';
            }
            if (percentage !== null) {
                document.querySelector('[data-js-product-percentage]').innerHTML = `${percentage}`;
                document.querySelector('.picto-promo').style.display = 'inline-block';
            } else {
                document.querySelector('.picto-promo').style.display = 'none';
                document.querySelector('[data-js-product-percentage]').innerHTML = '';
            }
            if (stock) {
                document.getElementById('enStock').classList.remove('d-none');
                document.getElementById('horsStock').classList.add('d-none');
            } else {
                document.getElementById('enStock').classList.add('d-none');
                document.getElementById('horsStock').classList.remove('d-none');
            }

            document.querySelector('[data-js-product-price]').innerHTML = price;
        });
    });
};

const SyliusVariantsPrices = () => {
    const syliusVariantsPricing = document.getElementById('sylius-variants-pricing') || null;
    const syliusProductVariants = document.getElementById('sylius-product-variants') || null;

    if (syliusVariantsPricing) {
        handleProductOptionsChange();
    } else if (syliusProductVariants) {
        handleProductVariantsChange();
    }


    if(document.querySelector('#canvas-wrap') !== null) {
        setTimeout(function () {
            const element = document.querySelector('[name*="sylius_add_to_cart[cartItem][variant]"]');
            if(element !== null){
                element.dispatchEvent(new Event("change"));
            }
        }, 5);
    }
};

/*
 * Get data Element info and update the canvas
 */
const initDataModelConfigurator = (detailsElements) => {
  document.querySelector(".products-bundles").innerHTML = "";
  document.querySelector(".products-bundles").setAttribute("data-bundle", 0);
  document.querySelector(".products-bundles").setAttribute("data-init", 1);
  let count = 0;
  let countModels = 0;
  let enableHandleCustomization = false;
  let displayCartButton = true;
  detailsElements.forEach((detailsElement) => {
    count++;
    let dataModel = {};
    let dataElements = [];
    if (detailsElement !== null) {
      if(detailsElements.length > 1){
        const modelId = parseInt(detailsElement.getAttribute('data-modelId'));
        if(modelId > 0){
          countModels++;
          if(countModels > 1){
            displayCartButton = false;
          }
          const variantId = detailsElement.getAttribute('data-variantId');
          const itemId = detailsElement.getAttribute('data-itemId');
          const variantName = detailsElement.getAttribute('data-variantName');
          const modelAdvanced = detailsElement.getAttribute('data-advanced');
          const activeClass = count == 1 ? 'active' : '';
          document.querySelector(".products-bundles").classList.remove('d-none');
          document.querySelector(".products-bundles").classList.add('d-flex');
          const selectProductBundle = `<span class="select-product-item ${activeClass}" data-itemId="${itemId}" data-variantId="${variantId}" data-modelId="${modelId}" data-name="${variantName}" data-advanced="${modelAdvanced}" data-valid="0"><span class="for-desktop" >${count}</span><span class="for-mobile" >${countModels}</span></span>`;
          document.querySelector(".products-bundles").insertAdjacentHTML("beforeend", selectProductBundle);
          document.querySelector(".products-bundles").setAttribute("data-bundle", 1);
        }
        document.querySelector('.product-bundle-name').innerHTML = detailsElements[0].dataset.variantname;
      }

      // check inventory
      let inStock = true;
      let onHand = detailsElement.dataset.onhand === "0" ? 0 : parseInt(detailsElement.dataset.onhand);
      let onHold = detailsElement.dataset.onhold === "0" ? 0 : parseInt(detailsElement.dataset.onhold);
      let quantity = onHand - onHold;
      // dans le cas d'une configuration, si on a pu cliquer sur le bouton personnaliser c'est que le produit est en stock
      if (detailsElement && !detailsElement.dataset.bundle) {
        if ((parseInt(detailsElement.dataset.tracked) === 1 && quantity <= 0 && detailsElement.dataset.stockFamily === "null") ||
            (parseInt(detailsElement.dataset.tracked) === 1 && quantity < parseInt(detailsElement.dataset.minimalQuantity) && detailsElement.dataset.stockFamily === "null") ||
            (detailsElement.dataset.stockFamily === "0")) {
            inStock = false;
        }
      }

      dataModel = JSON.parse(detailsElement.getAttribute('data-model'));
      if (dataModel) {

          // dans le cas d'un bundle avece configuration simple
          if (document.getElementById('product-bundle-items-images')) {

          }

        dataModel.lineSpace = 1;
        dataModel.allowLineSpaceChange = true;
        if(count >= 2){
          dataModel.visible = false;
        } else {
          dataModel.visible = true;
        }
        dataModel.count = count;
        if('fonts' in dataModel){

        }else{
          dataModel.fonts = [];
        }
        if('fontColors' in dataModel){

        }else{
          dataModel.fontColors = [];
        }

        //strokeColors
        if (typeof dataModel.strokeColors !== 'undefined') {
          dataModel.strokeColors.unshift({
            colorName: "transparent",
            hexadecimal: "transparent"
          });
        }

        //shadowColors
        if (typeof dataModel.shadowColors !== 'undefined') {
          dataModel.shadowColors.unshift({
            colorName: "transparent",
            hexadecimal: "transparent"
          });
        }

        dataElements = JSON.parse(detailsElement.getAttribute('data-elements'));
        if (dataElements) {
          //const firstElement = dataElements.shift();
          dataElements.forEach((item) => {
            //strokeColors
            if (typeof item.strokeColors !== 'undefined') {
              item.strokeColors.unshift({
                colorName: "transparent",
                hexadecimal: "transparent"
              });
            }

            //shadowColors
            if (typeof item.shadowColors !== 'undefined') {
              item.shadowColors.unshift({
                colorName: "transparent",
                hexadecimal: "transparent"
              });
            }
          });

          if(dataModel.advanced && inStock){
            updateCanvasModel(dataModel, dataElements, inStock);
            enableHandleCustomization = true;
          }else{
            updateModel(dataModel, dataElements);
          }
        } else {
          clearConfigurator();
        }
      }
    }
  });

  if(enableHandleCustomization){
    handleCustomization(displayCartButton);
  }


  document.querySelectorAll(".select-product-item").forEach((item) => {
    item.addEventListener('click', (e) => {
      const currentItemId = document.querySelector(".select-product-item.active").getAttribute("data-itemid");
      const isInit = document.querySelector(".products-bundles").getAttribute("data-init") == 1 ? true : false;
      const nextValid = item.getAttribute("data-valid");
      if(document.querySelector('.widgets-not-valid')){
        document.querySelector('.widgets-not-valid').style.visibility = "hidden";
      }

      const isItemValid = checkItemValid(currentItemId, true);
      //const isItemValid = isInit ? checkItemValid(currentItemId, false) : checkItemValid(currentItemId, true);
      //document.querySelector(".products-bundles").setAttribute("data-init", 0);
      if(isItemValid || nextValid == 1/* || isInit*/){
        document.querySelector('.select-product-item[data-itemid="'+currentItemId+'"]').setAttribute("data-valid", 1);

        document.querySelector('.product-bundle-name').innerHTML = item.dataset.name;
        const modelId = item.getAttribute("data-modelId");
        const itemId = item.getAttribute("data-itemId");
        const variantid = item.getAttribute("data-variantid");
        const advanced = parseInt(item.getAttribute("data-advanced"));

        document.querySelectorAll(".select-product-item").forEach((productItemTab) => {
          productItemTab.classList.remove("active");
        });
        item.classList.add("active");

        if(item.nextElementSibling !== null){
          if (document.querySelector('#btn-next-product')){
            document.querySelector('#btn-next-product').classList.remove("d-none");
          }
          if (document.querySelector('#btn-next-add-panier'))
              document.querySelector('#btn-next-add-panier').classList.add("d-none");
          document.querySelector('.btn-panier').setAttribute('disabled', '');
          document.querySelector('.btn-panier').classList.add('disabled');
          if (document.querySelector('#price-mobile'))
            document.querySelector('#price-mobile').classList.add("d-none");
          if(document.querySelector('#content-qte-tmp')){
            document.querySelector('#content-qte-tmp').classList.add('d-none');
          }
        } else {
          if (document.querySelector('#btn-next-product')){
            document.querySelector('#btn-next-product').classList.add("d-none");
          }
          if (document.querySelector('#btn-next-add-panier')){
            document.querySelector('#btn-next-add-panier').classList.remove("d-none");
          }
          document.querySelector('.btn-panier').removeAttribute('disabled');
          document.querySelector('.btn-panier').classList.remove('disabled');
          document.querySelector('.prix-art-panier').classList.remove("d-none");
          if (document.querySelector('#price-mobile'))
            document.querySelector('#price-mobile').classList.remove("d-none");
          if(checkItemValid(itemId)){
            if (document.querySelector('#btn-next-add-panier')){
              document.querySelector('#btn-next-add-panier').classList.add("active");
            }
            if(document.querySelector('#content-qte-tmp')){
              document.querySelector('#content-qte-tmp').classList.remove('d-none');
            }
          }else{
            document.querySelector('#btn-next-add-panier').classList.remove("active");
          }
        }

        window.scrollTo({
          top: 0,
        });
        if(advanced == 1){
          document.querySelector("#js-no-configurator").classList.add("d-none");
          document.querySelector("#js-configurator").classList.remove("hide");

          document.querySelectorAll(".wrap-canvas").forEach((wrapCanvas) => {
            wrapCanvas.classList.add("hide");
          });
          document.querySelector("#wrap-canvas-" + itemId).classList.remove("hide");

          /*
          document.querySelector('body').classList.add('no-scrolling');
          if(!document.querySelector(".hide-header")) {
            document.querySelector('#header').insertAdjacentHTML("afterbegin", "<div class='hide-header'></div>");
          }
          */
          // configuration simple
        } else {
            // dans le cas d'un bundle, mettre la photo du variant du bundle item plutôt que la photo du bundle
            // chemin trouvable grâce à la div #product-bundle-items-images
            if (document.getElementById('product-bundle-items-images')) {
                updateBundleImage(item);
            }
          document.querySelector("#js-no-configurator").classList.remove("d-none");
          document.querySelector("#js-configurator").classList.add("hide");
          /*
          document.querySelector('body').classList.remove('no-scrolling');
          if(document.querySelector('.hide-header')){
            document.querySelector('.hide-header').remove();
          }
          */
        }

        document.querySelectorAll(".config-perso").forEach((configPerso) => {
          configPerso.classList.add("d-none");
        });
        if(document.querySelector("#config-perso-" + itemId) !== null){
          document.querySelector("#config-perso-" + itemId).classList.remove("d-none");
        }

        document.querySelectorAll(".free-element").forEach((freeElement) => {
          freeElement.classList.add("d-none");
        });
        if(document.querySelector("#free-element-" + itemId) !== null){
          document.querySelector("#free-element-" + itemId).classList.remove("d-none");
        }

        document.querySelectorAll('.free-box').forEach((element) => {
          element.classList.add('d-none');
        });
        if(document.querySelector('#free-box-' + itemId) !== null){
          document.querySelector('#free-box-' + itemId).classList.remove('d-none');
        }
      } else {
        if(document.querySelector('.widgets-not-valid')){
          document.querySelector('.widgets-not-valid').style.visibility = "visible";
        }
      }
    });
  });
};

document.addEventListener("DOMContentLoaded", function() {
  let globalDetailsElementUpdate = null;
  if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length === 2) {
    disableIncompatibleVariants(document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0]);
  } else if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 2) {
    stepByStepProductOptions(document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0], true);
  }

  let variantId = 0;
  if(document.querySelector('#canvas-wrap') !== null) {
    if(document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 0){
      document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0].dispatchEvent(new Event('change'));
        // todo: récupérer le détails element par le code du variant
        let url = new URL(window.location.href);
        let variantCode = url.searchParams.get('variantCode');

        // update configuration
        if (variantCode) {
            const detailsElements = document.querySelectorAll('#sylius-variants-details .variant-description[data-code="'+variantCode+'"]');
            detailsElements.forEach((detailsElement) => {
                globalDetailsElements.push(detailsElement);
            });
            globalDetailsElementUpdate = globalDetailsElements;
        }

    }else{
      const detailsElements = document.querySelectorAll("#sylius-variants-details .variant-description");
      if(detailsElements.length > 0){
        detailsElements.forEach((detailsElement) => {
          globalDetailsElements.push(detailsElement);
        });
        if (document.querySelector("#btn-start-customization")) {
            document.querySelector("#btn-start-customization").classList.remove("d-none");
            if (document.querySelector("#add-to-cart-btn"))
                document.querySelector("#add-to-cart-btn").classList.add("d-none");
        }
        if (document.querySelector('.art-qte')) {
          //const modelAdvanced = detailsElement.getAttribute('data-advanced');
          var displayQuantity = false;
          if(detailsElements.length == 1 ){
            const modelAdvanced = detailsElements[0].getAttribute('data-advanced');
            if(modelAdvanced == 0){
              var displayQuantity = true;
            }
          }
          
          if(!displayQuantity){
            if (document.querySelector('.art-qte').closest('.quantity-container')) {
              document.querySelector('.art-qte').closest('.quantity-container').classList.add('d-none');
            }
            document.querySelector('.art-qte').classList.add('d-none');
          }
        }
        if (document.querySelector('.btn-panier')) {
          document.querySelector('.btn-panier').setAttribute('disabled', '');
          document.querySelector('.btn-panier').classList.add('disabled');
          if(document.querySelector('#content-qte-tmp')){
            document.querySelector('#content-qte-tmp').classList.add('d-none');
          }

        }
      }else{
        globalDetailsElements = [];
        if (document.querySelector("#btn-start-customization")) {
            document.querySelector("#btn-start-customization").classList.add("d-none");
        }
        if (document.querySelector('.btn-panier')) {
          document.querySelector('.btn-panier').removeAttribute('disabled');
          document.querySelector('.btn-panier').classList.remove('disabled');
          document.querySelector('.art-qte').classList.remove('d-none');

          if(document.querySelector('#content-qte-tmp')){
            document.querySelector('#content-qte-tmp').classList.remove('d-none');
          }

          if (document.querySelector("#input-qte-tmp")) {
            const inputQuantity = document.querySelector('.sylius-quantity input[type="number"]');
            document.querySelector("#input-qte-tmp").value = inputQuantity.value;
          }
        }
      }
    }
    checkIfOnUpdatedProduct();
  }else{
    if (document.querySelector("#add-to-cart-btn")) {
      document.querySelector("#add-to-cart-btn").classList.remove("d-none");
    }
  }

  // on change quantity
  if (document.querySelector('[name="sylius_add_to_cart[cartItem][quantity]"]')) {
      $('#sylius_add_to_cart_cartItem_quantity').on('change', minimalQuantity);
      document.querySelector('[name="sylius_add_to_cart[cartItem][quantity]"]').dispatchEvent(new Event('change'));
  }


  if(document.querySelector('#btn-start-customization') !== null ){
    document.querySelector('#btn-start-customization').addEventListener('click', (e) => {
      if(globalDetailsElements.length > 0 || globalDetailsElementUpdate.length > 0) {
  
        document.querySelector('#js-configurator').classList.add("active");
        const popupConfirmation = `
        <div class="popup-conf-wrap hide">
            <div class="popup-conf">
              <div class="popup-conf-msg">
                En continuant, vous perdrez votre personnalisation.
              </div>
              <div class="popup-conf-actions">
                  <span id="btn-back-confirm">Continuer</span>
                  <span id="btn-back-reset">Rester</span>
              </div>
            </div>
        </div>`;
        document.querySelector('body').insertAdjacentHTML("beforeend", popupConfirmation);

        document.querySelector('#btn-back-reset').addEventListener('click', (e) => {
          document.querySelector('.popup-conf-wrap').classList.add('hide');
        });

        document.querySelector('#btn-back-confirm').addEventListener('click', (e) => {
          handleClickBack();
        });

        //document.querySelector('.fiche-art-qty').classList.add('d-none');
        document.querySelector(".btn-retour").classList.add("show");
        document.querySelector("#js-configurator").classList.remove("hide");
        document.querySelector("#sylius-product-selecting-variant").classList.add("d-none");
        document.querySelector("#btn-start-customization").classList.add("d-none");
        document.querySelector('.article-right .descr-art').classList.add("d-none");
        document.querySelector('.tab-article-content').classList.add("d-none");
        document.querySelector('.article-bottom').classList.add("d-none");
        document.getElementById('sylius-product-name').classList.add('d-none');
        document.querySelector('#js-configurator').insertAdjacentHTML("afterend", "<div class='box-transparent'></div>");
        if (document.querySelector("#add-to-cart-btn"))
            document.querySelector("#add-to-cart-btn").classList.remove("d-none");
        if (globalDetailsElements.length > 0 ) {
            // dans le cas d'un bundle avec une configuration simple
            if (document.getElementById('product-bundle-items-images') && document.getElementById('box-config-perso')) {
                let currentConfig = null;
                setTimeout(function() {
                    document.querySelectorAll('#box-config-perso > .config-perso').forEach(configPerso=>{
                        if (!configPerso.classList.contains('d-none')) {
                            currentConfig = configPerso;
                        }
                    })
                    updateBundleImage(currentConfig);
                }, 300);

            }
            initDataModelConfigurator(globalDetailsElements);
        } else if (globalDetailsElementUpdate.length > 0) {
            initDataModelConfigurator(globalDetailsElementUpdate);
        }
      } else {
        console.log("log : globalDetailsElements is empty");
      }
    });
  }
});


export default SyliusVariantsPrices;
