$(function(){
  let ajaxCall;
  $('body').on('submit', 'form[name="filters-form"]', function(event){
    event.preventDefault();

    const url = $(this).attr('action');
    const data = $(this).serialize();


    if(ajaxCall != undefined){
      ajaxCall.abort();
    }
    ajaxCall = $.ajax({
      type:"GET",
      data: data,
      url: url,
      success: function(response){
        if(typeof $(response).find('.no-result').html() !== 'undefined' ){
          $(".liste-articles").html($(response).find('.no-result').html());
        }else{
          $(".page-categorie-content").html($(response).find('.page-categorie-content').html());
        }
      }
    })
  })

//click on list of filter to delete it
  $('body').on('click', '.delete-filter', function(){
    console.log('click')
    const filter = $(this).attr('data-filter');
    $('#'+filter).prop('checked', false);
    $('form[name="filters-form"]').submit();
  })

  $(".left-search").on("focus", "#monsieurbiz_searchplugin_search_query",function(){
    $(this).attr('placeholder', '');
  });

  $(".left-search").on("blur", "#monsieurbiz_searchplugin_search_query",function(){
    $(this).attr('placeholder', 'Rechercher…');
  });
});
