/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

class SyliusVariantImages {
  constructor() {
    let t = this;
    this.mainImageLink = document.querySelector('[data-js-product-image]');
    this.mainImage = this.mainImageLink ? this.mainImageLink.querySelector('img') : null;
    this.defaultImageLink = this.mainImageLink ? this.mainImageLink.getAttribute('href') : null;
    this.defaultImageSrc = this.mainImage ? this.mainImage.getAttribute('src') : null;

    if (this.mainImageLink) {
      document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
        item.addEventListener('change', () => this.setImage());
      });
      this.setImage();
    }

    document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach(item=>{
      item.addEventListener('change', function (e) {
        t.getProductImages(e.target);
      })
    })
  }

  getActiveVariant() {
    console.log('get active variant')
    const items = document.querySelectorAll(`
      input[name="sylius_add_to_cart[cartItem][variant]"]:checked,
      select[name*="sylius_add_to_cart[cartItem][variant]"] option:checked
    `);

    return [...items].map(el => el.value).join(' ');
  }

  getActiveImageSrc(variant) {
    console.log('get active image src')
    let imageLink = this.defaultImageLink;
    let imageSrc = this.defaultImageSrc;
    const item = document.querySelector(`
      .sylius-image-variants [data-variant-code="${variant}"],
      .sylius-image-variants [data-variant-options="${variant} "]
    `);

    if (item) {
      const parent = item.closest('[data-js-product-thumbnail]');
      imageLink = parent.querySelector('a').getAttribute('href');
      imageSrc = parent.querySelector('img').getAttribute('data-large-thumbnail');
    }

    return { imageLink, imageSrc };
  }

  setImage() {
    console.log('set image')
    const img = this.getActiveImageSrc(this.getActiveVariant());
    this.mainImageLink.setAttribute('href', img.imageLink);
    this.mainImage.setAttribute('src', img.imageSrc);
  }

  getProductImages(select) {
    let t = this;
    let imagesDetailsMatch = document.querySelectorAll('.sylius-image-variants [data-variant-options]');
    // in case of match selection
    if (imagesDetailsMatch.length > 0) {
      imagesDetailsMatch.forEach(imageDetail=> {
        if (imageDetail.getAttribute('data-variant-options').toLowerCase().trim() === select.value.toLowerCase().trim()) {
          imageDetail.parentElement.parentElement.style.display = "block";
          // console.log(imageDetail.parentElement.parentElement)
          // todo: remplacer la main image
          if (imageDetail.parentElement.parentElement.querySelector('.cursor-pointer')) {
            t.onImageClick(imageDetail.parentElement.parentElement.querySelector('.cursor-pointer'));
          }
        }
        else {
          imageDetail.parentElement.parentElement.style.display = "none";
        }
      })
    }
  }

  onImageClick(img) {
    img.addEventListener('click', function (e) {
      // todo: en fonction du aria label, afficher la bonne image big
      // trouver une solution pour la première image de gallery
      let ariaLabel = img.parentElement.getAttribute('aria-label')
      // console.log(ariaLabel)
      // console.log(document.querySelectorAll('.img-bit-article'))
      document.querySelectorAll('.img-bit-article').forEach(imgBig=> {
        if (imgBig.getAttribute('aria-label').trim() === ariaLabel.trim()) {
          console.log(imgBig)
          console.log(ariaLabel.trim())
          imgBig.classList.add('swiper-slide-active');
          setTimeout(function () {
            imgBig.style.opacity = 1;
          }, 500);
        }
        else {
          imgBig.classList.remove('swiper-slide-active');
          imgBig.style.opacity = 0;
        }
      })
    })
  }
}

export default SyliusVariantImages;
