import axios from 'axios';
import serialize from 'form-serialize';
import 'bootstrap/js/dist/modal';

import { generatePrintableCanvas } from './configurator.js';

const SyliusAddToCart = (el) => {

  const submitForm = el;
  const url = submitForm.getAttribute('action');
  const redirectUrl = submitForm.getAttribute('data-redirect');
  const validationElement = submitForm.querySelector('[data-js-add-to-cart="error"]');
  const detailsElement = document.querySelector("#sylius-variants-details div");

  submitForm.addEventListener('keydown', preventEnterKey);
  submitForm.addEventListener('keypress', preventEnterKey);
  submitForm.addEventListener('keyup', preventEnterKey);

  submitForm.addEventListener('submit', (e) => {
    e.preventDefault();

    if(!submitForm.querySelector('.btn-panier[type="submit"][disabled=""]')){

      //remove all generated input
      document.querySelectorAll(".generated-input").forEach(item => {
        item.remove();
      });

      let config = document.getElementById('box-config-perso');

      // CONFIGURABLE PRODUCT
      if(config != null){
        let modelIds = [];
        let itemIds = [];
        let configurationId = null;

        let isBundle = false;
        if(document.querySelector(".product-bundle-adding-to-cart") !== null) {
          isBundle = true;
        }

        let urlRequest = new URL(document.location.href);
        let productBundleOrderItems = urlRequest.searchParams.get("productBundleOrderItems");
        let test = urlRequest.searchParams.get("test");
        // // bundle
        if (productBundleOrderItems) {
          let productBundleOrderItemsIds = [];
          let productBundleOrderItemsArray = productBundleOrderItems.split(',');
          productBundleOrderItemsArray.forEach(item=>{
            let productBundleOrderItemDetails = item.split(':');
            productBundleOrderItemsIds.push(productBundleOrderItemDetails[0])
          });
          createAndAppendHiddenInput(submitForm, "bundleIds", productBundleOrderItemsIds);
        }

        createAndAppendHiddenInput(submitForm, "isBundle", isBundle);

        let configurationIds = [];
        document.querySelectorAll('.config-perso').forEach( configPerso =>{
          let elements = [];

          elements = configPerso.querySelectorAll('.personnalisation-bit');
          const variantId = configPerso.getAttribute("data-variant-id");
          let modelId = configPerso.getAttribute("data-model-id");
          const itemId = configPerso.getAttribute("data-itemId");
          const digital = configPerso.getAttribute("data-digital");
          modelIds.push(modelId);
          itemIds.push(itemId);
          let prefixModelId = "";
          if(isBundle) {
            prefixModelId = "_" + itemId;
          }

          let freeConfiguration = false;
          if (document.querySelector('#free-element-' + itemId + ' .bloc-config-perso:not(.d-none)') !== null) {
            freeConfiguration = true;
          }

          createAndAppendHiddenInput(submitForm, "count" + prefixModelId, elements.length);
          createAndAppendHiddenInput(submitForm, "configuration" + prefixModelId, true);
          createAndAppendHiddenInput(submitForm, "advancedConfiguration" + prefixModelId, configPerso.classList.contains('advanced'));
          createAndAppendHiddenInput(submitForm, "freeConfiguration" + prefixModelId, freeConfiguration);
          createAndAppendHiddenInput(submitForm, "variantId" + prefixModelId, variantId);
          createAndAppendHiddenInput(submitForm, "modelId" + prefixModelId, modelId);

          // configuration avancée
          if(configPerso.classList.contains('advanced')){
            configurationId = document.querySelector('#canvas-'+itemId).dataset.configurationId;
            // CONFIGURATION UPDATE
            if (urlRequest.searchParams.get("configurationId")) {
              configurationIds.push(modelId + ":" + configurationId);
            }

            let elementIds = [];
            let elementIndexes = [];
            elements.forEach(element=>{
              let index = element.dataset.index;
              elementIds.push(element.dataset.elementId);
              elementIndexes.push(index);

              createAndAppendHiddenInput(submitForm, "coordinatesX" + prefixModelId + "_" +index, element.dataset.coordinatesX);
              createAndAppendHiddenInput(submitForm, "coordinatesY" + prefixModelId + "_" +index, element.dataset.coordinatesY);
              createAndAppendHiddenInput(submitForm, "width" + prefixModelId + "_" +index, element.dataset.widthPx);
              createAndAppendHiddenInput(submitForm, "height" + prefixModelId + "_" +index, element.dataset.heightPx);
              createAndAppendHiddenInput(submitForm, "level" + prefixModelId + "_" +index, element.dataset.level);
              createAndAppendHiddenInput(submitForm, "elementId" + prefixModelId + "_" +index, element.dataset.elementId);
              createAndAppendHiddenInput(submitForm, "angle" + prefixModelId + "_" +index, element.dataset.angle);
              createAndAppendHiddenInput(submitForm, "freeElement" + prefixModelId + "_" +index, element.dataset.type.startsWith('free'));
              createAndAppendHiddenInput(submitForm, "blended" + prefixModelId + "_" +index, element.dataset.blended);

              switch (element.dataset.type) {
                case "text":
                  if (element.dataset.printColor) {
                    createAndAppendHiddenInput(submitForm, "text_print_color" + prefixModelId + "_" +index, element.dataset.printColor);
                  }
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "text");
                  break;
                case "free_text":
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "text");
                  break;
                case "image_admin":
                  createAndAppendHiddenInput(submitForm, "imagePath" + prefixModelId + "_" +index, element.dataset.imagePath);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_admin");
                  break;
                case "image_library":
                  createAndAppendHiddenInput(submitForm, "libraryFileId" + prefixModelId + "_" +index, element.dataset.libraryFileId);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_library");
                  break;
                case "image_upload_or_library":
                  createAndAppendHiddenInput(submitForm, "libraryFileId" + prefixModelId + "_" +index, element.dataset.libraryFileId);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_library");
                  break;
                case "free_image_library":
                  createAndAppendHiddenInput(submitForm, "libraryFileId" + prefixModelId + "_" +index, element.dataset.libraryFileId);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_library");
                  break;
                case "image_upload":
                  createAndAppendHiddenInput(submitForm, "cropX" + prefixModelId + "_" +index, element.dataset.cropX);
                  createAndAppendHiddenInput(submitForm, "cropY" + prefixModelId + "_" +index, element.dataset.cropY);
                  createAndAppendHiddenInput(submitForm, "cropWidth" + prefixModelId + "_" +index, element.dataset.cropWidth);
                  createAndAppendHiddenInput(submitForm, "cropHeight" + prefixModelId + "_" +index, element.dataset.cropHeight);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_upload");
                  break;
                case "free_image_upload":
                  createAndAppendHiddenInput(submitForm, "cropX" + prefixModelId + "_" +index, element.dataset.cropX);
                  createAndAppendHiddenInput(submitForm, "cropY" + prefixModelId + "_" +index, element.dataset.cropY);
                  createAndAppendHiddenInput(submitForm, "cropWidth" + prefixModelId + "_" +index, element.dataset.cropWidth);
                  createAndAppendHiddenInput(submitForm, "cropHeight" + prefixModelId + "_" +index, element.dataset.cropHeight);
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_upload");
                  break;
              }
            });
            createAndAppendHiddenInput(submitForm, "elementIds" + prefixModelId, elementIds);
            createAndAppendHiddenInput(submitForm, "elementIndexes" + prefixModelId, elementIndexes);

            /*
            * Generated images
            */
            const cartImage = generatePrintableCanvas(itemId, "cart");
            createAndAppendHiddenInput(submitForm, "cart_canvas" + prefixModelId, cartImage);

            if(digital == 1){
              const printImage = generatePrintableCanvas(itemId, "print", test);
              createAndAppendHiddenInput(submitForm, "print_canvas" + prefixModelId, printImage);
              createAndAppendHiddenInput(submitForm, "isDigitale" + prefixModelId, "true");
            }else{
              createAndAppendHiddenInput(submitForm, "isDigitale" + prefixModelId, "false");
            }
          }
          // configuration simple
          else{
            let elementIds = [];
            let elementIndexes = [];
            configurationId = null;

            elements.forEach(element=>{
              let index = element.dataset.index;
              elementIds.push(element.dataset.elementId);
              elementIndexes.push(index);
              createAndAppendHiddenInput(submitForm, "elementName" + prefixModelId + "_" +index, element.dataset.elementName);
              createAndAppendHiddenInput(submitForm, "elementId" + prefixModelId + "_" +index, element.dataset.elementId);

              switch (element.dataset.type) {
                case "text":
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "text");
                  break;
                case "image_upload":
                  createAndAppendHiddenInput(submitForm, "type" + prefixModelId + "_" +index, "image_upload");
                  break;
              }
            })

            createAndAppendHiddenInput(submitForm, "elementIds" + prefixModelId, elementIds);
            createAndAppendHiddenInput(submitForm, "elementIndexes" + prefixModelId, elementIndexes);
          }

          // UPDATE
          if (urlRequest.searchParams.get("configurationId") || productBundleOrderItems) {
            // produit seul
            if (!productBundleOrderItems) {
              configurationId = urlRequest.searchParams.get("configurationId");
              configurationIds.push(modelId + ":" + configurationId);
            }
            // bundle
            else {
              let configurationsDetails = productBundleOrderItems.split(',');
              for (let i = 0; i < modelIds.length; i++) {
                let oneConfigurationDetails = configurationsDetails[i].split(':');
                configurationIds.push(modelIds[i] + ":" + oneConfigurationDetails[2] + ":" + oneConfigurationDetails[0]);
              }
              configurationIds = [...new Set(configurationIds)];
            }
          }

        });

        if (configurationIds.length > 0) {
          createAndAppendHiddenInput(submitForm, "configurationId", configurationIds);
        }

        if(isBundle){
          createAndAppendHiddenInput(submitForm, "modelIds", modelIds);
          createAndAppendHiddenInput(submitForm, "itemIds", itemIds);
        }
      }

      setTimeout(function () {
        const request = axios.post(url, new FormData(submitForm));

        request.then((response) => {
          let name = response.data.variantName;
          let path = response.data.source_path;
          let options = response.data.options;
          let quantity = response.data.quantity;
          let price = response.data.price;

          // si pas d'image, afficher un "no image" sinon ça génèrera une erreur (pas bloquante)
          var http = new XMLHttpRequest();
          http.open('HEAD', path, false);
          http.send();

          if (http.status === 404 || http.status === 500) {
            if (path.indexOf('media/cache') !== -1) {
              path = path.substring(0, path.indexOf('media/cache')) + "images/spacer.png";
            }
          }

          if (validationElement)
            validationElement.classList.add('d-none');
          submitForm.classList.remove('loading');
          $('#modal-addProduct-image')
            .empty();
          $('#modal-addProduct-name')
            .empty();
          $('#modal-addProduct-options')
            .empty();
          $('#modal-addProduct-quantity')
            .empty();
          $('#modal-addProduct-price')
            .empty();

          $('#modal-addProduct-image')
            .append(
              '<img src="' + path + '"/>'
            );
          $('#modal-addProduct-name')
            .append(
              '<span>' + name + '</span>'
            );
          $('#modal-addProduct-options')
            .append(
              '<span>' + options + '</span>'
            );
          $('#modal-addProduct-quantity')
            .append(
              '<span>' + quantity + '</span>'
            );
          $('#modal-addProduct-price')
            .append(
              '<span>' + price + '</span>'
            );


          $('#addToCartModal')
            .modal('show');

          $.ajax({
            url: '/app_summary',
            type: "GET",
            success: function (data) {
              $("#contenuPanierCommunWidget")
                .html(data);
            },
            error: function () {
              console.log("error popup");
            }
          });

          $.ajax({
            url: '/app_summary_popup',
            type: "GET",
            success: function (data) {
              $("#contenuPanierCommunPopup")
                .html(data);
            },
            error: function () {
              console.log("error popup");
            }
          });

        });

        request.catch((error) => {
          let stockError = false;

          if (validationElement) {
            validationElement.classList.remove('d-none');
            let validationMessage = '';

            Object.entries(error.response.data).forEach(([error, message]) => {
              let errorMessage = "Une erreur est survenue";
              if (typeof message.errors !== "undefined") {
                errorMessage = message.errors[0];
                if (errorMessage.includes('rupture')) {
                  stockError = true;
                }
              }
              validationMessage = errorMessage + " ";
            });

            validationElement.innerHTML = validationMessage;
            submitForm.classList.remove('loading');

          }


          document.querySelector('#btn-next-add-panier').style.visibility = "visible";

          if (stockError) {
            $('#productShowQuantityErrorModal')
                .modal('show');
            $('.modal-backdrop').modal('hide');
            $('.modal-backdrop').remove();
          }
          else {
            $('#productAddToCartErrorModal')
                .modal('show');
            $('.modal-backdrop').modal('hide');
            $('.modal-backdrop').remove();
          }


        });
      }, 100);
    }

  });
};

function createAndAppendHiddenInput(form, name, value) {
  let input = document.createElement('input');
  input.setAttribute('type', 'hidden');
  input.setAttribute('name', name);
  input.setAttribute('value', value);
  input.classList.add('generated-input');

  form.appendChild(input);
}

function preventEnterKey(e) {
  if (e.target.nodeName !== "TEXTAREA" && (e.keyCode === 13 || e.key === 'Enter')) {
    e.preventDefault();
  }
}

export default SyliusAddToCart;



