// Main scripts file
import './js';
// Main styles file
import './scss/index.scss';
// Images
import './media/sylius-logo.png';
// Font awesome icons
import './js/fontawesome';

import './css/swiper.css';
// import './css/jcrop.css';
import './css/custom_styles.css';
import './css/fonts.css';
import './css/animate.css';
import './css/fontello.css';
import './css/css_mobile.css';

import './js/script';
import './js/swiper';
// import './js/jcrop';
import './js/product_search';
import './js/cookie_consent';